import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  CandidateProduct,
  ListProduct,
} from '@shared/models/response/list-product';
import { Picklist } from '@shared/models/response/picklist';
import { PicklistProductStatus } from '@shared/models/response/sub/picklist-product-status';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CandidateProductAction } from '../actions';
import {
  getIsFetching,
  getCandidateProduct,
  getCandidateProducts,
  getDiscontinuedProducts,
  getProcessingIds,
  getAllCandidateProducts,
  getFilter,
} from '../selectors';
import { CandidateListFilter } from '../states';

@Injectable({
  providedIn: 'root',
})
export class CandidateProductFacade {
  isFetching$ = this.store.pipe(select(getIsFetching));
  allCandidateProducts$ = this.store.pipe(select(getAllCandidateProducts));
  candidateProducts$ = this.store.pipe(select(getCandidateProducts));
  candidateProduct$ = this.store.pipe(select(getCandidateProduct));
  discontinuedProducts$ = this.store.pipe(select(getDiscontinuedProducts));
  processingIds$ = this.store.pipe(select(getProcessingIds));
  filter$ = this.store.pipe(select(getFilter));

  constructor(private readonly store: Store) {}

  fetchAll(picklists: Picklist[]): void {
    const _picklists = JSON.parse(JSON.stringify(picklists)) as Picklist[];
    this.store.dispatch(
      CandidateProductAction.fetchAll({ picklists: _picklists }),
    );
  }

  fetch(id: string): void {
    this.store.dispatch(CandidateProductAction.fetch({ id }));
  }

  create(candidateProduct: CandidateProduct | ListProduct): void {
    this.store.dispatch(CandidateProductAction.create({ candidateProduct }));
  }

  createMany(candidateProducts: CandidateProduct[] | ListProduct[]): void {
    this.store.dispatch(
      CandidateProductAction.createMany({ candidateProducts }),
    );
  }

  update(candidateProduct: CandidateProduct): void {
    this.store.dispatch(CandidateProductAction.update({ candidateProduct }));
  }

  remove(id: number): void {
    this.store.dispatch(CandidateProductAction.remove({ id }));
  }

  setFilter(filter: CandidateListFilter): void {
    this.store.dispatch(CandidateProductAction.setFilter({ filter }));
  }

  addProcessingId(id: number): void {
    this.store.dispatch(CandidateProductAction.addProcessingId({ id }));
  }

  getVariationTotal(candidateProducts: CandidateProduct[]): void {
    this.store.dispatch(
      CandidateProductAction.getVariationTotal({ candidateProducts }),
    );
  }

  getCandidateProductsByPicklistId(
    picklistId?: number,
  ): Observable<CandidateProduct[]> {
    return this.candidateProducts$.pipe(
      map((candidates) =>
        candidates.filter(
          (candidate) => candidate.list_id === (picklistId ?? -1),
        ),
      ),
    );
  }

  getFilteredCandidateProductsByPicklistId(
    picklistId: number,
  ): Observable<CandidateProduct[]> {
    return combineLatest([this.candidateProducts$, this.filter$]).pipe(
      map(([candidates, filter]) =>
        candidates.filter((candidate) => {
          if (candidate.list_id !== picklistId) return false;
          if (filter && filter.picklistId === picklistId) {
            if (filter.status) return candidate.status === filter.status;
            if (filter.addedById)
              return candidate.added_user_id === filter.addedById;
          }
          return true;
        }),
      ),
    );
  }

  getDiscontinuedProductsByPicklistId(
    picklistId: number,
  ): Observable<CandidateProduct[]> {
    return this.discontinuedProducts$.pipe(
      map((candidates) =>
        candidates.filter((candidate) => candidate.list_id === picklistId),
      ),
    );
  }

  private isDecided(candidate: CandidateProduct): boolean {
    return (
      candidate.status === PicklistProductStatus.Decided ||
      candidate.status === PicklistProductStatus.Fixed
    );
  }

  getDecidedProductByPicklistId(
    picklistId: number,
  ): Observable<CandidateProduct | undefined> {
    return this.allCandidateProducts$.pipe(
      map((candidates) =>
        candidates
          .filter((candidate) => candidate.list_id === picklistId)
          .find((candidate) => this.isDecided(candidate)),
      ),
    );
  }

  getCandidateProductsByPicklistIds(
    picklistIds: number[],
  ): Observable<CandidateProduct[]> {
    return this.allCandidateProducts$.pipe(
      map((candidates) =>
        candidates.filter((candidate) =>
          picklistIds.includes(candidate.list_id),
        ),
      ),
    );
  }

  getIsProcessingByListProductId(listProductId: number): Observable<boolean> {
    return this.processingIds$.pipe(map((ids) => ids.includes(listProductId)));
  }

  getCandidateProductByListProductId(
    listProductId: number,
  ): Observable<CandidateProduct | undefined> {
    return this.allCandidateProducts$.pipe(
      map((candidates) =>
        candidates.find((candidate) => candidate.id === listProductId),
      ),
    );
  }

  clearFilter() {
    this.setFilter({
      picklistId: null,
      status: null,
      addedById: null,
    });
  }
}
