import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { FeatureKey } from '../enums/feature-key.enum';

import { RoomToRoomEffects } from './room-to-room.effect';
import { roomToRoomReducer } from './room-to-room.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(FeatureKey.RoomToRoom, roomToRoomReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
      connectInZone: true,
    }),
    EffectsModule.forFeature([RoomToRoomEffects]),
  ],
})
export class RoomToRoomStoreModule {}
