import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Picklist } from '@shared/models/response/picklist';
import { PicklistService } from '@shared/service/picklist.service';
import { PicklistApiService } from 'app/services/api/picklist-api.service';
import {
  catchError,
  combineLatest,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { joineryComponentAction } from '../joinery-component/actions';
import { JoineryComponentFacade } from '../joinery-component/joinery-component.facade';

import { PicklistAction } from './actions';
import { PicklistStoreFacade } from './picklist-store.facade';

@Injectable()
export class PicklistEffects {
  fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PicklistAction.fetchAll),
      switchMap(({ projectId, region, businessGroupId }) => {
        // 一旦建具でしかstoreに入れないでおく
        if (region && businessGroupId) {
          return this.picklistApi
            .fetchJoineryPicklists(projectId, region, businessGroupId)
            .pipe(
              map((result) =>
                PicklistAction.fetchAllSuccess({ picklists: result }),
              ),
            );
        } else {
          return of(PicklistAction.fetchAllFailure({ error: 'error' }));
        }
      }),
    ),
  );

  updateSpec$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PicklistAction.updateSpec),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          picklist,
          picklistSpecification,
        }) => {
          if (region && businessGroupId) {
            return this.picklistApi
              .updatePicklistSpecification(
                projectId,
                region,
                businessGroupId,
                picklist.id,
                picklistSpecification,
              )
              .pipe(
                map(() => {
                  const newSpec = picklist.joinery_picklist_specifications?.map(
                    (s) => {
                      if (s.id === picklistSpecification.id) {
                        return picklistSpecification;
                      } else {
                        return s;
                      }
                    },
                  );
                  return PicklistAction.updateSpecSuccess({
                    picklist: {
                      ...picklist,
                      joinery_picklist_specifications: newSpec,
                    },
                  });
                }),
              );
          } else {
            return of(
              PicklistAction.updateSpecFailure({
                error: 'error',
              }),
            );
          }
        },
      ),
    ),
  );

  addPicklistToStandardComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PicklistAction.addPicklistToStandardComponent),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          standardComponentId,
          newPicklistName,
          parentPicklist,
        }) => {
          return this.picklistService
            .addJoineryPicklist(
              projectId,
              region,
              businessGroupId,
              standardComponentId,
              newPicklistName,
              parentPicklist,
            )
            .pipe(
              map((picklist) =>
                PicklistAction.addPicklistToStandardComponentSuccess({
                  picklist,
                }),
              ),
              catchError((error) =>
                of(
                  PicklistAction.addPicklistToStandardComponentFailure({
                    error,
                  }),
                ),
              ),
            );
        },
      ),
    ),
  );

  addPicklistToComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PicklistAction.addPicklistToComponent),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          component,
          newPicklistName,
          parentPicklist,
        }) => {
          return from(
            this.picklistService.addJoineryPicklistToComponent(
              projectId,
              region,
              businessGroupId,
              component,
              newPicklistName,
              parentPicklist,
            ),
          ).pipe(
            tap((picklists) => {
              const childPicklist = picklists.find(
                (picklist) => !picklist.is_parent,
              );
              if (childPicklist) {
                this.store.dispatch(
                  joineryComponentAction.setPicklist({
                    component,
                    picklistId: childPicklist.id,
                  }),
                );
              }
            }),
            map((picklists) =>
              PicklistAction.addPicklistToComponentSuccess({ picklists }),
            ),
            catchError((error) =>
              of(PicklistAction.addPicklistToComponentFailure({ error })),
            ),
          );
        },
      ),
    ),
  );

  duplicateJoineryPicklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PicklistAction.duplicateJoineryPicklist),
      switchMap(({ projectId, region, businessGroupId, picklist }) =>
        this.picklistService
          .duplicateJoineryPicklist(
            projectId,
            region,
            businessGroupId,
            picklist,
          )
          .pipe(
            map((picklist) =>
              PicklistAction.duplicateJoineryPicklistSuccess({ picklist }),
            ),
            catchError((error) =>
              of(PicklistAction.duplicateJoineryPicklistFailure({ error })),
            ),
          ),
      ),
    ),
  );

  removeMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PicklistAction.removeMany),
      switchMap(({ projectId, region, businessGroupId, picklists }) => {
        const remove$: Observable<Picklist | null>[] = picklists.map(
          (picklist) => {
            if (picklist.joinery_standard_component_id) {
              return this.picklistApi
                .removePicklistFromStandardComponent(
                  projectId,
                  region,
                  businessGroupId,
                  picklist.joinery_standard_component_id,
                  picklist.id,
                )
                .pipe(map(() => picklist));
            } else {
              return of(null);
            }
          },
        );
        return combineLatest(remove$).pipe(
          tap((picklists) => {
            // 材料を削除したら構成要素のpicklist_idからも削除させる処理
            firstValueFrom(this.joineryComponentFacade.components$).then(
              (components) => {
                firstValueFrom(this.picklistFacade.picklists$).then(
                  (allPicklists) => {
                    const picklistIds: number[] = picklists.flatMap(
                      (picklist) => {
                        if (!picklist) return [];
                        if (picklist.is_parent) {
                          const childPicklistIds = allPicklists
                            .filter(
                              (_picklist) =>
                                _picklist.group_id &&
                                _picklist.group_id === picklist.id,
                            )
                            .map((_picklist) => _picklist.id);
                          return [...childPicklistIds, picklist.id];
                        }
                        return picklist.id;
                      },
                    );
                    const removeTargetComponents = components.filter(
                      (component) =>
                        component.picklist_id &&
                        picklistIds.includes(component.picklist_id),
                    );
                    this.joineryComponentFacade.removePicklistManyByEffect(
                      removeTargetComponents,
                    );
                  },
                );
              },
            );
          }),
          map((picklists) =>
            PicklistAction.removeManySuccess({
              ids: picklists.flatMap((picklist) => picklist?.id ?? []),
            }),
          ),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private picklistApi: PicklistApiService,
    private picklistService: PicklistService,
    private picklistFacade: PicklistStoreFacade,
    private joineryComponentFacade: JoineryComponentFacade,
  ) {}
}
