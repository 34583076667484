import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { UserStore } from '../../core/store/user.store';

type LastLoginInfo = {
  userId: number;
  tenantId: number;
};

@Injectable({
  providedIn: 'root',
})
export class TenantIdStorageLogic {
  private router = inject(Router);
  private userStore = inject(UserStore);

  getId(userId: number): number | undefined {
    try {
      const storageData = localStorage.getItem('tenantId');
      if (!storageData) return;

      const lastLoginInfoList = JSON.parse(storageData);
      if (!this.isLastLoginInfo(lastLoginInfoList))
        throw new Error('invalid tenantId storage value');

      const currentUserItem = lastLoginInfoList.find(
        (obj) => obj.userId === userId,
      );
      if (!currentUserItem) return;

      const targetTenantId = currentUserItem.tenantId;
      if (isNaN(targetTenantId)) throw new Error('invalid tenant id');

      return targetTenantId;
    } catch (e) {
      localStorage.removeItem('tenantId');
      this.router.navigateByUrl('/tenant/login');
      throw new Error(`Error retrieving tenant ID: ${e.message}`);
    }
  }

  async setId(tenantId: number): Promise<void> {
    try {
      const userId = await firstValueFrom(this.userStore.userId$);
      if (!userId) throw new Error('User ID not found');

      const storageData = localStorage.getItem('tenantId');
      if (!storageData) {
        localStorage.setItem(
          'tenantId',
          JSON.stringify([{ userId, tenantId }]),
        );
        return;
      }

      const lastLoginInfoList = JSON.parse(storageData);
      if (!this.isLastLoginInfo(lastLoginInfoList))
        throw new Error('invalid tenantId storage value');

      const targetUser = lastLoginInfoList.find((obj) => obj.userId === userId);

      if (!targetUser) {
        lastLoginInfoList.push({ userId, tenantId });
        const tenantIdsListJSON = JSON.stringify(lastLoginInfoList);
        localStorage.setItem('tenantId', tenantIdsListJSON);
      } else {
        targetUser.tenantId = tenantId;
        const tenantIdsListJSON = JSON.stringify(lastLoginInfoList);
        localStorage.setItem('tenantId', tenantIdsListJSON);
      }
    } catch (e) {
      localStorage.removeItem('tenantId');
      this.router.navigateByUrl('/tenant/login');
      throw new Error(e);
    }
  }

  deleteId(userId: number): void {
    try {
      const storageData = localStorage.getItem('tenantId');
      if (!storageData) return;

      const lastLoginInfoList = JSON.parse(storageData);
      if (!this.isLastLoginInfo(lastLoginInfoList))
        throw new Error('invalid tenantId storage value');

      const deletedTenantIdsList = lastLoginInfoList.filter(
        (obj) => obj.userId !== userId,
      );

      const deletedTenantIdsListJSON = JSON.stringify(deletedTenantIdsList);
      localStorage.setItem('tenantId', deletedTenantIdsListJSON);
    } catch (e) {
      localStorage.removeItem('tenantId');
      this.router.navigateByUrl('/tenant/login');
      throw new Error(e);
    }
  }

  private isLastLoginInfo(data: unknown): data is LastLoginInfo[] {
    return (
      Array.isArray(data) &&
      data.every(
        (item) =>
          typeof item.userId === 'number' && typeof item.tenantId === 'number',
      )
    );
  }
}
