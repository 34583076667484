import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ListProduct } from '@shared/models/response/list-product';

import { ProjectPageService } from '../../../general/project/project-page.service';
import { Picklist } from '../../models/response/picklist';

import { AddOutdoorPicklistDialogComponent } from './add-outdoor-picklist-dialog.component';

export interface AddPicklistResult {
  picklist: Picklist;
  copyInfo?: {
    needProduct: boolean;
  };
}

export interface AddPicklistInput {
  projectPage: ProjectPageService;
  inputPicklist?: Picklist;
  isCopy: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AddOutdoorPicklistDialogService extends MatDialog {
  openDialog(
    projectPage: ProjectPageService,
    inputPicklist?: Picklist,
    isCopy: boolean = false,
  ): MatDialogRef<AddOutdoorPicklistDialogComponent, AddPicklistResult> {
    const baseConfig = {
      minWidth: 660,
    };
    const config = Object.assign({}, baseConfig, {
      data: { projectPage, isCopy, inputPicklist },
    });
    return this.open(AddOutdoorPicklistDialogComponent, config);
  }
}
