import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

import { Picklist } from '../../models/response/picklist';
import { getPicklistTypeLabel } from '../../models/response/sub/picklist-type';
import { ProjectService } from '../../service/project.service';

@Component({
  selector: 'ls-list-link-check-dialog',
  templateUrl: './list-link-check-dialog.component.html',
  styleUrls: ['./list-link-check-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ListLinkCheckDialogComponent {
  get otherLinkPicklistTypeLabel(): string {
    return getPicklistTypeLabel(this.otherLinkPicklist?.type || '');
  }

  get currentPicklistTypeLabel(): string {
    return getPicklistTypeLabel(this.picklist!.type);
  }

  get picklist() {
    return this.data.picklist;
  }

  get otherLinkPicklist() {
    return this.project.getOtherLinkPicklist(this.picklist);
  }

  constructor(
    private dialogRef: MatDialogRef<ListLinkCheckDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { picklist: Picklist },
    readonly project: ProjectService,
  ) {}

  cancel() {
    this.dialogRef.close(false);
  }

  okClick() {
    this.project.updateDisplayListLink(this.picklist, true);
    this.dialogRef.close(true);
  }
}
