import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export interface DialogBaseConfig {
  title?: string;
  subtitle?: string;
  actionLabel?: string;
  cancelLabel?: string;
  disableContainer?: boolean;
}

@Component({
  selector: 'ls-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogBaseComponent {
  @Input() config: DialogBaseConfig;
  @Input() canAction: boolean;
  @Output() action = new EventEmitter();
  @Output() cancel = new EventEmitter();
}
