import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';

import { JoineryPageInfoAction } from './actions';
import { JoineryPageInfoSelector } from './selectors';

@Injectable({ providedIn: 'root' })
export class JoineryPageInfoStoreFacade {
  isFetching$ = this.store.pipe(select(JoineryPageInfoSelector.getIsFetching));
  error$ = this.store.pipe(select(JoineryPageInfoSelector.getError));
  joineryPageInfo$ = this.store.pipe(
    select(JoineryPageInfoSelector.getJoineryPageInfo),
  );
  symbolOptions$ = this.store.pipe(
    select(JoineryPageInfoSelector.getSymbolOptions),
  );
  standardComponents$ = this.store.pipe(
    select(JoineryPageInfoSelector.getStandardComponents),
  );

  constructor(private readonly store: Store) {}

  fetch(projectId: number, joineryRegionType: ProjectRangeType) {
    this.store.dispatch(
      JoineryPageInfoAction.fetch({ projectId, joineryRegionType }),
    );
  }
}
