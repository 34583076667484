import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JoineryComponent } from '@interfaces/joinery-component';
import { Picklist } from '@shared/models/response/picklist';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JoineryComponentApiService {
  constructor(private http: HttpClient) {}

  addComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    component: Omit<JoineryComponent, 'id'>,
  ): Observable<JoineryComponent> {
    return this.http.post<JoineryComponent>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${component.joinery_id}/components`,
      { ...component },
    );
  }

  removeComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    component: JoineryComponent,
  ): Observable<void> {
    return this.http.delete<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${component.joinery_id}/components/${component.id}`,
    );
  }

  pastePicklistToComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineryId: number,
    componentId: number,
    picklist: Picklist,
  ): Observable<void> {
    return this.http.post<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${joineryId}/components/${componentId}/picklists/${picklist.id}`,
      { ...picklist },
    );
  }

  pastePicklistToComponents(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    componentIds: number[],
    picklistId: number | null,
  ): Observable<void> {
    return this.http.put<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/components/${componentIds.toString()}/bulk/picklists/${picklistId}`,
      {},
    );
  }

  removePicklistFromComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineryId: number,
    componentId: number,
    picklistId: number,
  ): Observable<void> {
    return this.http.delete<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${joineryId}/components/${componentId}/picklists/${picklistId}`,
    );
  }

  removePicklistFromComponents(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    componentIds: number[],
  ): Observable<void> {
    return this.http.delete<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/components/${componentIds.toString()}/bulk/picklists`,
      {},
    );
  }
}
