import { Injectable } from '@angular/core';

import { BannerService } from './banner.service';
import { CategoryColumnSettingService } from './category-column-setting.service';
import { CategoryService } from './category.service';
import { FaceService } from './face.service';
import { FavoriteListService } from './favorite-list.service';
import { FeatureService } from './feature.service';
import { ImageService } from './image.service';
import { MakerService } from './maker.service';
import { ProductService } from './product.service';
import { ProjectService } from './project.service';
import { SeriesService } from './series.service';
import { UseCaseService } from './use-case.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    readonly banner: BannerService,
    readonly categoryColumnSetting: CategoryColumnSettingService,
    readonly series: SeriesService,
    readonly product: ProductService,
    readonly category: CategoryService,
    readonly maker: MakerService,
    readonly useCase: UseCaseService,
    readonly feature: FeatureService,
    readonly image: ImageService,
    readonly face: FaceService,
    readonly user: UserService,
    readonly favList: FavoriteListService,
    readonly project: ProjectService,
  ) {}
}
