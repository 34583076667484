import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import {
  RoomBlock,
  RoomBlockState,
} from '../../domain/types/states/room-block.state';
import { RoomBlockPartsViewModel } from '../view-model/room-block-view-model';

@Injectable({
  providedIn: 'root',
})
export class RoomBlockStore extends ComponentStore<RoomBlockState> {
  constructor() {
    super({
      roomBlocks: [],
    });
  }

  roomBlocks$: Observable<RoomBlock[]> = this.select(
    (state) => state.roomBlocks,
  );

  updateState = this.updater((state: RoomBlockState, value: RoomBlock[]) => {
    return {
      ...state,
      roomBlocks: value,
    };
  });

  addManyRoomBlock = this.updater(
    (state: RoomBlockState, value: RoomBlock[]) => {
      return {
        ...state,
        roomBlocks: [...state.roomBlocks, ...value],
      };
    },
  );

  deleteRoomBlock = this.updater(
    (state: RoomBlockState, deleteItemIds: number[]) => {
      const newState = state.roomBlocks.filter((roomBlock) => {
        return !deleteItemIds.includes(roomBlock.id);
      });
      return { roomBlocks: newState };
    },
  );

  deleteRoomBlockByRoomGroupId = this.updater(
    (state: RoomBlockState, deleteRoomGroupId: number) => {
      const newState = state.roomBlocks.filter((roomBlock) => {
        return roomBlock.room_group_id !== deleteRoomGroupId;
      });
      return { roomBlocks: newState };
    },
  );

  removePicklistInfosByPicklistIds = this.updater(
    (state: RoomBlockState, deletePicklistIds: number[]) => {
      const newState = state.roomBlocks.map((roomBlock) => {
        roomBlock.parts = Object.keys(roomBlock.parts).reduce(
          (roomBlockParts, interiorCategoryId) => {
            const castedInteriorCategoryId = parseInt(interiorCategoryId);
            const parts = roomBlock.parts[
              castedInteriorCategoryId
            ] as RoomBlockPartsViewModel;

            parts.picklistInfo = parts.picklistInfo.filter((picklistInfo) => {
              return !deletePicklistIds.includes(picklistInfo.id);
            });

            roomBlockParts[castedInteriorCategoryId] = parts;

            return roomBlockParts;
          },
          {} as { [interior_category_id: number]: RoomBlockPartsViewModel },
        );

        return roomBlock;
      });

      return { roomBlocks: newState };
    },
  );

  updateManyRoomBlock = this.updater(
    (state: RoomBlockState, newItems: RoomBlock[]) => {
      if (state.roomBlocks.length === 0) {
        return {
          ...state,
          roomBlocks: newItems,
        };
      }

      const updatedItems = state.roomBlocks.map((item) => {
        const matchingItem = newItems.find((newItem) => newItem.id === item.id);
        if (matchingItem) {
          return { ...item, ...matchingItem };
        }

        return item;
      });

      const roomBlockIds = state.roomBlocks.map((item) => {
        return item.id;
      });

      const addedItems = newItems.filter(
        (newItem) => !roomBlockIds.includes(newItem.id),
      );
      if (addedItems.length > 0) {
        updatedItems.push(...addedItems);
      }

      return { ...state, roomBlocks: updatedItems };
    },
  );
}
