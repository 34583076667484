import { RoomToRoom } from '@interfaces/room-to-room';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface RoomToRoomState extends EntityState<RoomToRoom> {
  error?: unknown;
  isFetching: boolean;
}

export const roomToRoomAdapter = createEntityAdapter<RoomToRoom>({
  selectId: (entity) => entity.id,
});

export const initialRoomToRoomState: RoomToRoomState =
  roomToRoomAdapter.getInitialState({
    isFetching: false,
  });
