import { Injectable, inject } from '@angular/core';
import { InteriorCategory } from 'app/v2/general/domain/types/states/interior-category.state';
import { InteriorSetParts } from 'app/v2/general/domain/types/states/interior-set-parts.state';
import { combineLatest, map, Observable } from 'rxjs';

import { InteriorCategoryStore } from '../../../store/interior-category.store';
import { InteriorSetPartsStore } from '../../../store/interior-set-parts.store';
import { InteriorSetStore } from '../../../store/interior-set.store';
import { InteriorSetPartsViewModel } from '../../../view-model/interior-set-parts-view-model';
import { InteriorSetViewModel } from '../../../view-model/interior-set-view-model';
import { PicklistViewModel } from '../../../view-model/picklist-view-model';
import { GetInteriorPicklistViewModelQuery } from '../picklist/get-interior-picklist-view-model-query';

/**
 * InteriorSetViewModel生成クラス
 */
@Injectable({
  providedIn: 'root',
})
export class GetInteriorSetViewModelQuery {
  private interiorCategoryStore = inject(InteriorCategoryStore);
  private interiorSetStore = inject(InteriorSetStore);
  private interiorSetPartsStore = inject(InteriorSetPartsStore);
  private getPicklistViewModelQuery = inject(GetInteriorPicklistViewModelQuery);

  /**
   * interiorCategoryStore, interiorSetStore, interiorSetPartsStoreから取得した値でInteriorSetViewModelの配列を生成する
   */
  public getViewModelList(): Observable<InteriorSetViewModel[]> {
    const interiorSetsFromStore$ = this.interiorSetStore.interiorSets$;

    const interiorCategoriesFromStore$ =
      this.interiorCategoryStore.interiorCategories$;
    const picklistsFromStore$ = this.getInteriorPicklistFromStore();
    const interiorSetPartsFromStore$ =
      this.interiorSetPartsStore.interiorSetParts$;

    return combineLatest([
      interiorSetsFromStore$,
      interiorSetPartsFromStore$,
      interiorCategoriesFromStore$,
      picklistsFromStore$,
    ]).pipe(
      map(([sets, setParts, categories, picklists]) => {
        if (sets?.length === 0) return [];

        const filteredInteriorSetParts: InteriorSetParts[] = [];
        sets.forEach((set) => {
          const filteredSetParts = setParts.filter((setParts) => {
            return setParts.interior_set_id === set.id;
          });
          filteredInteriorSetParts.push(...filteredSetParts);
        });

        const interiorSetParts = this.getInteriorSetPartsViewModelList(
          filteredInteriorSetParts,
          categories,
          picklists,
        );

        const interiorSets = sets.map((interiorSet) => {
          const partsFromSetId = interiorSetParts.filter((setParts) => {
            return setParts.interior_set_id === interiorSet.id;
          });

          const partsInSets = partsFromSetId.map((parts) => {
            const partsInInteriorSetViewModel: {
              [interior_category_id: number]: InteriorSetPartsViewModel;
            } = {};

            partsInInteriorSetViewModel[parts.interior_category_id] = parts;
            return partsInInteriorSetViewModel;
          });

          const interiorSetViewModel: InteriorSetViewModel = {
            ...interiorSet,
            parts: {},
          };

          partsInSets.forEach((partsInSet) => {
            Object.keys(partsInSet).forEach((key) => {
              const interiorCategoryId = parseInt(key);
              const parts = partsInSet[interiorCategoryId];

              interiorSetViewModel.parts[interiorCategoryId] = parts;
            });
          });

          return interiorSetViewModel;
        });

        return interiorSets;
      }),
    );
  }

  /**
   * InteriorSetPartsViewModelの配列を生成する
   * @param interiorSetPartsFromStore
   * @param interiorCategoriesFromStore
   * @param picklistsFromStore
   * @returns
   */
  private getInteriorSetPartsViewModelList(
    interiorSetPartsFromStore: InteriorSetParts[],
    interiorCategoriesFromStore: InteriorCategory[],
    picklistsFromStore: PicklistViewModel[],
  ): InteriorSetPartsViewModel[] {
    const interiorSetPartsViewModel = interiorSetPartsFromStore.map(
      (interiorSetParts) => {
        const interiorCategory = interiorCategoriesFromStore.find(
          (interiorCategory) => {
            return (
              interiorCategory.id === interiorSetParts.interior_category_id
            );
          },
        )!;

        const picklists: PicklistViewModel[] = [];
        if (interiorSetParts.picklistInfo.length > 0) {
          interiorSetParts.picklistInfo.forEach((info) => {
            const partsPicklist = picklistsFromStore.filter((picklist) => {
              return picklist.id === info.id;
            });
            picklists.push(...partsPicklist);
          });
        }

        const interiorSetPartsViewModel: InteriorSetPartsViewModel = {
          ...interiorSetParts,
          interior_category: interiorCategory!,
          picklists: picklists,
        };

        return interiorSetPartsViewModel;
      },
    );

    return interiorSetPartsViewModel;
  }

  /**
   * picklistStoreからpicklistの配列を取得する
   */
  private getInteriorPicklistFromStore(): Observable<PicklistViewModel[]> {
    return this.getPicklistViewModelQuery.getViewModelList();
  }
}
