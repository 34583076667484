import { JoineryOptionSetting } from '@interfaces/joinery-config';
import { JoinerySpecificationOption } from '@interfaces/joinery-specification-option';
import { createAction, props } from '@ngrx/store';

const apiPrefix = '[JoineryOptionSetting API]';
const pagePrefix = '[JoineryOptionSetting Page]';
const effectPrefix = '[JoineryOptionSetting Effect]';

export const fetch = createAction(
  pagePrefix + 'Fetch',
  props<{ projectId: number }>(),
);

export const fetchSuccess = createAction(
  apiPrefix + 'Fetch Success',
  props<{ joineryOptionSettings: JoineryOptionSetting[] }>(),
);

export const fetchFailure = createAction(
  apiPrefix + 'Fetch Failure',
  props<{ error: unknown }>(),
);

export const addSpecOption = createAction(
  pagePrefix + 'AddSpec',
  props<{
    projectId: number;
    joineryOptionSetting: JoineryOptionSetting;
    specOption: Omit<JoinerySpecificationOption, 'id' | 'order'>;
  }>(),
);

export const addSpecOptionSuccess = createAction(
  pagePrefix + 'AddSpec Success',
  props<{
    joineryOptionSetting: JoineryOptionSetting;
    specOption: JoinerySpecificationOption;
  }>(),
);

export const addSpecOptionFailure = createAction(
  pagePrefix + 'AddSpec Failure',
  props<{ error: unknown }>(),
);
