import { Injectable, inject } from '@angular/core';
import {
  MiscellaneousWorkSetStore,
  MiscellaneousWorkSet,
} from 'app/v2/general/application/store/miscellaneous-work-set.store';
import { MiscellaneousWorkPicklistViewModel } from 'app/v2/general/application/view-model/miscellaneous-work-picklist-view-model';
import {
  MiscellaneousWorkRoomGroupViewModel,
  MiscellaneousWorkPicklistInRoomGroupViewModel,
} from 'app/v2/general/application/view-model/miscellaneous-work-room-group-view-model';
import { RoomGroupViewModel } from 'app/v2/general/application/view-model/room-group-view-model';
import { Observable, combineLatest, map } from 'rxjs';

import { GetRoomGroupViewModelQuery } from '../../../../application/query/services/room-group/get-room-group-view-model-query';

import { GetMiscellaneousWorkPicklistsViewModelQuery } from './get-miscellaneous-work-picklists-view-model.query';

@Injectable({ providedIn: 'root' })
export class GetMiscellaneousWorkRoomGroupViewModelQuery {
  private getRoomGroupQuery = inject(GetRoomGroupViewModelQuery);
  private miscellaneousWorkSetStore = inject(MiscellaneousWorkSetStore);
  private getPicklistsQuery = inject(
    GetMiscellaneousWorkPicklistsViewModelQuery,
  );

  getViewModelList(): Observable<MiscellaneousWorkRoomGroupViewModel[]> {
    const roomGroups$: Observable<RoomGroupViewModel[]> =
      this.getRoomGroupQuery.getViewModelList();
    const miscellaneousWorkSets$: Observable<MiscellaneousWorkSet[]> =
      this.miscellaneousWorkSetStore.miscellaneousWorkSets$;
    const picklists$: Observable<MiscellaneousWorkPicklistViewModel[]> =
      this.getPicklistsQuery.getViewModelList();

    return combineLatest([
      roomGroups$,
      miscellaneousWorkSets$,
      picklists$,
    ]).pipe(
      map(([roomGroups, miscellaneousWorkSets, picklists]) => {
        const miscellaneousWorkRoomGroups: MiscellaneousWorkRoomGroupViewModel[] =
          miscellaneousWorkSets.map((miscellaneousWorkSet) => {
            const targetRoomGroup: RoomGroupViewModel = roomGroups.find(
              (roomGroup) => miscellaneousWorkSet.roomGroupId === roomGroup.id,
            )!;
            const targetPicklists: MiscellaneousWorkPicklistInRoomGroupViewModel[] =
              miscellaneousWorkSet.picklistInfos
                .map((picklistInfo) => {
                  const targetPicklist = picklists.find(
                    (picklist) => picklist.id === picklistInfo.picklistId,
                  )!;

                  if (targetPicklist)
                    return {
                      ...targetPicklist,
                      orderInMiscellaneousWorkRoomGroup: picklistInfo.order,
                    };
                  // targetPicklistがない時はundefinedを返し、後でfilterで除外する
                  // TODO: asアサーションしなくて済むなら変えたい
                  else
                    return undefined as unknown as MiscellaneousWorkPicklistInRoomGroupViewModel;
                })
                .filter((picklist) => picklist != undefined);

            const miscellaneousWorkRoomGroup: MiscellaneousWorkRoomGroupViewModel =
              {
                ...targetRoomGroup,
                miscellaneousWorkPicklists: targetPicklists,
              };

            return miscellaneousWorkRoomGroup;
          });

        return miscellaneousWorkRoomGroups;
      }),
    );
  }
}
