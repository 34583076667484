import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Pagination } from '../models/response/pagination';
import { Product } from '../models/response/product';
import { Series } from '../models/response/series';
import { UseCase } from '../models/response/use-case';
import { setFavoriteState } from '../service/favorite-state.service';

@Injectable({
  providedIn: 'root',
})
export class UseCaseService {
  constructor(protected http: HttpClient) {}

  index(page = 1): Observable<Pagination<UseCase>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<UseCase>>('/api/use-cases', { params });
  }

  show(id: string): Observable<UseCase> {
    return this.http.get<UseCase>(`/api/use-cases/${id}`);
  }

  series(id: string): Observable<Pagination<Series>> {
    return this.http.get<Pagination<Series>>(`/api/use-cases/${id}/series`);
  }

  products(id: string): Observable<Pagination<Product>> {
    return this.http
      .get<Pagination<Product>>(`/api/use-cases/${id}/products`)
      .pipe(tap((pagination) => setFavoriteState(...pagination.data)));
  }
}
