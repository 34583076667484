import { PicklistProductStatus } from '@shared/models/response/sub/picklist-product-status';

import { PicklistViewModel } from '../../application/view-model/picklist-view-model';
import { PicklistType } from '../enums/picklist-type.enum';
import { Picklist } from '../types/states/picklist.state';

export const createParentPicklistValue = (
  childPicklist: PicklistViewModel,
): PicklistViewModel => {
  const copiedFields: (keyof PicklistViewModel)[] = [
    'project_id',
    'type',
    'is_structural_material',
    'name',
    'specification',
    'assign_type',
    'enable_similar',
    'remarks',
    'memo',
    'enable_multiple_paste',
    'created_at',
    'updated_at',
  ];
  const setFalseFileds: (keyof PicklistViewModel)[] = ['is_standard'];

  const setTrueFields: (keyof PicklistViewModel)[] = ['is_parent'];

  let setValues: {
    [key: string]: any;
  } = {};

  Object.keys(childPicklist).forEach((key: keyof PicklistViewModel) => {
    if (copiedFields.includes(key)) {
      setValues[key] = childPicklist[key];
    } else if (setFalseFileds.includes(key)) {
      setValues[key] = false;
    } else if (setTrueFields.includes(key)) {
      setValues[key] = true;
    } else {
      setValues[key] = null;
    }
  });

  const parentPicklist = Object.assign({}, childPicklist, setValues);
  parentPicklist.id = childPicklist.group_id!;
  parentPicklist.listProductCount = 0;

  return parentPicklist;
};

export const sortPicklist = (
  categoryName: string,
  picklists: PicklistViewModel[],
): PicklistViewModel[] => {
  const distinctPicklists = picklists.filter(
    (p, index, self) => self.findIndex((s) => p.id === s.id) === index,
  );

  return distinctPicklists.slice().sort((a, b) => {
    if (categoryName === '未使用') {
      return a.id - b.id;
    } else if (a.order === null && b.order === null) {
      return a.id - b.id;
    } else if (
      a.order &&
      b.order &&
      !a.order.hasOwnProperty(categoryName!) &&
      !b.order.hasOwnProperty(categoryName!)
    ) {
      return a.id - b.id;
    } else if (
      a.order &&
      !a.order.hasOwnProperty(categoryName!) &&
      b.order === null
    ) {
      return a.id - b.id;
    } else if (
      a.order === null &&
      b.order &&
      !b.order.hasOwnProperty(categoryName!)
    ) {
      return a.id - b.id;
    } else if (
      a.order === null ||
      (a.order && !a.order!.hasOwnProperty(categoryName!))
    ) {
      return 1;
    } else if (
      b.order === null ||
      (b.order && !b.order!.hasOwnProperty(categoryName!))
    ) {
      return -1;
    } else {
      return a.order![categoryName!] - b.order![categoryName!];
    }
  });
};

export const isParentPicklist = (picklist: PicklistViewModel): boolean => {
  return picklist.is_parent == true;
};

export const isStandardPicklist = (picklist: PicklistViewModel): boolean => {
  return picklist.is_standard == true;
};

export const isChildPicklist = (picklist: PicklistViewModel): boolean => {
  return picklist.is_parent == false;
};

export const getInteriorPicklists = (picklists: PicklistViewModel[]) => {
  return picklists.filter((picklist) => picklist.type == PicklistType.Interior);
};

export const getInteriorPicklistsByIds = (
  picklists: PicklistViewModel[],
  targetPicklistIds: number[],
): PicklistViewModel[] => {
  return getInteriorPicklists(picklists).filter((picklist) =>
    targetPicklistIds.includes(picklist.id),
  );
};

export const getInteriorChildPicklistsByGroupIds = (
  picklists: PicklistViewModel[],
  targetPicklistGroupIds: number[],
): PicklistViewModel[] => {
  return getInteriorPicklists(picklists).filter(
    (picklist) =>
      picklist.group_id && targetPicklistGroupIds.includes(picklist.group_id),
  );
};

export const getInteriorParentPicklists = (
  picklists: PicklistViewModel[],
): PicklistViewModel[] => {
  return getInteriorPicklists(picklists).filter((picklist) =>
    isParentPicklist(picklist),
  );
};

export const getInteriorChildPicklists = (
  picklists: PicklistViewModel[],
): PicklistViewModel[] => {
  return getInteriorPicklists(picklists).filter((picklist) =>
    isChildPicklist(picklist),
  );
};

export const findInteriorParentPicklistByGroupId = (
  parentPicklists: PicklistViewModel[],
  picklistGroupId: number,
): PicklistViewModel | null => {
  const picklist = getInteriorParentPicklists(parentPicklists).find(
    (parentPicklist) => parentPicklist.id == picklistGroupId,
  );

  return picklist ?? null;
};

export const convertUniquePicklists = (
  picklists: PicklistViewModel[],
): PicklistViewModel[] => {
  return picklists.filter(
    (picklist, i, self) =>
      self.findIndex((_picklist) => picklist.id === _picklist.id) == i,
  );
};

export const getInteriorNeighborChildPicklists = (
  picklists: PicklistViewModel[],
  targetPicklist: PicklistViewModel,
): PicklistViewModel[] => {
  if (isChildPicklist(targetPicklist)) {
    return getInteriorChildPicklists(picklists).filter(
      (picklist) =>
        picklist.group_id &&
        picklist.group_id == targetPicklist.group_id &&
        picklist.id !== targetPicklist.id,
    );
  } else {
    return getInteriorChildPicklistsByGroupIds(picklists, [targetPicklist.id]);
  }
};

export const validatePicklistName = (
  targetPicklistName: string,
  targetPicklistType: PicklistType,
  picklists: Picklist[] | PicklistViewModel[],
): string => {
  if (targetPicklistName == '') {
    return '必須入力です';
  }

  if (
    picklists.filter(
      (p) => !p.is_parent && p.name == targetPicklistName && targetPicklistType,
    ).length > 0
  ) {
    return '同名の材料が既に存在するため、この名前は使用できません。';
  }

  return '';
};

export const createNewPicklistName = (
  targetPicklist: PicklistViewModel,
  neighborPicklists: PicklistViewModel[],
): string => {
  const prePicklistName = targetPicklist.name;
  const neighborPicklistNames = neighborPicklists.map(
    (neighborPicklist) => neighborPicklist.name,
  );
  let num =
    [...neighborPicklistNames, ...[prePicklistName]]
      .filter((name) => {
        return name.match(/^.*\(([A-Z]+)\)$/);
      })
      .map((name) => {
        const str = name.match(/^.*\(([A-Z]+)\)$/);
        if (!str || !str[1]) {
          return 0;
        }
        return Array.from(str[1])
          .reverse()
          .reduce((sum, _str, i) => {
            return sum + (_str.charCodeAt(0) - 65 + 1) * 26 ** i;
          }, 0);
      })
      .reduce((max, charCode) => {
        return Math.max(max, charCode);
      }, 0) + 1;
  let temp,
    letter = '';
  while (num > 0) {
    temp = (num - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    num = (num - temp - 1) / 26;
  }
  return `${prePicklistName.replace(/\(([A-Z]+)\)$/, '')}(${letter})`;
};

export const isFixedPicklist = (picklist: PicklistViewModel): boolean => {
  if (isParentPicklist(picklist)) {
    return false;
  }
  if (picklist.listProducts.length === 0) {
    return false;
  }

  const fixedListProduct = picklist.listProducts.find(
    (listProduct) => listProduct.status === PicklistProductStatus.Fixed,
  );

  return !!fixedListProduct;
};

export const checkProhibitedCharacters = (
  picklistName: string,
): string | null => {
  const prohibitedCharacters: string[] = [
    ':',
    '{',
    '}',
    '[',
    ']',
    '|',
    ';',
    '<',
    '>',
    '?',
    "'",
    '~',
  ];

  let message = null;
  const usedProhibitedCharacters: string[] = [];

  prohibitedCharacters.forEach((prohibitedCharacter) => {
    if (picklistName.includes(prohibitedCharacter)) {
      usedProhibitedCharacters.push(prohibitedCharacter);
    }
  });

  if (usedProhibitedCharacters.length > 0) {
    message = `材料名に　${usedProhibitedCharacters.join(
      '　',
    )}　は使用できません`;
  }

  return message;
};

export const checkProhibitedSpace = (picklistName: string) => {
  let message = null;
  const regex = new RegExp('.* +$');

  if (picklistName.match(regex)) {
    message = '材料名の末尾に半角スペースは使用できません';
  }

  return message;
};
