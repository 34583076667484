import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import {
  InteriorSetParts,
  InteriorSetPartsState,
} from '../../domain/types/states/interior-set-parts.state';

@Injectable({
  providedIn: 'root',
})
export class InteriorSetPartsStore extends ComponentStore<InteriorSetPartsState> {
  constructor() {
    super({
      interiorSetParts: [],
    });
  }

  interiorSetParts$: Observable<InteriorSetParts[]> = this.select((state) => {
    return state.interiorSetParts;
  });

  updateState = this.updater(
    (state: InteriorSetPartsState, value: InteriorSetParts[]) => {
      return {
        ...state,
        interiorSetParts: value,
      };
    },
  );

  addInteriorSetParts = this.updater(
    (state: InteriorSetPartsState, value: InteriorSetParts) => {
      return {
        interiorSetParts: [...state.interiorSetParts, value],
      };
    },
  );

  addManyInteriorSetParts = this.updater(
    (state: InteriorSetPartsState, value: InteriorSetParts[]) => {
      return {
        interiorSetParts: [...state.interiorSetParts, ...value],
      };
    },
  );

  deleteInteriorSetParts = this.updater(
    (state: InteriorSetPartsState, deleteItem: InteriorSetParts) => {
      const newState = state.interiorSetParts.filter((parts) => {
        return parts.id !== deleteItem.id;
      });
      return { interiorSetParts: newState };
    },
  );

  deleteInteriorSetPartsByInteriorSetId = this.updater(
    (state: InteriorSetPartsState, deleteInteriorSetId: number) => {
      const newState = state.interiorSetParts.filter((parts) => {
        return parts.id !== deleteInteriorSetId;
      });
      return { interiorSetParts: newState };
    },
  );

  deleteManyInteriorSetParts = this.updater(
    (state: InteriorSetPartsState, deletePartsIds: number[]) => {
      const newState = state.interiorSetParts.filter((parts) => {
        return !deletePartsIds.includes(parts.id);
      });
      return { interiorSetParts: newState };
    },
  );

  updateInteriorSetParts = this.updater((state, newItem: InteriorSetParts) => {
    const updatedItems = state.interiorSetParts.map((item) => {
      if (item.id === newItem.id) {
        return newItem;
      }
      return item;
    });

    return { ...state, interiorSetParts: updatedItems };
  });

  updateManyInteriorSetParts = this.updater(
    (state, newItems: InteriorSetParts[]) => {
      const updatedItems = state.interiorSetParts.map((item) => {
        const matchingItem = newItems.find((newItem) => newItem.id === item.id);
        if (matchingItem) {
          return { ...item, ...matchingItem };
        }
        return item;
      });

      return { ...state, interiorSetParts: updatedItems };
    },
  );

  removePicklistInfosByPicklistIds = this.updater(
    (state: InteriorSetPartsState, deletePicklistIds: number[]) => {
      const newItems = state.interiorSetParts.map((interiorSetPart) => {
        interiorSetPart.picklistInfo = interiorSetPart.picklistInfo.filter(
          (picklistInfo) => {
            return !deletePicklistIds.includes(picklistInfo.id);
          },
        );
        return interiorSetPart;
      });

      return {
        interiorSetParts: newItems,
      };
    },
  );
}
