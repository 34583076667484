import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JoineryPageApiService } from 'app/services/api/joinery-page-api.service';
import { catchError, map, of, switchMap } from 'rxjs';

import { symbolWithNumberAction } from './actions';

@Injectable()
export class SymbolWithNumberEffects {
  fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(symbolWithNumberAction.fetchAll),
      switchMap(({ projectId, region, businessGroupId }) => {
        return this.joineryPageApi
          .getSymbolWithNumbers(projectId, region, businessGroupId)
          .pipe(
            map((result) =>
              symbolWithNumberAction.fetchAllSuccess({
                symbolWithNumbers: result,
              }),
            ),
            catchError((error) =>
              of(symbolWithNumberAction.fetchAllFailure({ error })),
            ),
          );
      }),
    ),
  );
  constructor(
    private readonly actions$: Actions,
    private joineryPageApi: JoineryPageApiService,
  ) {}
}
