import { JoineryComponentWithStandard } from '@interfaces/joinery-component';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
export interface JoineryComponentState
  extends EntityState<JoineryComponentWithStandard> {
  error?: unknown;
  isFetching: boolean;
  processingIds: number[];
}

export const JoineryComponentAdapter =
  createEntityAdapter<JoineryComponentWithStandard>();

export const initialJoineryComponentState: JoineryComponentState =
  JoineryComponentAdapter.getInitialState({
    isFetching: false,
    processingIds: [],
  });
