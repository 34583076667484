import { Injectable } from '@angular/core';

import { Product } from '../models/response/product';

// key: productId, value: isFavOrNot
const stateMap = new Map<string, boolean>();

export const setFavoriteState = (...products: Product[]) => {
  products.forEach((p) => stateMap.set(p.id, p.is_fav));
};

export const getFavoriteState = (productId: string): boolean | undefined => {
  // undefinedは不明の意味
  return stateMap.get(productId);
};

export const isFav = (product: Product) => {
  const state = getFavoriteState(product.id);
  return state === undefined ? product.is_fav : state;
};

@Injectable({
  providedIn: 'root',
})
export class FavoriteStateService {
  setFavoriteState(...products: Product[]) {
    setFavoriteState(...products);
  }

  getFavoriteState(productId: string): boolean | undefined {
    return getFavoriteState(productId);
  }

  isFav(product: Product) {
    return isFav(product);
  }

  resetState() {
    stateMap.clear();
  }
}
