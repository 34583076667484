import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export type EditPermission = {
  canEdit: boolean | undefined;
  canRequest: boolean | undefined;
  isOnlyRequest: boolean | undefined;
  isBrowseMode: boolean | undefined;
};
type EditPermissionState = {
  editPermissionState: EditPermission;
};

/**
 * 編集権限・閲覧権限状態管理用ストア
 */
@Injectable({
  providedIn: `root`,
})
export class EditPermissionStore extends ComponentStore<EditPermissionState> {
  constructor() {
    super({
      editPermissionState: {
        canEdit: undefined,
        canRequest: undefined,
        isOnlyRequest: undefined,
        isBrowseMode: undefined,
      },
    });
  }

  readonly editPermissionState$: Observable<EditPermission> = this.select(
    (state) => {
      return state.editPermissionState;
    },
  );

  readonly canEdit$: Observable<boolean | undefined> = this.select((state) => {
    return state.editPermissionState.canEdit;
  });

  readonly canRequest$: Observable<boolean | undefined> = this.select(
    (state) => {
      return state.editPermissionState.canRequest;
    },
  );

  readonly isOnlyRequest$: Observable<boolean | undefined> = this.select(
    (state) => {
      return state.editPermissionState.isOnlyRequest;
    },
  );

  readonly isBrowseMode$: Observable<boolean | undefined> = this.select(
    (state) => {
      return state.editPermissionState.isBrowseMode;
    },
  );

  readonly updateEditPermissionState = this.updater(
    (state: EditPermissionState, value: EditPermission) => {
      return {
        editPermissionState: value,
      };
    },
  );

  readonly updateCanEdit = this.updater(
    (state: EditPermissionState, canEdit: boolean | undefined) => {
      const updateValue: EditPermission = {
        ...state.editPermissionState,
        canEdit: canEdit,
      };

      if (!canEdit) {
        updateValue.isBrowseMode = true;
      } else {
        updateValue.isBrowseMode = false;
      }

      return {
        editPermissionState: updateValue,
      };
    },
  );

  readonly updateCanRequest = this.updater(
    (state: EditPermissionState, canRequest: boolean | undefined) => {
      const updateValue: EditPermission = {
        ...state.editPermissionState,
        canRequest: canRequest,
      };

      if (!!canRequest && !updateValue.canEdit) {
        updateValue.isOnlyRequest = true;
      }

      if (
        (!!canRequest && !!updateValue.canEdit) ||
        (!canRequest && !updateValue.canEdit)
      ) {
        updateValue.isOnlyRequest = false;
      }

      return {
        editPermissionState: updateValue,
      };
    },
  );

  readonly updateIsBrowseMode = this.updater(
    (state: EditPermissionState, isBrowseMode: boolean | undefined) => {
      const updateValue: EditPermission = {
        ...state.editPermissionState,
        isBrowseMode: isBrowseMode,
      };

      return {
        editPermissionState: updateValue,
      };
    },
  );
}
