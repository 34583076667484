import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { CandidateProduct } from '@shared/models/response/list-product';
import { PicklistProductStatus } from '@shared/models/response/sub/picklist-product-status';

export interface CandidateProductState extends EntityState<CandidateProduct> {
  error?: unknown;
  isFetching: boolean;
  selectedId?: string;
  filter?: CandidateListFilter;
  processingIds: number[];
}

export interface CandidateListFilter {
  status?: PicklistProductStatus | null;
  addedById?: number | null;
  picklistId?: number | null;
}

export const candidateProductAdapter = createEntityAdapter<CandidateProduct>();

export const initialCandidateProductState: CandidateProductState =
  candidateProductAdapter.getInitialState({
    isFetching: false,
    processingIds: [],
  });
