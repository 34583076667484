import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import {
  RoomGroup,
  RoomGroupState,
} from '../../domain/types/states/room-group.state';

@Injectable({
  providedIn: 'root',
})
export class RoomGroupStore extends ComponentStore<RoomGroupState> {
  constructor() {
    super({
      roomGroups: [],
    });
  }

  roomGroups$: Observable<RoomGroup[]> = this.select(
    (state) => state.roomGroups,
  );

  addRoomGroup = this.updater((state: RoomGroupState, value: RoomGroup) => {
    return {
      roomGroups: [...state.roomGroups, value],
    };
  });

  addManyRoomGroup = this.updater(
    (state: RoomGroupState, value: RoomGroup[]) => {
      return {
        roomGroups: [...state.roomGroups, ...value],
      };
    },
  );

  deleteRoomGroup = this.updater(
    (state: RoomGroupState, deleteItemId: number) => {
      const newState = state.roomGroups.filter((roomGroup) => {
        return roomGroup.id !== deleteItemId;
      });
      return { roomGroups: newState };
    },
  );

  deleteManyRoomGroup = this.updater(
    (state: RoomGroupState, deleteRoomGroupIds: number[]) => {
      const newState = state.roomGroups.filter((roomGroup) => {
        return !deleteRoomGroupIds.includes(roomGroup.id);
      });
      return { roomGroups: newState };
    },
  );

  updateRoomGroup = this.updater((state, newItem: RoomGroup) => {
    const updatedItems = state.roomGroups.map((item) => {
      if (item.id === newItem.id) {
        return newItem;
      }
      return item;
    });

    return { ...state, roomGroups: updatedItems };
  });

  updateManyRoomGroup = this.updater((state, newItems: RoomGroup[]) => {
    const updatedItems = state.roomGroups.map((item) => {
      const matchingItem = newItems.find((newItem) => newItem.id === item.id);
      if (matchingItem) {
        return { ...item, ...matchingItem };
      }
      return item;
    });

    return { ...state, roomGroups: updatedItems };
  });
}
