import { Joinery } from '@interfaces/joinery';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Meta, Links } from '@shared/models/response/pagination';

export interface JoineryState extends EntityState<Joinery> {
  error?: unknown;
  isFetching: boolean;
  links?: Links;
  meta?: Meta;
  processingIds: number[];
}

export const JoineryAdapter = createEntityAdapter<Joinery>();

export const initialJoineryState: JoineryState = JoineryAdapter.getInitialState(
  { isFetching: false, processingIds: [] },
);
