import { JoineryOptionSetting } from '@interfaces/joinery-config';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { joineryOptionSettingAction } from './actions';
import {
  initialJoineryOptionSettingState,
  JoineryOptionSettingAdapter,
} from './joinery-option-setting.state';

export const JoineryOptionSettingReducer = createReducer(
  initialJoineryOptionSettingState,
  on(joineryOptionSettingAction.fetch, (state, { projectId }) =>
    JoineryOptionSettingAdapter.removeAll({
      ...state,
      isFetching: true,
    }),
  ),
  on(
    joineryOptionSettingAction.fetchSuccess,
    (state, { joineryOptionSettings }) => {
      return JoineryOptionSettingAdapter.setAll(joineryOptionSettings, {
        ...state,
        isFetching: false,
      });
    },
  ),
  on(joineryOptionSettingAction.fetchFailure, (state, error) => ({
    ...state,
    error,
    isFetching: false,
  })),
  on(
    joineryOptionSettingAction.addSpecOption,
    (state, { projectId, joineryOptionSetting, specOption }) => ({
      ...state,
      processingIds: [...state.processingIds, joineryOptionSetting.id],
    }),
  ),
  on(
    joineryOptionSettingAction.addSpecOptionSuccess,
    (state, { joineryOptionSetting, specOption }) => {
      const change: Update<JoineryOptionSetting> = {
        id: joineryOptionSetting.id,
        changes: {
          ...joineryOptionSetting,
          options: [...joineryOptionSetting.options, specOption],
        },
      };
      return JoineryOptionSettingAdapter.updateOne(change, { ...state });
    },
  ),
  on(joineryOptionSettingAction.addSpecOptionFailure, (state, error) => ({
    ...state,
    error,
  })),
);
