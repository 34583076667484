import { JoineryPageInfo } from '@interfaces/joinery-page-info';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface JoineryPageInfoState extends EntityState<JoineryPageInfo> {
  error?: unknown;
  isFetching: boolean;
}

export const JoineryPageInfoAdapter = createEntityAdapter<JoineryPageInfo>({
  // 一つのデータしか入らないので固定で１を指定する
  selectId: () => 1,
});

export const initialJoineryPageInfoState: JoineryPageInfoState =
  JoineryPageInfoAdapter.getInitialState({ isFetching: false });
