import {
  JoineryComponent,
  JoineryComponentWithStandard,
} from '@interfaces/joinery-component';
import { JoineryStandardComponent } from '@interfaces/joinery-standard-component';
import { createAction, props } from '@ngrx/store';
import { Picklist } from '@shared/models/response/picklist';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';

const apiPrefix = '[JoineryComponent API]';
const pagePrefix = '[JoineryComponent Page]';
const effectPrefix = '[JoineryComponent Effect]';

export const create = createAction(
  pagePrefix + 'Create',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    component: Omit<JoineryComponent, 'id'>;
    standardComponent: JoineryStandardComponent;
  }>(),
);

export const createSuccess = createAction(
  apiPrefix + 'Create Success',
  props<{ component: JoineryComponentWithStandard }>(),
);

export const createFailure = createAction(
  apiPrefix + 'Create Failure',
  props<{ error: unknown }>(),
);

export const setAll = createAction(
  `${apiPrefix} SetAll`,
  props<{ components: JoineryComponentWithStandard[] }>(),
);

export const setsByEffect = createAction(
  `${effectPrefix} SetsByEffect`,
  props<{ components: JoineryComponentWithStandard[] }>(),
);

export const update = createAction(
  `${pagePrefix} Update`,
  props<{ component: JoineryComponent }>(),
);

export const updateSuccess = createAction(
  `${apiPrefix} Update Success`,
  props<{ component: JoineryComponent }>(),
);

export const updateFailure = createAction(
  `${apiPrefix} Update Failure`,
  props<{ error: unknown }>(),
);

export const remove = createAction(
  '[JoineryComponent Page] Remove',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    component: JoineryComponent;
  }>(),
);

export const removeSuccess = createAction(
  '[JoineryComponent API] Remove Success',
);

export const removeFailure = createAction(
  '[JoineryComponent API] Remove Failure',
  props<{ error: unknown }>(),
);

export const pastePicklist = createAction(
  `${pagePrefix} PastePicklist`,
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    component: JoineryComponentWithStandard;
    picklist: Picklist;
  }>(),
);

export const pastePicklistSuccess = createAction(
  `${apiPrefix} PastePicklist Success`,
  props<{
    component: JoineryComponentWithStandard;
  }>(),
);

export const pastePicklistFailure = createAction(
  `${apiPrefix} PastePicklist Failure`,
  props<{
    error: unknown;
  }>(),
);

export const pastePicklistMany = createAction(
  `${pagePrefix} PastePicklistMany`,
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    components: JoineryComponentWithStandard[];
    picklistId: number | null;
  }>(),
);

export const pastePicklistManySuccess = createAction(
  `${pagePrefix} PastePicklistMany Success`,
  props<{ components: JoineryComponentWithStandard[] }>(),
);

export const pastePicklistManyFailure = createAction(
  `${pagePrefix} PastePicklistMany Failure`,
  props<{ error: unknown }>(),
);

export const removePicklist = createAction(
  `${apiPrefix} RemovePicklist`,
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    component: JoineryComponentWithStandard;
    picklistId: number;
  }>(),
);

export const removePicklistSuccess = createAction(
  `${apiPrefix} RemovePicklist Success`,
  props<{
    component: JoineryComponentWithStandard;
  }>(),
);

export const removePicklistFailure = createAction(
  `${apiPrefix} RemovePicklist Failure`,
  props<{
    error: unknown;
  }>(),
);

export const removePicklistMany = createAction(
  `${apiPrefix} RemovePicklistMany`,
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    components: JoineryComponentWithStandard[];
  }>(),
);

export const removePicklistManySuccess = createAction(
  `${apiPrefix} RemovePicklistMany Success`,
  props<{
    components: JoineryComponentWithStandard[];
  }>(),
);

export const removePicklistManyFailure = createAction(
  `${apiPrefix} RemovePicklistMany Failure`,
  props<{
    error: unknown;
  }>(),
);

export const removePicklistManyByEffect = createAction(
  `${effectPrefix} RemovePicklistManyByEffect`,
  props<{ components: JoineryComponentWithStandard[] }>(),
);

export const setPicklist = createAction(
  `[JoineryComponent] SetPicklist`,
  props<{ component: JoineryComponentWithStandard; picklistId: number }>(),
);
