import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Joinery } from '@interfaces/joinery';
import { JoineryComponent } from '@interfaces/joinery-component';
import { JoineryOptionSetting } from '@interfaces/joinery-config';
import { JoineryPageInfo } from '@interfaces/joinery-page-info';
import { JoinerySpecificationOption } from '@interfaces/joinery-specification-option';
import { SymbolWithNumber } from '@interfaces/symbol-with-number';
import { Pagination } from '@shared/models/response/pagination';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { Observable } from 'rxjs';

export interface JoinerySearchParam {
  symbolSpecifications?: string;
  picklistSpecifications?: string;
  standardComponentIds?: string;
  symbols?: string[];
  number?: string;
  roomToRoomFloors?: string;
  roomToRoomNames?: string;
}

@Injectable({
  providedIn: 'root',
})
export class JoineryPageApiService {
  constructor(private http: HttpClient) {}

  fetchJoineryPageInfo(
    projectId: number,
    joineryRegionType: ProjectRangeType,
  ): Observable<JoineryPageInfo> {
    return this.http.get<JoineryPageInfo>(
      `/api/projects/${projectId}/${joineryRegionType}`,
    );
  }

  fetchPage(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    page?: number,
    searchParam?: JoinerySearchParam,
  ): Observable<{
    joineries: Pagination<Joinery>;
    components: JoineryComponent[];
  }> {
    const symbolParam = searchParam?.symbols?.toString() ?? undefined;
    const numberParam = searchParam?.number ?? undefined;
    return this.http.get<{
      joineries: Pagination<Joinery>;
      components: JoineryComponent[];
    }>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries`,
      {
        params: new HttpParams()
          .set('page', page ?? '1')
          .set(
            'symbol_specification_search',
            searchParam?.symbolSpecifications ?? '',
          )
          .set('component_search', searchParam?.standardComponentIds ?? '')
          .set('symbol_search', symbolParam ?? '')
          .set('number_search', numberParam ?? '')
          .set('floor_search', searchParam?.roomToRoomFloors ?? '')
          .set('room_to_rooms_search', searchParam?.roomToRoomNames ?? '')
          .set('picklist_specification_search', searchParam?.picklistSpecifications ?? '')
          .set('embeds', 'components,symbol_specifications'),
      },
    );
  }

  getSymbolWithNumbers(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
  ): Observable<SymbolWithNumber[]> {
    return this.http.get<SymbolWithNumber[]>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/symbols-with-number`,
    );
  }

  getJoineryOptionSettings(
    projectId: number,
  ): Observable<JoineryOptionSetting[]> {
    return this.http.get<JoineryOptionSetting[]>(
      `/api/projects/${projectId}/joinery-option-settings`,
    );
  }

  addJoinerySpecOption(
    projectId: number,
    joinerySpecOption: Omit<JoinerySpecificationOption, 'id' | 'order'>,
  ) {
    return this.http.post<JoinerySpecificationOption>(
      `/api/projects/${projectId}/joinery-option-settings`,
      { ...joinerySpecOption, id: null, order: null },
    );
  }
}
