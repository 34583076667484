import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { FilteredVariationTotalCount } from '@shared/models/request/product-variation-request';
import { CandidateProduct } from '@shared/models/response/list-product';
import { Picklist } from '@shared/models/response/picklist';
import { PicklistProductStatus } from '@shared/models/response/sub/picklist-product-status';

import { CandidateListFilter } from '../states';

export const fetchAll = createAction(
  '[CandidateProduct Page] Fetch All',
  props<{ picklists: Picklist[] }>(),
);

export const fetchAllSuccess = createAction(
  '[CandidateProduct API] Fetch All Success',
  props<{ candidateProducts: CandidateProduct[] }>(),
);

export const fetchAllFailure = createAction(
  '[CandidateProduct API] Fetch All Failure',
  props<{ error: unknown }>(),
);

export const fetch = createAction(
  '[CandidateProduct Page] Fetch',
  props<{ id: string }>(),
);

export const fetchSuccess = createAction(
  '[CandidateProduct API] Fetch Success',
  props<{ candidateProduct: CandidateProduct }>(),
);

export const fetchFailure = createAction(
  '[CandidateProduct API] Fetch Failure',
  props<{ error: unknown }>(),
);

export const create = createAction(
  '[CandidateProduct Page] Create',
  props<{ candidateProduct: CandidateProduct }>(),
);

export const createSuccess = createAction(
  '[CandidateProduct API] Create Success',
  props<{ candidateProduct: CandidateProduct }>(),
);

export const createFailure = createAction(
  '[CandidateProduct API] Create Failure',
  props<{ error: unknown }>(),
);

export const createMany = createAction(
  '[CandidateProduct Page] CreateMany',
  props<{ candidateProducts: CandidateProduct[] }>(),
);

export const createManySuccess = createAction(
  '[CandidateProduct API] CreateMany Success',
  props<{ candidateProducts: CandidateProduct[] }>(),
);

export const createManyFailure = createAction(
  '[CandidateProduct API] CreateMany Failure',
  props<{ error: unknown }>(),
);

export const update = createAction(
  '[CandidateProduct Page] Update',
  props<{
    candidateProduct: CandidateProduct;
    status?: PicklistProductStatus;
  }>(),
);

export const updateSuccess = createAction(
  '[CandidateProduct API] Update Success',
  props<{ candidateProduct: CandidateProduct }>(),
);

export const updateFailure = createAction(
  '[CandidateProduct API] Update Failure',
  props<{ error: unknown; id: number }>(),
);

export const updateManySuccess = createAction(
  '[CandidateProduct API] UpdateMany Success',
  props<{
    candidateProducts: Update<CandidateProduct>[];
  }>(),
);

export const remove = createAction(
  '[CandidateProduct Page] Remove',
  props<{ id: number }>(),
);

export const removeSuccess = createAction(
  '[CandidateProduct API] Remove Success',
  props<{ id: number }>(),
);

export const removeFailure = createAction(
  '[CandidateProduct API] Remove Failure',
  props<{ error: unknown; id: number }>(),
);

export const setFilter = createAction(
  '[CandidateProduct Page] SetFilter',
  props<{ filter: CandidateListFilter }>(),
);

export const removeFilter = createAction(
  '[CandidateProduct Page] RemoveFilter',
);

export const addProcessingId = createAction(
  '[CandidateProduct Page] AddProcessingId',
  props<{ id: number }>(),
);

export const getVariationTotal = createAction(
  '[CandidateProduct Page] GetVariationTotal',
  props<{ candidateProducts: CandidateProduct[] }>(),
);

export const getVariationTotalSuccess = createAction(
  '[CandidateProduct Page] GetVariationTotalSuccess',
  props<{
    candidateProducts: CandidateProduct[];
    filteredVariationTotalCount: FilteredVariationTotalCount[];
  }>(),
);

export const getVariationTotalFailure = createAction(
  '[CandidateProduct Page] GetVariationTotalFailure',
  props<{ error: unknown }>(),
);
