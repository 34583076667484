import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { FeatureKey } from '../enums/feature-key.enum';

import { CandidateProductEffects } from './effects';
import { candidateProductReducer } from './reducers/candidate-product.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(
      FeatureKey.CandidateProduct,
      candidateProductReducer,
    ),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      connectInZone: true,
    }),
    EffectsModule.forFeature([CandidateProductEffects]),
  ],
})
export class CandidateProductStoreModule {}
