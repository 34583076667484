import { Injectable, inject } from '@angular/core';
import { RoomBlock } from 'app/v2/general/domain/types/states/room-block.state';
import { Observable, combineLatest, map } from 'rxjs';

import { RoomBlockStore } from '../../../store/room-block.store';
import {
  RoomBlockPartsViewModel,
  RoomBlockViewModel,
} from '../../../view-model/room-block-view-model';
import { GetInteriorPicklistViewModelQuery } from '../picklist/get-interior-picklist-view-model-query';

/**
 * FloorGroupViewModel生成クラス
 */
@Injectable({
  providedIn: 'root',
})
export class GetRoomBlockViewModelQuery {
  private roomBlockStore = inject(RoomBlockStore);
  private getInteirorPicklistViewModelQuery = inject(
    GetInteriorPicklistViewModelQuery,
  );

  getViewModelList(): Observable<RoomBlockViewModel[]> {
    return combineLatest([
      this.getRoomBlocksFromStore(),
      this.getInteirorPicklistViewModelQuery.getViewModelList(),
    ]).pipe(
      map(([roomBlocks, picklistViewModels]) => {
        return roomBlocks.map((roomBlock: RoomBlock) => {
          Object.entries(roomBlock.parts).forEach(
            ([interiorCategoryId, roomBlockParts]) => {
              const picklistIds = roomBlockParts.picklistInfo.map(
                (picklistinfo) => picklistinfo.id,
              );
              const picklists = picklistViewModels.filter((picklist) =>
                picklistIds.includes(picklist.id),
              );

              roomBlock.parts[parseInt(interiorCategoryId)] = {
                ...roomBlockParts,
                picklists,
              } as RoomBlockPartsViewModel;
            },
          );
          return roomBlock as RoomBlockViewModel;
        });
      }),
    );
  }

  private getRoomBlocksFromStore(): Observable<RoomBlock[]> {
    const roomBlocks$ = this.roomBlockStore.roomBlocks$;
    return roomBlocks$;
  }
}
