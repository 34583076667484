import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import { RoomGroup } from '../../domain/types/states/room-group.state';

export type MiscellaneousWorkSet = {
  roomGroupId: number;
  picklistInfos: MiscellaneousWorkPicklistInfo[];
};

export type MiscellaneousWorkPicklistInfo = {
  picklistId: number;
  order: number;
};

export type MiscellaneousWorkSetState = {
  miscellaneousWorkSets: MiscellaneousWorkSet[];
};

@Injectable({ providedIn: 'root' })
export class MiscellaneousWorkSetStore extends ComponentStore<MiscellaneousWorkSetState> {
  constructor() {
    super({
      miscellaneousWorkSets: [],
    });
  }

  miscellaneousWorkSets$ = this.select((state) => state.miscellaneousWorkSets);

  addPicklistInfo = this.updater(
    (
      state: MiscellaneousWorkSetState,
      value: { roomGroupId: number; addPicklistId: number; order: number },
    ) => {
      const newSets = state.miscellaneousWorkSets.map((v) => {
        if (value.roomGroupId === v.roomGroupId) {
          v.picklistInfos.push({
            picklistId: value.addPicklistId,
            order: value.order,
          });
        }

        return v;
      });

      return {
        miscellaneousWorkSets: newSets,
      };
    },
  );

  sortMiscellaneousWorkSets = this.updater(
    (state: MiscellaneousWorkSetState, value: MiscellaneousWorkSet) => {
      const newSet = state.miscellaneousWorkSets.filter((set) => {
        {
          return set.roomGroupId !== value.roomGroupId;
        }
      });

      return {
        miscellaneousWorkSets: [...newSet, value],
      };
    },
  );

  deletePicklistInfo = this.updater(
    (state: MiscellaneousWorkSetState, deletePicklistId: number) => {
      const newSets = state.miscellaneousWorkSets.map((set) => {
        set.picklistInfos = set.picklistInfos.filter(
          (picklistInfo) => deletePicklistId !== picklistInfo.picklistId,
        );
        return set;
      });

      return { miscellaneousWorkSets: newSets };
    },
  );

  removePicklistInfo = this.updater(
    (
      state: MiscellaneousWorkSetState,
      target: { picklistId: number; roomGroupId: number },
    ) => {
      const newSets = state.miscellaneousWorkSets.map((set) => {
        if (set.roomGroupId === target.roomGroupId)
          set.picklistInfos = set.picklistInfos.filter(
            (picklistInfo) => picklistInfo.picklistId !== target.picklistId,
          );
        return set;
      });

      return { miscellaneousWorkSets: newSets };
    },
  );

  addRooms = this.updater(
    (state: MiscellaneousWorkSetState, roomGrous: RoomGroup[]) => {
      const addedSets: MiscellaneousWorkSet[] = roomGrous.map((roomGroup) => {
        const addedSet: MiscellaneousWorkSet = {
          roomGroupId: roomGroup.id,
          picklistInfos: [],
        };
        return addedSet;
      });
      const newSets = [...state.miscellaneousWorkSets, ...addedSets];
      return { miscellaneousWorkSets: newSets };
    },
  );

  addRoomWithId = this.updater(
    (state: MiscellaneousWorkSetState, roomGroupIds: number[]) => {
      const addedSets: MiscellaneousWorkSet[] = roomGroupIds
        .map((id) => {
          const idExists = state.miscellaneousWorkSets.some(
            (set) => set.roomGroupId === id,
          );
          if (!idExists) {
            const addedSet: MiscellaneousWorkSet = {
              roomGroupId: id,
              picklistInfos: [],
            };
            return addedSet;
          }

          return undefined;
        })
        .filter(Boolean) as MiscellaneousWorkSet[];

      const newSets = [...state.miscellaneousWorkSets, ...addedSets];
      return { miscellaneousWorkSets: newSets };
    },
  );

  deleteRooms = this.updater(
    (state: MiscellaneousWorkSetState, roomGroupId: number) => {
      const newSets = state.miscellaneousWorkSets.filter(
        (item) => item.roomGroupId !== roomGroupId,
      );
      return { miscellaneousWorkSets: newSets };
    },
  );

  deleteFloor = this.updater(
    (state: MiscellaneousWorkSetState, roomGroupIds: number[]) => {
      const newSets = state.miscellaneousWorkSets.filter(
        (item) => !roomGroupIds.includes(item.roomGroupId),
      );

      return { miscellaneousWorkSets: newSets };
    },
  );
}
