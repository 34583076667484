import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import {
  AddExteriorSetDialogComponent,
  AddExteriorSetResult,
} from './add-exterior-set-dialog.component';

@Injectable()
export class AddExteriorSetDialogService extends MatDialog {
  openDialog(
    groupNameList: string[],
  ): MatDialogRef<AddExteriorSetDialogComponent, AddExteriorSetResult> {
    const baseConfig = {
      minWidth: 600,
    };
    const config = Object.assign({}, baseConfig, { data: { groupNameList } });

    return this.open(AddExteriorSetDialogComponent, config);
  }
}
