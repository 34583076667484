import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Picklist } from '@shared/models/response/picklist';

export interface PicklistState extends EntityState<Picklist> {
  error?: unknown;
  isFetching: boolean;
  selectedId?: string;
  filter?: PicklistFilterParam;
  processingIds: number[];
}

export const picklistAdapter = createEntityAdapter<Picklist>();

export interface PicklistFilterParam {
  searchKey?: string;
  used?: boolean;
  standard?: boolean;
}

export const initialPicklistState: PicklistState =
  picklistAdapter.getInitialState({
    isFetching: false,
    processingIds: [],
  });
