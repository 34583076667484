import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import {
  InteriorCategoryState,
  InteriorCategory,
} from '../../domain/types/states/interior-category.state';

@Injectable({
  providedIn: 'root',
})
export class InteriorCategoryStore extends ComponentStore<InteriorCategoryState> {
  constructor() {
    super({
      interiorCategories: [],
    });
  }

  interiorCategories$: Observable<InteriorCategory[]> = this.select(
    (state) => state.interiorCategories,
  );

  updateState = this.updater(
    (state: InteriorCategoryState, value: InteriorCategoryState) => {
      return {
        ...value,
      };
    },
  );
}
