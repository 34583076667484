import { Injectable, inject } from '@angular/core';
import { CandidateProduct } from '@shared/models/response/list-product';
import { CandidateProductFacade } from 'app/store/candidate-product/facades/candidate-product.facade';
import { PicklistType } from 'app/v2/general/domain/enums/picklist-type.enum';
import { Picklist } from 'app/v2/general/domain/types/states/picklist.state';
import { combineLatest, map, Observable } from 'rxjs';

import { PicklistStore } from '../../../store/picklist.store';
import { PicklistViewModel } from '../../../view-model/picklist-view-model';
import { PicklistDto } from '../../dto/picklist.dto';

/**
 * PicklistViewModel生成クラス
 */
@Injectable({
  providedIn: 'root',
})
export class GetInteriorPicklistViewModelQuery {
  private picklistStore = inject(PicklistStore);
  private candidateProductFacade = inject(CandidateProductFacade);
  private readonly picklistType = PicklistType.Interior;
  /**
   * picklistStore, candidateProductFacadeから取得した値でPicklistViewModelの配列を生成する
   */
  public getViewModelList(): Observable<PicklistViewModel[]> {
    return combineLatest([
      this.getPicklistsFromStore(),
      this.getListProductsFromStore(),
    ]).pipe(
      map(([picklistsFromStore, listProductsFromStore]) => {
        return picklistsFromStore.map((picklist) => {
          return PicklistDto.build(picklist, listProductsFromStore);
        });
      }),
    );
  }

  /**
   * picklistStoreからPicklistの配列を取得する
   */
  private getPicklistsFromStore(): Observable<Picklist[]> {
    const picklists$ = this.picklistStore.picklists$.pipe(
      map((state) => {
        return state.filter((picklist) => {
          return picklist.type === this.picklistType;
        });
      }),
    );

    return picklists$;
  }

  /**
   * candidateProductFacadeからListProductの配列を取得する
   */
  private getListProductsFromStore(): Observable<CandidateProduct[]> {
    const listProducts$ = this.candidateProductFacade.allCandidateProducts$;

    return listProducts$;
  }
}
