<mat-dialog-content>
  <div class="menu">
    <form
      ls-global-nav-item
      class="keyword-form"
      #form="ngForm"
      (submit)="onKeywordSearch(form)"
    >
      <div class="keyword-form__inner">
        <fa-icon [icon]="['fas', 'search']"></fa-icon>
        <input
          name="q"
          type="text"
          [ngModel]="searchKeyword"
          placeholder="品番・製品名で検索（カテゴリー横断）"
        />
      </div>
    </form>
  </div>
  <div class="header">
    <div *ngIf="type == 'interior'">内部仕上表関連</div>
    <div *ngIf="type == 'exterior'">外部仕上表関連</div>
    <h3>検索カテゴリーを選択してください</h3>
  </div>
  <div
    class="wrapper"
    *ngIf="(user$ | async)?.is_enable_project && !!projectId"
  >
    <mat-checkbox
      color="primary"
      [(ngModel)]="needProjectCondition"
      *ngIf="!isLoadingProjectId"
    >
      <div class="checkbox-wrap">
        <a
          (click)="transitionPage()"
          [routerLink]="['/project', projectId, 'setting']"
          >プロジェクトで設定されている耐火構造などの建物情報</a
        >を検索条件に自動反映
        <button
          mat-icon-button
          [matTooltip]="
            'プロジェクト情報をもとに、あらかじめ\n検索条件が入力された状態で開きます。'
          "
          matTooltipClass="custom-tooltip"
        >
          <mat-icon>help</mat-icon>
        </button>
      </div>
    </mat-checkbox>
    <gn-spinner
      class="loadingProjectId"
      size="small"
      *ngIf="isLoadingProjectId && !!projectId"
    ></gn-spinner>
  </div>

  <div class="categories" *ngIf="!isLoading; else loading">
    <div class="category-container-index">
      <div
        [class.selected]="selectedCategory == category.id"
        class="category-index"
        *ngFor="let category of categories"
      >
        <span class="category-index-selected-mark"></span>
        <a class="category-index-anchor" (click)="scroll('#id-' + category.id)"
          ><p [innerHTML]="insertBreakForParents(category.name)"></p
        ></a>
      </div>
    </div>
    <div class="category-container-select">
      <div
        id="id-{{ category.id }}"
        class="category-container"
        *ngFor="let category of categories"
      >
        <a
          (click)="transitionPage()"
          [routerLink]="getRouterLink(category)"
          [queryParams]="getQueryParams(category.id)"
          class="category"
        >
          <p [innerHTML]="insertBreakForParents(category.name)"></p>
          <span>{{ category.productCount | number }}件</span>
        </a>

        <div class="params">
          <ng-container *ngIf="category.types">
            <a
              (click)="transitionPage()"
              [routerLink]="getRouterLink(category)"
              [queryParams]="
                getQueryParams(category.id, category.types, value.id)
              "
              class="param"
              *ngFor="let value of category.types.values"
            >
              <p class="param-wrap">
                <span
                  *ngFor="let word of insertBreak(value.name)"
                  style="display: inline-block"
                  [innerHTML]="word"
                ></span>
              </p>
            </a>
          </ng-container>
          <ng-container *ngIf="category.subCategories">
            <a
              (click)="transitionPage()"
              [routerLink]="getRouterLink(category, sub.id)"
              [queryParams]="getQueryParams(category.id)"
              class="param"
              *ngFor="let sub of category.subCategories"
            >
              <p class="param-wrap">
                <span
                  *ngFor="let word of insertBreak(sub.name)"
                  style="display: inline-block"
                  [innerHTML]="word"
                ></span>
              </p>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <gn-spinner></gn-spinner>
  </ng-template>
</mat-dialog-content>
