import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, delay, retry } from 'rxjs/operators';

@Injectable()
export class NetworkErrorInterceptor implements HttpInterceptor {
  private readonly OFF_LINE_MESSAGE = [
    'ネットワークに接続できません。',
    '接続を確認の上、ページを再読み込みしてください。',
  ].join('\n');

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!navigator.onLine) {
          alert(this.OFF_LINE_MESSAGE);
          return EMPTY;
        }
        return throwError(() => error);
      }),
      retry({
        count: 2,
        delay: (error: HttpErrorResponse) => {
          if (error.status === 0) return of(error).pipe(delay(1500));
          return throwError(() => error);
        },
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          this.log(error);
        }

        return throwError(() => error);
      }),
    );
  }

  private log(error: Error | HttpErrorResponse): void {
    if (!environment.production) console.error(error);
  }
}
