import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductState } from '@shared/models/response/sub/product-state';
import { FeatureKey } from 'app/store/enums/feature-key.enum';

import { CandidateProductState, candidateProductAdapter } from '../states';

const getCandidateProductState = createFeatureSelector<CandidateProductState>(
  FeatureKey.CandidateProduct,
);

const { selectAll, selectEntities } = candidateProductAdapter.getSelectors();

export const getIsFetching = createSelector(
  getCandidateProductState,
  (state) => state.isFetching,
);

export const getError = createSelector(
  getCandidateProductState,
  (state) => state.error,
);

export const getSelectedId = createSelector(
  getCandidateProductState,
  (state) => state.selectedId,
);

export const getAllCandidateProducts = createSelector(
  getCandidateProductState,
  selectAll,
);

export const getCandidateProducts = createSelector(
  getCandidateProductState,
  (state) =>
    selectAll(state)
      .filter((candidate) => {
        if (candidate.product?.state === ProductState.Discontinued) {
          return false;
        }
        return true;
      })
      .sort((a, b) => (a.created_at > b.created_at ? -1 : 1)),
);

export const getDiscontinuedProducts = createSelector(
  getCandidateProductState,
  (state) =>
    selectAll(state)
      .filter(
        (candidate) => candidate.product?.state === ProductState.Discontinued,
      )
      .sort((a, b) => (a.created_at > b.created_at ? -1 : 1)),
);

export const getCandidateProductEntities = createSelector(
  getCandidateProductState,
  selectEntities,
);

export const getCandidateProduct = createSelector(
  getSelectedId,
  getCandidateProductEntities,
  (id, entities) => (id ? entities[id] ?? null : null),
);

export const getFilter = createSelector(
  getCandidateProductState,
  (state) => state.filter,
);

export const getProcessingIds = createSelector(
  getCandidateProductState,
  (state) => state.processingIds,
);
