<mat-dialog-content>
  <table>
    <tr>
      <th>分類名<span class="required">*</span></th>
      <td>
        <input
          name="name"
          type="text"
          placeholder="「外壁」など分類名を入力"
          [formControl]="nameControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredGroup | async"
            [value]="option.category_name"
            matTooltip="{{
              option.category_id ? standardSetToolTip[option.category_id] : ''
            }}"
            matTooltipPosition="right"
            (onSelectionChange)="autoInput(option, $event)"
          >
            {{
              option.category_id
                ? option.category_name
                : option.category_name.replace('【標準】', '')
            }}
          </mat-option>
        </mat-autocomplete>
      </td>
    </tr>
    <tr>
      <th class="vertical-top">
        <div class="vertical-center">
          <span>項目名<span class="required">*</span></span>
        </div>
      </th>
      <td>
        <ls-exterior-select-new-value
          #exteriorSelectNewValue
          [(ngModel)]="setNames"
          (isTyped)="typedValue = $event"
          placeholder="「南側タイル壁」など項目名を入力"
        ></ls-exterior-select-new-value>
      </td>
    </tr>
    <tr class="hint">
      <th></th>
      <td>
        <span
          >入力後にエンターキーを押すことで、複数の部屋を続けて入力できます。<br />
          また、改行区切りの部屋名をコピー&ペーストすることでも入力ができます。</span
        >
      </td>
    </tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="left">
    <ls-button (click)="cancel()" [border]="true" class="block"
      >キャンセル</ls-button
    >
  </div>
  <div class="right">
    <ls-button
      (click)="onSave()"
      [class.disabled]="!canSubmit"
      theme="primary"
      [border]="true"
      class="block"
      >作成</ls-button
    >
  </div>
</mat-dialog-actions>
