import { JoineryPageInfo } from '@interfaces/joinery-page-info';
import { createAction, props } from '@ngrx/store';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';

export const fetch = createAction(
  '[JoineryPageInfo Page] Fetch',
  props<{ projectId: number; joineryRegionType: ProjectRangeType }>(),
);

export const fetchSuccess = createAction(
  '[JoineryPageInfo API] Fetch Success',
  props<{ joineryPageInfo: JoineryPageInfo }>(),
);

export const fetchFailure = createAction(
  '[JoineryPageInfo API] Fetch Failure',
  props<{ error: unknown }>(),
);
