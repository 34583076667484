import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from '@shared/models/response/project';
import { Observable } from 'rxjs';

import { FavoriteList } from '../models/response/favorite-list';
import { KpiParam } from '../models/response/kpi-param';
import { User } from '../models/response/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(protected http: HttpClient) {}

  me(): Observable<User> {
    return this.http.get<User>('/api/users/me');
  }

  getEnableProjectUser(userId: number): Observable<User> {
    return this.http.get<User>(`/api/users/${userId}/get-enable-project-user`);
  }

  getProjects(userId: number): Observable<{
    projects: Project[];
    templates: Project[];
    samples: Project[];
    standard_sets: Project[];
  }> {
    return this.http.get<{
      projects: Project[];
      templates: Project[];
      samples: Project[];
      standard_sets: Project[];
    }>(`/api/users/${userId}/get-projects`);
  }

  storeFavlList(list: FavoriteList) {
    return this.http.post<any>(`/api/users/fav-lists`, list);
  }

  exisitsMail(mail: string) {
    return this.http.post<any>(`/api/users/exists`, { mail });
  }

  // updateActiveProject(projectId: number) {
  //   return this.http.get<any>(`/api/project/${projectId}/active`);
  // }

  // updateActiveList(picklist: Picklist) {
  //   return this.http.get<any>(
  //     `/api/project/${picklist.project_id}/finish-schedule/picklist/${picklist.id}/active`
  //   );
  // }

  updateKpiParams(userId: number, kpiParams: KpiParam): Observable<KpiParam> {
    return this.http.put<KpiParam>(`/api/users/${userId}/kpi-params`, {
      kpiParams,
    });
  }

  updateShowReleaseNotifications(userId: number, user: User) {
    return this.http.put<User>(
      `/api/users/${userId}/update-confirmed-latest-release-version`,
      user,
    );
  }
}
