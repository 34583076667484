import { inject, Injectable } from '@angular/core';
import { JoineryComponentWithStandard } from '@interfaces/joinery-component';
import { select, Store } from '@ngrx/store';
import { Picklist } from '@shared/models/response/picklist';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';

import { joineryComponentAction } from './actions';
import { joineryComponentSelector } from './selectors';

@Injectable({ providedIn: 'root' })
export class JoineryComponentFacade {
  private readonly store = inject(Store);

  isFetching$ = this.store.pipe(select(joineryComponentSelector.getIsFetching));
  error$ = this.store.pipe(select(joineryComponentSelector.getError));
  components$ = this.store.pipe(select(joineryComponentSelector.getComponents));

  pastePicklist(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    component: JoineryComponentWithStandard,
    picklist: Picklist,
  ) {
    this.store.dispatch(
      joineryComponentAction.pastePicklist({
        projectId,
        region,
        businessGroupId,
        component,
        picklist,
      }),
    );
  }

  pastePicklistMany(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    components: JoineryComponentWithStandard[],
    picklistId: number | null,
  ) {
    this.store.dispatch(
      joineryComponentAction.pastePicklistMany({
        projectId,
        region,
        businessGroupId,
        components,
        picklistId,
      }),
    );
  }

  removePicklist(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    component: JoineryComponentWithStandard,
    picklistId: number,
  ) {
    this.store.dispatch(
      joineryComponentAction.removePicklist({
        projectId,
        region,
        businessGroupId,
        component,
        picklistId,
      }),
    );
  }

  removePicklistMany(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    components: JoineryComponentWithStandard[],
  ) {
    this.store.dispatch(
      joineryComponentAction.removePicklistMany({
        projectId,
        region,
        businessGroupId,
        components,
      }),
    );
  }

  removePicklistManyByEffect(components: JoineryComponentWithStandard[]) {
    this.store.dispatch(
      joineryComponentAction.removePicklistManyByEffect({ components }),
    );
  }

  setsByEffect(components: JoineryComponentWithStandard[]) {
    this.store.dispatch(joineryComponentAction.setsByEffect({ components }));
  }
}
