import { JoineryComponentWithStandard } from '@interfaces/joinery-component';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { joineryComponentAction } from './actions';
import {
  initialJoineryComponentState,
  JoineryComponentAdapter,
} from './joinery-component.state';

export const joineryComponentReducer = createReducer(
  initialJoineryComponentState,
  on(joineryComponentAction.createSuccess, (state, { component }) => {
    return JoineryComponentAdapter.addOne(component, { ...state });
  }),
  on(joineryComponentAction.remove, (state, data) => {
    return JoineryComponentAdapter.removeOne(data.component.id, state);
  }),
  on(joineryComponentAction.removeSuccess, (state) => {
    return {
      ...state,
    };
  }),
  on(joineryComponentAction.removeFailure, (state, { error }) => {
    return { ...state, error };
  }),
  on(joineryComponentAction.setAll, (state, { components }) => {
    return JoineryComponentAdapter.setAll(components, { ...state });
  }),
  on(joineryComponentAction.setsByEffect, (state, { components }) => {
    return JoineryComponentAdapter.upsertMany(components, state);
  }),
  on(joineryComponentAction.pastePicklist, (state, { component }) => {
    return {
      ...state,
      processingIds: [...state.processingIds, component.id],
    };
  }),
  on(joineryComponentAction.pastePicklistSuccess, (state, { component }) => {
    return JoineryComponentAdapter.updateOne(
      { id: component.id, changes: component },
      {
        ...state,
        processingIds: [
          ...state.processingIds.filter((id) => id !== component.id),
        ],
      },
    );
  }),
  on(joineryComponentAction.pastePicklistFailure, (state, { error }) => {
    return { ...state, error };
  }),
  on(joineryComponentAction.pastePicklistMany, (state, { components }) => {
    return {
      ...state,
      processingIds: [...state.processingIds, ...components.map((c) => c.id)],
    };
  }),
  on(
    joineryComponentAction.pastePicklistManySuccess,
    (state, { components }) => {
      const changes: Update<JoineryComponentWithStandard>[] = components.map(
        (c) => {
          return {
            id: c.id,
            changes: c,
          } as Update<JoineryComponentWithStandard>;
        },
      );
      const targetIds = components.map((c) => c.id);
      return JoineryComponentAdapter.updateMany(changes, {
        ...state,
        processingIds: state.processingIds.filter((id) =>
          targetIds.includes(id),
        ),
      });
    },
  ),
  on(joineryComponentAction.pastePicklistFailure, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(joineryComponentAction.removePicklist, (state, { component }) => {
    return {
      ...state,
      processingIds: [...state.processingIds, component.id],
    };
  }),
  on(joineryComponentAction.removePicklistSuccess, (state, { component }) => {
    return JoineryComponentAdapter.updateOne(
      { id: component.id, changes: component },
      {
        ...state,
        processingIds: [
          ...state.processingIds.filter((id) => id !== component.id),
        ],
      },
    );
  }),
  on(joineryComponentAction.removePicklistFailure, (state, { error }) => {
    return { ...state, error };
  }),
  on(joineryComponentAction.removePicklistMany, (state, { components }) => {
    return {
      ...state,
      processingIds: [...state.processingIds, ...components.map((c) => c.id)],
    };
  }),
  on(
    joineryComponentAction.removePicklistManySuccess,
    (state, { components }) => {
      const removeTargetIds = components.map((c) => c.id);
      const changes: Update<JoineryComponentWithStandard>[] = components.map(
        (c) => ({
          id: c.id,
          changes: c,
        }),
      );
      return JoineryComponentAdapter.updateMany(changes, {
        ...state,
        processingIds: state.processingIds.filter(
          (id) => !removeTargetIds.includes(id),
        ),
      });
    },
  ),
  on(joineryComponentAction.removePicklistManyFailure, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(
    joineryComponentAction.removePicklistManyByEffect,
    (state, { components }) => {
      const changes: Update<JoineryComponentWithStandard>[] = components.map(
        (c) => ({
          id: c.id,
          changes: { picklist_id: null },
        }),
      );
      return JoineryComponentAdapter.updateMany(changes, state);
    },
  ),
  on(joineryComponentAction.setPicklist, (state, { component, picklistId }) => {
    return JoineryComponentAdapter.updateOne(
      { id: component.id, changes: { ...component, picklist_id: picklistId } },
      {
        ...state,
      },
    );
  }),
);
