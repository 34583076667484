import { Injectable } from '@angular/core';
import { Joinery } from '@interfaces/joinery';
import { JoineryComponent } from '@interfaces/joinery-component';
import { RoomToRoom } from '@interfaces/room-to-room';
import { SymbolSpecification } from '@interfaces/symbol-specification';
import { select, Store } from '@ngrx/store';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { JoinerySearchParam } from 'app/services/api/joinery-page-api.service';

import { JoineryAction } from './actions';
import { joinerySelector } from './selectors';

@Injectable({ providedIn: 'root' })
export class JoineryFacade {
  isFetching$ = this.store.pipe(select(joinerySelector.getIsFetching));
  error$ = this.store.pipe(select(joinerySelector.getError));
  joineries$ = this.store.pipe(select(joinerySelector.getJoineries));
  meta$ = this.store.pipe(select(joinerySelector.getPageMeta));
  links$ = this.store.pipe(select(joinerySelector.getPageLinks));
  constructor(private readonly store: Store) {}

  fetch(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    page?: number,
    searchParam?: JoinerySearchParam,
  ) {
    this.store.dispatch(
      JoineryAction.fetch({
        projectId,
        region,
        businessGroupId,
        page,
        searchParam,
      }),
    );
  }

  update(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joinery: Joinery,
  ): void {
    this.store.dispatch(
      JoineryAction.update({ projectId, region, businessGroupId, joinery }),
    );
  }

  incrementMeta(): void {
    this.store.dispatch(JoineryAction.incrementMeta());
  }

  decrementMeta(): void {
    this.store.dispatch(JoineryAction.decrementMeta());
  }

  incrementMetaMany(incrementCount: number): void {
    this.store.dispatch(JoineryAction.incrementMetaMany({ incrementCount }));
  }

  updateSpec(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joinery: Joinery,
    spec: SymbolSpecification,
  ): void {
    this.store.dispatch(
      JoineryAction.updateSpec({
        projectId,
        region,
        businessGroupId,
        joinery,
        symbolSpecification: spec,
      }),
    );
  }

  updateSpecMany(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineries: Joinery[],
    spec: SymbolSpecification,
  ): void {
    this.store.dispatch(
      JoineryAction.updateSpecMany({
        projectId,
        region,
        businessGroupId,
        joineries,
        symbolSpecification: spec,
      }),
    );
  }

  duplicateAndEditRooms(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joinery: Joinery,
    targetRooms: RoomToRoom[],
    isDeleteCopySource: boolean,
  ): void {
    this.store.dispatch(
      JoineryAction.duplicateAndEditRooms({
        projectId,
        region,
        businessGroupId,
        joinery,
        targetRooms,
        isDeleteCopySource,
      }),
    );
  }

  duplicateMultiWindowAndEditRooms(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineries: Joinery[],
    components: JoineryComponent[],
    targetRooms: RoomToRoom[],
  ): void {
    this.store.dispatch(
      JoineryAction.duplicateMultiWindowAndEditRooms({
        projectId,
        region,
        businessGroupId,
        joineries,
        components,
        targetRooms,
      }),
    );
  }

  remove(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineryId: number,
  ): void {
    this.store.dispatch(
      JoineryAction.remove({ projectId, region, businessGroupId, joineryId }),
    );
  }

  copySpecMany(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    copySourceJoinery: Joinery,
    copySourceConfigId: number,
    pasteTargetJoineries: Joinery[],
  ): void {
    this.store.dispatch(
      JoineryAction.copySpecMany({
        projectId,
        region,
        businessGroupId,
        copySourceJoinery,
        copySourceConfigId,
        pasteTargetJoineries,
      }),
    );
  }

  addMany(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineries: Omit<Joinery, 'id'>[],
  ): void {
    this.store.dispatch(
      JoineryAction.addMany({
        projectId,
        region,
        businessGroupId,
        joineries,
      }),
    );
  }
}
