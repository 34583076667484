import { JoineryOptionSetting } from '@interfaces/joinery-config';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
export interface JoineryOptionSettingState
  extends EntityState<JoineryOptionSetting> {
  error?: unknown;
  isFetching: boolean;
  processingIds: number[];
}

export const JoineryOptionSettingAdapter =
  createEntityAdapter<JoineryOptionSetting>();

export const initialJoineryOptionSettingState: JoineryOptionSettingState =
  JoineryOptionSettingAdapter.getInitialState({
    isFetching: false,
    processingIds: [],
  });
