import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserTenant } from '../_type/tenant';

@Injectable({
  providedIn: 'root',
})
export class UserTenantStore {
  private readonly userTenantSubject$ = new BehaviorSubject<
    UserTenant[] | undefined
  >(undefined);

  get(): Observable<UserTenant[] | undefined> {
    return this.userTenantSubject$.asObservable();
  }

  set(userTenant: UserTenant[]): void {
    this.userTenantSubject$.next(userTenant);
  }

  update(userTenant: UserTenant): void {
    const userTenants = this.userTenantSubject$.getValue();
    const newState = userTenants?.map((tenant) => {
      if (tenant.id === userTenant.id) tenant = userTenant;
      return tenant;
    });

    this.userTenantSubject$.next(newState);
  }

  delete(): void {
    this.userTenantSubject$.next(undefined);
  }
}
