import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { AlertDialogComponent } from './alert-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AlertDialogService extends MatDialog {
  openDialog(
    message: string,
    okLabel?: string,
    cancelLabel = 'キャンセル',
    okTheme: 'default' | 'primary' | 'accent' | 'warn' | 'pink' = 'warn',
    isSingleButton?: boolean,
  ): MatDialogRef<AlertDialogComponent, boolean> {
    return this.open(AlertDialogComponent, {
      data: {
        message,
        okLabel: okLabel || 'OK',
        cancelLabel,
        okTheme,
        isSingleButton: isSingleButton || false,
      },
    });
  }
}
