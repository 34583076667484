import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'ls-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class AlertDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<AlertDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: {
      message: string;
      okLabel: string;
      cancelLabel: string;
      okTheme: 'default' | 'primary' | 'accent' | 'warn' | 'pink';
      isSingleButton: boolean;
    },
  ) {}

  cancel() {
    this.dialogRef.close(false);
  }

  okClick() {
    this.dialogRef.close(true);
  }
}
