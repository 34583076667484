import { RoomToRoom } from '@interfaces/room-to-room';
import { RoomToRoomSpecification } from '@interfaces/room-to-room-specification';
import { createAction, props } from '@ngrx/store';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';

const pageActionPrefix = '[RoomToRoom Page]';
const apiActionPrefix = '[RoomToRoom API]';

export const fetchAll = createAction(
  pageActionPrefix + 'Fetch All',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
  }>(),
);

export const fetchAllSuccess = createAction(
  apiActionPrefix + 'Fetch All Success',
  props<{ roomToRooms: RoomToRoom[] }>(),
);

export const fetchAllFailure = createAction(
  apiActionPrefix + 'Fetch All Failure',
  props<{ error: unknown }>(),
);

export const updateMany = createAction(
  pageActionPrefix + 'Update Many',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    roomToRooms: RoomToRoom[];
  }>(),
);

export const updateManySuccess = createAction(
  apiActionPrefix + 'Update Many Success',
  props<{ roomToRooms: RoomToRoom[] }>(),
);

export const updateManyFailure = createAction(
  apiActionPrefix + 'Update Many Failure',
  props<{ error: unknown }>(),
);

export const updateSpecs = createAction(
  pageActionPrefix + 'UpdateSpecs',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    roomToRooms: RoomToRoom[];
    roomToRoomSpecs: RoomToRoomSpecification[];
  }>(),
);

export const updateSpecsSuccess = createAction(
  apiActionPrefix + 'UpdateSpecs Success',
  props<{
    roomToRooms: RoomToRoom[];
    roomToRoomSpecs: RoomToRoomSpecification[];
  }>(),
);

export const updateSpecsFailure = createAction(
  apiActionPrefix + 'UpdateSpecs Failure',
  props<{ error: unknown }>(),
);
