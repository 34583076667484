import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { FeatureKey } from '../enums/feature-key.enum';

import { JoineryEffects } from './joinery.effect';
import { joineryReducer } from './joinery.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(FeatureKey.Joinery, joineryReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
      connectInZone: true,
    }),
    EffectsModule.forFeature([JoineryEffects]),
  ],
})
export class JoineryStoreModule {}
