import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JoineryPageApiService } from 'app/services/api/joinery-page-api.service';
import { catchError, map, of, switchMap } from 'rxjs';

import { JoineryPageInfoAction } from './actions';

@Injectable()
export class JoineryPageInfoEffects {
  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoineryPageInfoAction.fetch),
      switchMap(({ projectId, joineryRegionType }) =>
        this.joineryPageApi
          .fetchJoineryPageInfo(projectId, joineryRegionType)
          .pipe(
            map((result) =>
              JoineryPageInfoAction.fetchSuccess({ joineryPageInfo: result }),
            ),
            catchError((error) =>
              of(JoineryPageInfoAction.fetchFailure({ error })),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private joineryPageApi: JoineryPageApiService,
  ) {}
}
