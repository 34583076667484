import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKey } from 'app/store/enums/feature-key.enum';

import { JoineryAdapter, JoineryState } from '../joinery.state';

const getJoineryState = createFeatureSelector<JoineryState>(FeatureKey.Joinery);

const { selectAll } = JoineryAdapter.getSelectors();

export const getIsFetching = createSelector(
  getJoineryState,
  (state) => state.isFetching,
);

export const getError = createSelector(getJoineryState, (state) => state.error);

export const getJoineries = createSelector(getJoineryState, (state) =>
  selectAll(state),
);

export const getPageMeta = createSelector(
  getJoineryState,
  (state) => state.meta,
);

export const getPageLinks = createSelector(
  getJoineryState,
  (state) => state.links,
);
