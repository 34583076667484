import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoomToRoom } from '@interfaces/room-to-room';
import { RoomToRoomSpecification } from '@interfaces/room-to-room-specification';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoomToRoomApiService {
  constructor(private http: HttpClient) {}
  fetchAll(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
  ): Observable<RoomToRoom[]> {
    return this.http.get<RoomToRoom[]>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/room-to-rooms`,
      {
        params: new HttpParams().set('embeds', 'room_to_room_specifications'),
      },
    );
  }

  // 使用場所の編集
  updateMany(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    roomToRooms: RoomToRoom[],
  ): Observable<void> {
    const roomToRoomIds = roomToRooms.map((room) => room.id).toString();

    return this.http.put<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/room-to-rooms/${roomToRoomIds}/bulk`,
      { room_to_rooms: roomToRooms },
    );
  }

  updateSpecifications(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    roomToRoomSpecs: RoomToRoomSpecification[],
  ): Observable<void> {
    const roomToRoomSpecIds = roomToRoomSpecs.map((room) => room.id).toString();

    return this.http.put<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/room-to-rooms/specifications/${roomToRoomSpecIds}/bulk`,
      { joinery_room_to_room_specifications: roomToRoomSpecs },
    );
  }
}
