import { FloorGroup } from 'app/v2/general/domain/types/states/floor-group.state';

import {
  FloorGroupViewModel,
  MiscellaneousWorkFloorGroupViewModel,
} from '../../view-model/floor-group-view-model';
import { MiscellaneousWorkRoomGroupViewModel } from '../../view-model/miscellaneous-work-room-group-view-model';
import { RoomGroupViewModel } from '../../view-model/room-group-view-model';

export class FloorGroupDto {
  public static build(
    floorGroupsFromStore: FloorGroup[],
    roomGroupViewModel: RoomGroupViewModel[],
  ): FloorGroupViewModel[] {
    const floorGroups: FloorGroupViewModel[] = floorGroupsFromStore
      .sort((a, b) => a.finish_schedule_order - b.finish_schedule_order)
      .map((floorGroup) => {
        const roomGroupsViewModel = roomGroupViewModel
          .filter((roomGroup) => {
            return roomGroup.floor_group_id === floorGroup.id;
          })
          .sort((a, b) => a.finish_schedule_order - b.finish_schedule_order);

        const floorGroupViewModel: FloorGroupViewModel = {
          ...floorGroup,
          room_groups: roomGroupsViewModel,
        };

        return floorGroupViewModel;
      });
    return floorGroups;
  }

  // 上のbuild関数もジェネリック型にして使い回せるようにしようとしたが、出来なかった。
  // 妥協案として同じ処理の関数を作成して、型だけ変更してます。
  public static transformMiscellaneousWorks(
    floorGroupsFromStore: FloorGroup[],
    roomGroupViewModel: MiscellaneousWorkRoomGroupViewModel[],
  ): MiscellaneousWorkFloorGroupViewModel[] {
    const floorGroups: MiscellaneousWorkFloorGroupViewModel[] =
      floorGroupsFromStore
        .sort((a, b) => a.finish_schedule_order - b.finish_schedule_order)
        .map((floorGroup) => {
          const roomGroupsViewModel = roomGroupViewModel
            .filter((roomGroup) => {
              return roomGroup.floor_group_id === floorGroup.id;
            })
            .sort((a, b) => a.finish_schedule_order - b.finish_schedule_order);

          const floorGroupViewModel: MiscellaneousWorkFloorGroupViewModel = {
            ...floorGroup,
            room_groups: roomGroupsViewModel,
          };

          return floorGroupViewModel;
        });
    return floorGroups;
  }
}
