<mat-dialog-content>
  <p>
    部屋情報が一致しません。<br />
    どの部屋情報で仕上情報を上書きするか選択し「紐付ける」ボタンをクリックすると、該当の部屋情報で仕上情報を上書きした上で仕上に部屋を紐付けます。<br />
    「キャンセル」ボタンをクリックすると、仕上情報は上書きせず部屋の紐付けをキャンセルします。
  </p>
  <mat-radio-group name="selected" [(ngModel)]="selected">
    <div class="scroll-container">
      <table>
        <thead>
          <tr>
            <th rowspan="3"></th>
            <th rowspan="3">部屋名</th>
            <th rowspan="3">排煙</th>
            <th rowspan="3">内装制限</th>
            <th rowspan="3">内装制限の<br />制限根拠</th>
            <th rowspan="3">SL</th>
            <th rowspan="3">FL</th>
            <th rowspan="3">巾木<br />H</th>
            <th rowspan="3">腰壁<br />H</th>
            <th rowspan="3">CH</th>
            <th colspan="10">施工区分</th>
          </tr>
          <tr>
            <th colspan="2">床</th>
            <th rowspan="2">巾木</th>
            <th colspan="2">腰壁</th>
            <th colspan="2">壁</th>
            <th rowspan="2">見切縁</th>
            <th colspan="2">天井</th>
          </tr>
          <tr>
            <th>下地</th>
            <th>仕上</th>
            <th>下地</th>
            <th>仕上</th>
            <th>下地</th>
            <th>仕上</th>
            <th>下地</th>
            <th>仕上</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let room of rooms">
            <td>
              <mat-radio-button
                color="primary"
                [value]="room"
              ></mat-radio-button>
            </td>
            <td>{{ room.name }}</td>
            <td>{{ room.smoke_exhausting_system }}</td>
            <td>{{ room.interior_restriction }}</td>
            <td>{{ room.restriction_grounds }}</td>
            <td>{{ room.sl }}</td>
            <td>{{ room.fl }}</td>
            <td>{{ room.baseboard_height }}</td>
            <td>{{ room.spandrel_wall_height }}</td>
            <td>{{ room.ch }}</td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_floor_ground
                  : ''
              }}
            </td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_floor_finish
                  : ''
              }}
            </td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_baseboard
                  : ''
              }}
            </td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_spandrel_wall_ground
                  : ''
              }}
            </td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_spandrel_wall_finish
                  : ''
              }}
            </td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_wall_ground
                  : ''
              }}
            </td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_wall_finish
                  : ''
              }}
            </td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_parting_edge
                  : ''
              }}
            </td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_ceiling_ground
                  : ''
              }}
            </td>
            <td>
              {{
                room.construction_classifications
                  ? room.construction_classifications
                      .construction_classification_ceiling_finish
                  : ''
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-radio-group>
</mat-dialog-content>

<mat-dialog-actions>
  <a ls-button (click)="cancel()" [border]="true">キャンセル</a>
  <a ls-button (click)="okClick()" theme="primary" [border]="true">紐付ける</a>
</mat-dialog-actions>
