import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Banner } from '../models/response/banner';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(protected http: HttpClient) {}

  random(count = 3): Observable<Banner> {
    const params = { count: count + '' };
    return this.http.get<Banner>(`/api/banners/random`, { params });
  }

  show(id: string): Observable<Banner> {
    return this.http.get<Banner>(`/api/banners/${id}`);
  }
}
