import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListProduct } from '@shared/models/response/list-product';
import { Picklist } from '@shared/models/response/picklist';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment as env } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  type: string;

  constructor(private http: HttpClient) {}

  checkIfExists(src: string, contentType: string): Observable<boolean> {
    return this.http.get(src, { responseType: 'blob' }).pipe(
      catchError((_) => of(false)),
      map<Blob | false, boolean>((blob) => {
        return !!blob && !!blob.type.match(new RegExp(contentType));
      }),
    );
  }

  storage(fileName: string, directories: string[] = []) {
    const path = env.storagePath;

    return `${path}${
      directories ? directories.join('/') + '/' : ''
    }${fileName}`;
  }

  uploadImageForListProduct(
    projectId: number,
    picklist: Picklist,
    listProduct: ListProduct,
  ) {
    const formData = new FormData();

    if (listProduct.uploadedThumbnail) {
      formData.append(
        'thumbnail-file',
        listProduct.uploadedThumbnail,
        listProduct.uploadedThumbnail.name,
      );
    }
    if (listProduct.uploadedTexture) {
      formData.append(
        'texture-file',
        listProduct.uploadedTexture,
        listProduct.uploadedTexture.name,
      );
    }

    return this.http.post<{ thumbnail?: string; texture?: string }>(
      `/api/project/${projectId}/finish-schedule/picklist/${picklist.id}/upload-image`,
      formData,
    );
  }
}
