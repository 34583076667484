import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { ImagePreviewType } from '../../domain/enums/image-preview-type.enum';
import { PicklistType } from '../../domain/enums/picklist-type.enum';
import { ThumbnailType } from '../../domain/enums/thumbnail-type.enum';
import { Picklist } from '../../domain/types/states/picklist.state';

type MiscellaneousWorkPicklistsState = {
  miscellaneousWorkPicklists: MiscellaneousWorkPicklist[];
};

export type MiscellaneousWorkPicklist = Picklist & {
  specCode: string;
  standardDimension: string;
};

@Injectable({ providedIn: 'root' })
export class MiscellaneousWorkPicklistStore extends ComponentStore<MiscellaneousWorkPicklistsState> {
  constructor() {
    super({ miscellaneousWorkPicklists: [] });
  }

  readonly miscellaneousWorkPicklists$: Observable<
    MiscellaneousWorkPicklist[]
  > = this.select((state) => {
    return state.miscellaneousWorkPicklists;
  });

  readonly addPicklist = this.updater(
    (
      state: MiscellaneousWorkPicklistsState,
      newPicklist: MiscellaneousWorkPicklist,
    ) => {
      return {
        miscellaneousWorkPicklists: [
          ...state.miscellaneousWorkPicklists,
          newPicklist,
        ],
      };
    },
  );

  readonly updatePicklist = this.updater(
    (state, newPicklist: MiscellaneousWorkPicklist) => {
      const updatedItems = state.miscellaneousWorkPicklists.map((item) => {
        if (item.id === newPicklist.id) {
          return newPicklist;
        }
        return item;
      });

      return { ...state, miscellaneousWorkPicklists: updatedItems };
    },
  );

  readonly deletePicklist = this.updater((state, deletePicklistId: number) => {
    const newState = state.miscellaneousWorkPicklists.filter(
      (picklist) =>
        deletePicklistId !== picklist.id &&
        deletePicklistId !== picklist.group_id!!,
    );

    return { miscellaneousWorkPicklists: newState } as const;
  });
}

export const InitialMiscellaneousWorkPicklistStateType: MiscellaneousWorkPicklist =
  {
    id: 0,
    revit_id: null,
    project_id: 0,
    type: PicklistType.MiscellaneousWork,
    is_standard: false,
    is_parent: false,
    name: '',
    group_id: null,
    specification: '',
    assign_type: '',
    enable_similar: 0,
    similar_memo: '',
    enable_multiple_paste: false,
    thickness: null,
    remarks: '',
    request_memo: '',
    updated_request_memo_at: null,
    updated_request_memo_user_name: null,
    updated_request_memo_user_id: null,
    memo: '',
    product_image_preview_type: ImagePreviewType.Cover,
    product_use_image: ThumbnailType.Product,
    order: null,
    mark: '',
    keynote: '',
    surface_pattern_id: null,
    list_link_id: null,
    bim_color: '',
    is_multiple_paste: false,
    meta: undefined,
    joinery_standard_component_id: 0,
    joinery_picklist_specifications: [],
    joinery_component_ids: [],
    is_structural_material: false,
    structural_code: null,
    created_at: '',
    updated_at: '',
    specCode: '',
    standardDimension: '',
  };
