import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, takeUntil } from 'rxjs';

import { PicklistType } from '../../domain/enums/picklist-type.enum';
import {
  Picklist,
  PicklistState,
} from '../../domain/types/states/picklist.state';

@Injectable({
  providedIn: 'root',
})
export class PicklistStore extends ComponentStore<PicklistState> {
  constructor() {
    super({
      picklists: [],
      highlightPicklistId: undefined,
    });
  }

  readonly picklists$: Observable<Picklist[]> = this.select(
    (state) => {
      return state.picklists;
    },
    { debounce: true },
  ).pipe(takeUntil(this.destroy$));

  readonly highlightPicklistId$: Observable<number | undefined> = this.select(
    (state) => {
      return state.highlightPicklistId;
    },
  ).pipe(takeUntil(this.destroy$));

  public findPicklistById(
    picklistId: number,
  ): Observable<Picklist | undefined> {
    return this.select((state) => {
      return state.picklists.find((p) => p.id === picklistId);
    });
  }

  public getPicklistByName(
    picklistName: string,
    type: PicklistType,
  ): Observable<Picklist[]> {
    return this.select((state) => {
      return state.picklists.filter(
        (p) => p.name === picklistName && p.type === type,
      );
    });
  }

  readonly addPicklist = this.updater(
    (state: PicklistState, value: Picklist) => {
      return {
        picklists: [...state.picklists, value],
      };
    },
  );

  addManyPicklists = this.updater(
    (state: PicklistState, values: Picklist[]) => {
      return {
        picklists: [...state.picklists, ...values],
      };
    },
  );

  readonly updateOne = this.updater((state, newItem: Picklist) => {
    const updatedItems = state.picklists.map((item) => {
      if (item.id === newItem.id) {
        return newItem;
      }
      return item;
    });

    return { ...state, picklists: updatedItems };
  });

  readonly updateMany = this.updater((state, newItems: Picklist[]) => {
    const updatedItems = state.picklists.map((item) => {
      const matchingItem = newItems.find((newItem) => newItem.id === item.id);
      if (matchingItem) {
        return { ...item, ...matchingItem };
      }
      return item;
    });

    return { ...state, picklists: updatedItems };
  });

  readonly deletePicklist = this.updater((state, deletePicklistId: number) => {
    const newState = state.picklists.filter(
      (picklist) =>
        deletePicklistId !== picklist.id &&
        deletePicklistId !== picklist.group_id!!,
    );

    return { picklists: newState } as const;
  });

  setHighlightPicklist(id?: number) {
    this.patchState({ highlightPicklistId: id ? id : undefined });
  }
}
