import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Joinery } from '@interfaces/joinery';
import { JoineryComponent } from '@interfaces/joinery-component';
import { SymbolSpecification } from '@interfaces/symbol-specification';
import { SymbolWithNumber } from '@interfaces/symbol-with-number';
import { MessageDialogComponent } from '@shared/dialog/message-dialog/message-dialog.component';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { JoineryFacade } from 'app/store/joinery/joinery.facade';
import { PicklistStoreFacade } from 'app/store/picklist/picklist-store.facade';
import { SymbolWithNumberFacade } from 'app/store/symbol-with-number/symbol-with-number.facade';
import { firstValueFrom, map, Observable } from 'rxjs';

import { JoineryApiService } from './api/joinery-api.service';

@Injectable({
  providedIn: 'root',
})
export class JoineryService {
  constructor(
    private joineryApi: JoineryApiService,
    private joineryFacade: JoineryFacade,
    private picklistFacade: PicklistStoreFacade,
    private symbolWithNumberFacade: SymbolWithNumberFacade,
    private dialog: MatDialog,
  ) {}

  async applyStandardJoinery(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joinery: Joinery,
    page?: number,
  ): Promise<void> {
    const result = await firstValueFrom(
      this.joineryApi.applyStandardJoinery(
        projectId,
        region,
        businessGroupId,
        joinery,
      ),
    );
    if (!result) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          message: `反映されませんでした。<br /><br />建具の種類・防火性能が入力されていないか、<br />対応する建具の用意がない可能性があります。`,
        },
      });
    } else {
      this.joineryFacade.fetch(projectId, region, businessGroupId, page);
      this.picklistFacade.sets(result.picklists);
    }
  }

  async isDuplicateSign(joinery: Joinery): Promise<boolean> {
    const target = (joinery.symbol ?? '') + (joinery.number ?? '');
    const symbolWithNumbers = await firstValueFrom(
      this.symbolWithNumberFacade.symbolWithNumbers$,
    );
    return symbolWithNumbers
      .filter((value) => value.id !== joinery.id)
      .map((value) => value.symbol_with_number)
      .includes(target);
  }

  async duplicate(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joinery: Joinery,
  ): Promise<{
    joinery: Joinery;
    components: JoineryComponent[];
  }> {
    const nextItem = await this.findNextOrderItem(joinery);
    const newOrder = nextItem
      ? joinery.order + (nextItem.order - joinery.order) / 2
      : joinery.order + 1;
    const newNumber = joinery.number ? joinery.number + 'のコピー' : null;
    const newJoinery: Joinery = {
      ...joinery,
      number: newNumber,
      order: newOrder,
    };
    const res = await firstValueFrom(
      this.joineryApi.addJoinery(
        projectId,
        region,
        businessGroupId,
        newJoinery,
        true,
      ),
    );
    return res;
  }

  async findNextOrderItem(target: Joinery): Promise<SymbolWithNumber> {
    const symbolWithNumbers = await firstValueFrom(
      this.symbolWithNumberFacade.symbolWithNumbers$,
    );
    const sorted = symbolWithNumbers.sort((a, b) => a.order - b.order);
    const targetIndex = sorted.findIndex((v) => v.id === target.id);
    const nextItem = sorted[targetIndex + 1];
    return nextItem;
  }

  pasteSpecs(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    copySourceJoinery: Joinery,
    copySourceSpecConfigId: number,
    pasteTargetJoineries: Joinery[],
  ): Observable<Joinery[]> {
    const copySourceSpec = copySourceJoinery.symbol_specifications.find(
      (spec) => spec.joinery_config_id === copySourceSpecConfigId,
    );
    const pastedJoineries = pasteTargetJoineries.map((joinery) => {
      const pastedSpecs = joinery.symbol_specifications.map((spec) => {
        if (
          copySourceSpec &&
          spec.joinery_config_id === copySourceSpec.joinery_config_id
        ) {
          spec = { ...spec, value: copySourceSpec.value };
        }
        return spec;
      });
      return { ...joinery, symbol_specifications: pastedSpecs };
    });

    const pastedSpecs: SymbolSpecification[] = pastedJoineries
      .flatMap((joinery) => joinery.symbol_specifications)
      .filter((spec) => spec.joinery_config_id === copySourceSpecConfigId);

    return this.joineryApi
      .updateSymbolSpecifications(
        projectId,
        region,
        businessGroupId,
        pastedSpecs,
      )
      .pipe(map(() => pastedJoineries));
  }

  updateSpecMany(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    joineries: Joinery[],
    symbolSpec: SymbolSpecification,
  ): Observable<Joinery[]> {
    const updatedJoineries = joineries.map((joinery) => {
      const updatedSpecs = joinery.symbol_specifications.map((spec) => {
        if (
          spec.joinery_config_id === symbolSpec.joinery_config_id &&
          spec.joinery_column_setting_id ===
            symbolSpec.joinery_column_setting_id
        ) {
          spec = { ...spec, value: symbolSpec.value };
        }
        return spec;
      });
      return { ...joinery, symbol_specifications: updatedSpecs };
    });

    const updatedSpecs: SymbolSpecification[] = updatedJoineries
      .flatMap((joinery) => joinery.symbol_specifications)
      .filter((spec) => {
        return (
          spec.joinery_config_id === symbolSpec.joinery_config_id &&
          spec.joinery_column_setting_id ===
            symbolSpec.joinery_column_setting_id
        );
      });

    return this.joineryApi
      .updateSymbolSpecifications(
        projectId,
        region,
        businessGroupId,
        updatedSpecs,
      )
      .pipe(map(() => updatedJoineries));
  }

  async addMany(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    addJoineriesValue: {
      symbol: string;
      count: string;
    },
  ): Promise<void> {
    const initJoinery = this.constructInitJoinery(projectId, region);
    const symbol = addJoineriesValue.symbol;
    const count = parseInt(addJoineriesValue.count);
    const newJoineries = await this.generateNewJoineries(
      symbol,
      count,
      initJoinery,
    );

    this.joineryFacade.addMany(
      projectId,
      region,
      businessGroupId,
      newJoineries,
    );
  }

  private async generateNewJoineries(
    symbol: string,
    count: number,
    initJoinery: Omit<Joinery, 'id' | 'order'>,
  ): Promise<Joinery[]> {
    const symbolWithNumbers = await firstValueFrom(
      this.symbolWithNumberFacade.symbolWithNumbers$.pipe(
        map((symbolWithNumber) => {
          const filteredSymbolWithNumber = symbolWithNumber.filter(
            (symbolWithNumber) => {
              return (
                symbolWithNumber.symbol === symbol &&
                symbolWithNumber.number !== null
              );
            },
          );

          return filteredSymbolWithNumber;
        }),
      ),
    );

    const newJoineries: Joinery[] = [];
    let incrementNumber = 0;
    let incrementOrder = 0;
    if (symbolWithNumbers.length > 0) {
      incrementNumber = symbolWithNumbers.reduce(
        (max, obj) => Math.max(max, Number(obj.number)),
        -Infinity,
      );
      incrementOrder = symbolWithNumbers.reduce(
        (max, obj) => Math.max(max, obj.order),
        -Infinity,
      );
    }

    for (let i: number = 1; i <= count; i++) {
      const number = incrementNumber + i;
      const order = incrementOrder + i;
      initJoinery.number = String(number);
      initJoinery.symbol = symbol;

      // 一時的に型キャスト、後でidは送らなくて良いように修正すべき
      const newJoinery = {
        ...(initJoinery as Omit<Joinery, 'id'>),
        order: order,
      } as Joinery;
      newJoineries.push(newJoinery);
    }

    return newJoineries;
  }

  private constructInitJoinery(
    projectId: number,
    region: ProjectRangeType,
  ): Omit<Joinery, 'id' | 'order'> {
    const initJoinery: Omit<Joinery, 'id' | 'order'> = {
      project_id: projectId,
      region: region,
      standard_joinery_id: null,
      symbol: null,
      number: null,
      symbol_specifications: [],
      multi_step_window_group_id: null,
      multi_set_window_ids: [],
    };

    return initJoinery;
  }
}
