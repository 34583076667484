import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

import { List } from '../models/list';
import { Banner } from '../models/response/banner';
import { Category } from '../models/response/category';
import { Count } from '../models/response/count';
import { Feature } from '../models/response/feature';
import { GalleryItem } from '../models/response/gallery-item';
import { Maker } from '../models/response/maker';
import { Pagination } from '../models/response/pagination';
import { Product } from '../models/response/product';
import { SearchParamsOptionSet } from '../models/response/search-params-option-set';
import { Series } from '../models/response/series';
import { UseCase } from '../models/response/use-case';
import { setFavoriteState } from '../service/favorite-state.service';

import { RequestParamsConverterService } from './request-params-converter.service';

@Injectable({
  providedIn: 'root',
})
export class MakerService {
  constructor(
    protected http: HttpClient,
    protected converter: RequestParamsConverterService,
  ) {}

  index(page = 1): Observable<Pagination<Maker>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<Maker>>('/api/makers', { params });
  }

  indexWithOutPagenation(params?: {
    has_sampleable_product: boolean;
  }): Observable<Maker[]> {
    return this.http.get<Maker[]>('/api/makers', {
      params: this.converter.convert({ skip_paginate: true, ...params }),
    });
  }

  show(id: string): Observable<Maker> {
    return this.http.get<Maker>(`/api/makers/${id}`);
  }

  useCases(id: string, page = 1): Observable<Pagination<UseCase>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<UseCase>>(`/api/makers/${id}/use-cases`, {
      params,
    });
  }

  useCasesTotal(id: string): Observable<Count> {
    return this.http.get<Count>(`/api/makers/${id}/use-cases/total`);
  }

  features(id: string, page = 1): Observable<Pagination<Feature>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<Feature>>(`/api/makers/${id}/features`, {
      params,
    });
  }

  featuresTotal(id: string): Observable<Count> {
    return this.http.get<Count>(`/api/makers/${id}/features/total`);
  }

  series(
    id: string,
    page = 1,
    categoryId: string = '',
  ): Observable<Pagination<Series>> {
    const params = { page: page, category_id: categoryId };
    return this.http.get<Pagination<Series>>(`/api/makers/${id}/series`, {
      params,
    });
  }

  categories(id: string): Observable<Category[]> {
    return this.http.get<Category[]>(`/api/makers/${id}/categories`);
  }

  banners(id: string): Observable<Banner[]> {
    return this.http.get<Banner[]>(`/api/makers/${id}/banners`);
  }

  products(id: string, page = 1): Observable<Pagination<Product>> {
    const params = { page: page + '' };
    return this.http
      .get<Pagination<Product>>(`/api/makers/${id}/products`, { params })
      .pipe(tap((pagination) => setFavoriteState(...pagination.data)));
  }

  galleryItems(id: string, page = 1): Observable<Pagination<GalleryItem>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<GalleryItem>>(
      `/api/makers/${id}/gallery-items`,
      { params },
    );
  }

  searchParamsMap(
    id: string,
    categories: string[],
  ): Observable<Map<string, SearchParamsOptionSet>> {
    return this.http
      .get<List<SearchParamsOptionSet>>(`/api/makers/${id}/search-params`, {
        params: this.converter.convert({ categories }),
      })
      .pipe(
        map((response) => {
          const paramsMap = new Map<string, SearchParamsOptionSet>();
          Object.keys(response).forEach((categoryId) => {
            return (
              !!response[categoryId] &&
              paramsMap.set(categoryId, response[categoryId])
            );
          });
          return paramsMap;
        }),
      );
  }
}
