import { Injectable } from '@angular/core';
import { ApiService } from '@shared/api/api.service';
import { Paginator } from '@shared/models/pagination/paginator';
import {
  FilterCondition,
  FilteredVariationTotalCount,
  ProductVariationRequest,
} from '@shared/models/request/product-variation-request';
import { CandidateProduct } from '@shared/models/response/list-product';
import { Pagination } from '@shared/models/response/pagination';
import { Picklist } from '@shared/models/response/picklist';
import { Product } from '@shared/models/response/product';
import { Observable, Subject } from 'rxjs';

import { PicklistService } from './picklist.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  onAddListProduct$ = new Subject<Product | undefined>();
  onFilteringVariation$ = new Subject<ProductVariationRequest | undefined>();

  constructor(
    private picklistService: PicklistService,
    readonly api: ApiService,
  ) {}

  changeAddVariationProduct(paginator: Paginator<Product>, product: Product) {
    paginator.data.subscribe((paginate) => {
      this.addedVariationProductAll(paginate, product);
    });
  }

  addedVariationProductAll(paginate: Product[], product: Product) {
    const variation = paginate.filter(
      (p) => p.cache.group_key === product.cache.group_key,
    );
    if (variation) {
      variation.forEach((v) => {
        v.is_fav = true;
      });
    }
  }

  findAndAddProductFromCurrentPicklist(
    paginator: Paginator<Product> | Product[],
    currentPicklist: Picklist,
  ) {
    const uniqueKeys =
      this.picklistService.getAllProductUniqueKey(currentPicklist);
    if ('data' in paginator) {
      paginator.data.subscribe((paginate) => {
        this.findAndAddProductFromProductUniqueKeys(paginate, uniqueKeys);
      });
    } else {
      this.findAndAddProductFromProductUniqueKeys(paginator, uniqueKeys);
    }
  }

  findAndAddProductFromProductUniqueKeys(
    paginate: Product[],
    uniqueKeys: string[],
  ) {
    const addProduct = paginate.filter((p) =>
      uniqueKeys.includes(p.unique_key),
    );
    if (addProduct) {
      addProduct.forEach((a) => {
        this.addedVariationProductAll(paginate, a);
      });
    }
  }

  createVariationRequestFromFilterCondition(
    filterCondition: FilterCondition[],
    group_key: string,
    page_limit: number = 20,
  ): ProductVariationRequest {
    const filterVariationRequest: ProductVariationRequest = {
      group_key,
      matches: [],
      page_limit,
    };
    if (filterCondition) {
      filterCondition.forEach((f) => {
        const filterKey = Object.keys(f).find((k) => k);
        if (filterKey) {
          f[filterKey].forEach((v) => {
            filterVariationRequest.matches!.push({ key: filterKey, value: v });
          });
        }
      });
    }
    return filterVariationRequest;
  }

  createVariationRequestFromListProducts(
    candidateProducts: CandidateProduct[],
  ): ProductVariationRequest[] {
    const filterVariationRequests: ProductVariationRequest[] = [];
    candidateProducts.forEach((l) => {
      const product = l.product;
      if (product) {
        const filterVariationRequest: ProductVariationRequest =
          this.createVariationRequestFromFilterCondition(
            l.filter_condition!,
            product!.cache.group_key,
          );
        filterVariationRequest.list_product_id = l.id;
        filterVariationRequests.push(filterVariationRequest);
      }
    });
    return filterVariationRequests;
  }

  filteredVariation(filterVariationRequest: ProductVariationRequest) {
    const onEnd$ = new Subject<Pagination<Product>>();
    this.api.product
      .filteredVariation(filterVariationRequest)
      .subscribe((pagination) => {
        if (pagination) {
          onEnd$.next(pagination);
        }
      });
    return onEnd$;
  }

  filteredVariationTotal(
    candidateProducts: CandidateProduct[],
  ): Observable<FilteredVariationTotalCount[]> {
    const filterVariationRequests: ProductVariationRequest[] =
      this.createVariationRequestFromListProducts(candidateProducts);

    return this.api.product.filteredVariationTotal(filterVariationRequests);
  }

  combineVariationTotalAndCandidate(
    candidateProducts: CandidateProduct[],
    filteredVariationTotalCounts: FilteredVariationTotalCount[],
  ) {
    const combined = candidateProducts.map((candidate) => {
      const count = filteredVariationTotalCounts.find(
        (count) => count.list_product_id === candidate.id,
      )?.total;
      const variationCount = count ? count - 1 : count;
      return {
        ...candidate,
        variation_count: variationCount,
      } as CandidateProduct;
    });
    return combined;
  }

  getProductRouterLink(productId: string) {
    return `product/${productId}`;
  }
}
