export enum PicklistProductStatus {
  Candidate = 'candidate',
  Requested = 'requested',
  Decided = 'decided',
  Fixed = 'fixed',
  Reject = 'reject',
}

export const getPicklistProductStatusLabel = (
  status: PicklistProductStatus | string | undefined,
) => {
  switch (status) {
    case PicklistProductStatus.Candidate:
      return '候補';
    case PicklistProductStatus.Requested:
      return '提案';
    case PicklistProductStatus.Decided:
      return '仮採用';
    case PicklistProductStatus.Fixed:
      return '確定';
    case PicklistProductStatus.Reject:
      return '却下';
    default:
      return '';
  }
};
