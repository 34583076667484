import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RoomToRoomApiService } from 'app/services/api/room-to-room-api.service';
import { catchError, map, of, switchMap } from 'rxjs';

import { roomToRoomAction } from './actions';

@Injectable()
export class RoomToRoomEffects {
  fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(roomToRoomAction.fetchAll),
      switchMap(({ projectId, region, businessGroupId }) => {
        return this.roomToRoomApi
          .fetchAll(projectId, region, businessGroupId)
          .pipe(
            map((result) =>
              roomToRoomAction.fetchAllSuccess({
                roomToRooms: result,
              }),
            ),
            catchError((error) =>
              of(roomToRoomAction.fetchAllFailure({ error })),
            ),
          );
      }),
    ),
  );

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(roomToRoomAction.updateMany),
      switchMap(({ projectId, region, businessGroupId, roomToRooms }) => {
        if (region && businessGroupId) {
          return this.roomToRoomApi
            .updateMany(projectId, region, businessGroupId, roomToRooms)
            .pipe(
              map(() => {
                return roomToRoomAction.updateManySuccess({
                  roomToRooms,
                });
              }),
            );
        } else {
          return of(
            roomToRoomAction.updateManyFailure({
              error: 'error',
            }),
          );
        }
      }),
    ),
  );

  updateSpecs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(roomToRoomAction.updateSpecs),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          roomToRooms,
          roomToRoomSpecs,
        }) => {
          return this.roomToRoomApi
            .updateSpecifications(
              projectId,
              region,
              businessGroupId,
              roomToRoomSpecs,
            )
            .pipe(
              map(() => {
                return roomToRoomAction.updateSpecsSuccess({
                  roomToRooms,
                  roomToRoomSpecs,
                });
              }),
              catchError((error) => {
                return of(roomToRoomAction.updateSpecsFailure({ error }));
              }),
            );
        },
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private roomToRoomApi: RoomToRoomApiService,
  ) {}
}
