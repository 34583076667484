import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

// Google Tag Manager
const script = document.createElement('script');
script.text = `
(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${environment.gtmId}');`;
document.head.appendChild(script);
// End Google Tag Manager

// Google Tag Manager (noscript)
const noscript = document.createElement('noscript');
const iframe = document.createElement('iframe');
iframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.gtmId}`;
iframe.height = '0';
iframe.width = '0';
iframe.setAttribute('style', 'display:none;visibility:hidden');
noscript.appendChild(iframe);
document.body.insertBefore(noscript, document.body.firstChild);
// End Google Tag Manager (noscript)

// gtag
gtag('js', new Date());
gtag('config', environment.gaTrackingId);
