import { Injectable } from '@angular/core';
import { SymbolWithNumber } from '@interfaces/symbol-with-number';
import { select, Store } from '@ngrx/store';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { map, Observable } from 'rxjs';

import { symbolWithNumberAction } from './actions';
import { symbolWithNumberSelector } from './selectors';

@Injectable({
  providedIn: 'root',
})
export class SymbolWithNumberFacade {
  isFetching$ = this.store.pipe(select(symbolWithNumberSelector.getIsFetching));
  error$ = this.store.pipe(select(symbolWithNumberSelector.getError));
  symbolWithNumbers$ = this.store.pipe(
    select(symbolWithNumberSelector.getSymbolWithNumbers),
  );
  uniqueSymbols$ = this.store.pipe(
    select(symbolWithNumberSelector.getUniqueSymbols),
  );

  constructor(private readonly store: Store) {}

  fetchAll(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
  ) {
    this.store.dispatch(
      symbolWithNumberAction.fetchAll({ projectId, region, businessGroupId }),
    );
  }

  set(symbolWithNumber: SymbolWithNumber): void {
    this.store.dispatch(symbolWithNumberAction.set({ symbolWithNumber }));
  }

  sets(symbolWithNumbers: SymbolWithNumber[]): void {
    this.store.dispatch(
      symbolWithNumberAction.setsByEffect({ symbolWithNumbers }),
    );
  }

  removeByEffect(joineryId: number): void {
    this.store.dispatch(symbolWithNumberAction.removeByEffect({ joineryId }));
  }

  getEntityByJoineryId(
    joineryId: number,
  ): Observable<SymbolWithNumber | undefined> {
    return this.symbolWithNumbers$.pipe(
      map((sn) => sn.find((_sn) => _sn.id === joineryId)),
    );
  }
}
