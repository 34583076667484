import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JoineryPageApiService } from 'app/services/api/joinery-page-api.service';
import { catchError, map, of, switchMap } from 'rxjs';

import { joineryOptionSettingAction } from './actions';

@Injectable()
export class JoineryOptionSettingEffects {
  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joineryOptionSettingAction.fetch),
      switchMap(({ projectId }) => {
        return this.joineryPageApi.getJoineryOptionSettings(projectId).pipe(
          map((joineryOptionSettings) =>
            joineryOptionSettingAction.fetchSuccess({
              joineryOptionSettings,
            }),
          ),
          catchError((error) =>
            of(joineryOptionSettingAction.fetchFailure({ error })),
          ),
        );
      }),
    ),
  );

  addSpecOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joineryOptionSettingAction.addSpecOption),
      switchMap(({ projectId, joineryOptionSetting, specOption }) => {
        return this.joineryPageApi
          .addJoinerySpecOption(projectId, specOption)
          .pipe(
            map((_specOption) =>
              joineryOptionSettingAction.addSpecOptionSuccess({
                joineryOptionSetting,
                specOption: { ..._specOption, project_id: projectId },
              }),
            ),
            catchError((error) =>
              of(joineryOptionSettingAction.addSpecOptionFailure({ error })),
            ),
          );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private joineryPageApi: JoineryPageApiService,
  ) {}
}
