import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserService } from '../shared/service/user.service';

@Injectable()
export class UserIdInterceptor implements HttpInterceptor {
  constructor(private user: UserService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const isApiRequest = req.url.startsWith('/api');

    if (!isApiRequest) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          const userId = response.headers.get('user_id') || undefined;
          this.user.setCurrentUserId(userId);
        }
      }),
    );
  }
}
