import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import {
  FloorGroup,
  FloorGroupState,
} from '../../domain/types/states/floor-group.state';

@Injectable({
  providedIn: 'root',
})
export class FloorGroupStore extends ComponentStore<FloorGroupState> {
  constructor() {
    super({
      floorGroups: [],
    });
  }

  floorGroups$: Observable<FloorGroup[]> = this.select(
    (state) => state.floorGroups,
  );

  addFloorGroup = this.updater((state: FloorGroupState, value: FloorGroup) => {
    return {
      floorGroups: [...state.floorGroups, value],
    };
  });

  deleteFloorGroup = this.updater(
    (state: FloorGroupState, deleteItemId: number) => {
      const newState = state.floorGroups.filter((floorGroup) => {
        return floorGroup.id !== deleteItemId;
      });
      return { floorGroups: newState };
    },
  );

  updateFloorGroup = this.updater((state, newItem: FloorGroup) => {
    const updatedItems = state.floorGroups.map((item) => {
      if (item.id === newItem.id) {
        return newItem;
      }
      return item;
    });

    return { ...state, floorGroups: updatedItems };
  });

  updateManyFloorGroup = this.updater((state, newItems: FloorGroup[]) => {
    const updatedItems = state.floorGroups.map((item) => {
      const matchingItem = newItems.find((newItem) => newItem.id === item.id);
      if (matchingItem) {
        return { ...item, ...matchingItem };
      }
      return item;
    });

    return { ...state, floorGroups: updatedItems };
  });
}
