import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TenantStore } from '../_store/tenant.store';

@Injectable({
  providedIn: 'root',
})
export class CurrentTenantIdLogic {
  private store = inject(TenantStore);
  private router = inject(Router);

  invalidIdHandler(error?: Error): void {
    this.store.remove();
    this.router.navigateByUrl('/tenant/login');

    if (error) console.error(error);
    else console.error('invalid tenant id');
  }
}
