import { createReducer, on } from '@ngrx/store';

import { JoineryPageInfoAction } from './actions';
import {
  initialJoineryPageInfoState,
  JoineryPageInfoAdapter,
} from './joinery-page-info.state';

export const joineryPageInfoReducer = createReducer(
  initialJoineryPageInfoState,
  on(JoineryPageInfoAction.fetch, (state) => {
    JoineryPageInfoAdapter.removeAll({ ...state });
    return { ...state, isFetching: true };
  }),
  on(JoineryPageInfoAction.fetchSuccess, (state, { joineryPageInfo }) =>
    JoineryPageInfoAdapter.upsertOne(joineryPageInfo, {
      ...state,
      isFetching: false,
    }),
  ),
  on(JoineryPageInfoAction.fetchFailure, (state, { error }) => ({
    ...state,
    isFetching: false,
    error,
  })),
);
