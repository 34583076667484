import { Injectable } from '@angular/core';
import { RoomToRoom } from '@interfaces/room-to-room';
import { RoomToRoomSpecification } from '@interfaces/room-to-room-specification';
import { select, Store } from '@ngrx/store';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { map, Observable } from 'rxjs';

import { roomToRoomAction } from './actions';
import { roomToRoomSelector } from './selectors';

@Injectable({
  providedIn: 'root',
})
export class RoomToRoomFacade {
  isFetching$ = this.store.pipe(select(roomToRoomSelector.getIsFetching));
  error$ = this.store.pipe(select(roomToRoomSelector.getError));
  roomToRooms$ = this.store.pipe(select(roomToRoomSelector.getRoomToRooms));

  constructor(private readonly store: Store) {}

  fetchAll(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
  ) {
    this.store.dispatch(
      roomToRoomAction.fetchAll({ projectId, region, businessGroupId }),
    );
  }

  updateMany(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    roomToRooms: RoomToRoom[],
  ): void {
    this.store.dispatch(
      roomToRoomAction.updateMany({
        projectId,
        region,
        businessGroupId,
        roomToRooms,
      }),
    );
  }

  updateSpecs(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    roomToRooms: RoomToRoom[],
    roomToRoomSpecs: RoomToRoomSpecification[],
  ): void {
    this.store.dispatch(
      roomToRoomAction.updateSpecs({
        projectId,
        region,
        businessGroupId,
        roomToRooms,
        roomToRoomSpecs,
      }),
    );
  }

  getRoomToRoomsByJoineryId(joineryId: number): Observable<RoomToRoom[]> {
    return this.roomToRooms$.pipe(
      map((rooms) => rooms.filter((room) => room.joinery_id === joineryId)),
    );
  }
}
