export enum ProjectType {
  Project = 'project',
  Template = 'template',
  Sample = 'sample',
  StandardSet = 'standard_set',
}

export const getProjectTypeLabel = (type: ProjectType | string) => {
  switch (type) {
    case ProjectType.Project:
      return 'プロジェクト';
    case ProjectType.Template:
      return 'テンプレート';
    case ProjectType.Sample:
      return 'サンプルプロジェクト';
    case ProjectType.StandardSet:
      return '標準セット';
    default:
      return 'プロジェクト';
  }
};
