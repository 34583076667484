export enum ProjectRangeType {
  FinishSchedule = 'finish-schedule',
  Picklist = 'picklist',
  PicklistTable = 'picklist-table',
  Confirm = 'confirm',
  Setting = 'setting',
  Log = 'log',
  Member = 'member',
  PicklistDetail = 'image',
  Search = 'search',
  Interior = 'interior',
  Exterior = 'exterior',
  JoinerySchedule = 'joinery-schedule',
  JoineryOptionSettings = 'joinery-option-settings',
  Window = 'window-frame',
  Door = 'door-frame',
  Shutter = 'shutter',
  Category = 'category',
  ThumbNail = 'thumbnail',
  Chart = 'chart',
  Image = 'image',
  CategoryImage = 'category-image',
  CategoryChart = 'category-chart',
  Basic = 'basic',
  Top = 'top',
  ProjectIndex = 'project-index',
  SampleRequestHistory = 'sample-request-history',
  ShippingAddress = 'shipping-address',
}
