<mat-dialog-content>
  <div>
    {{ currentPicklistTypeLabel }}領域の材料情報が変更されました。
    <br />リンク済みの{{ otherLinkPicklistTypeLabel }}領域の材料「{{
      otherLinkPicklist?.name
    }}」の情報も変更してよろしいですか？
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <a ls-button (click)="okClick()" [border]="true">リンクを解除する</a>
  <a ls-button (click)="cancel()" [border]="true" theme="primary">
    {{ otherLinkPicklistTypeLabel }}領域の材料「{{
      otherLinkPicklist?.name
    }}」も変更する
  </a>
</mat-dialog-actions>
