import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JoineryComponentApiService } from 'app/services/api/joinery-component-api.service';
import { catchError, firstValueFrom, map, of, switchMap, tap } from 'rxjs';

import { PicklistStoreFacade } from '../picklist/picklist-store.facade';

import { joineryComponentAction } from './actions';

@Injectable()
export class JoineryComponentEffects {
  addComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joineryComponentAction.create),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          component,
          standardComponent,
        }) => {
          return this.joineryComponentApi
            .addComponent(projectId, region, businessGroupId, component)
            .pipe(
              map((component) =>
                joineryComponentAction.createSuccess({
                  component: { ...component, standardComponent },
                }),
              ),
              catchError((error) =>
                of(joineryComponentAction.createFailure({ error })),
              ),
            );
        },
      ),
    ),
  );

  removeComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joineryComponentAction.remove),
      switchMap(({ projectId, region, businessGroupId, component }) => {
        return this.joineryComponentApi
          .removeComponent(projectId, region, businessGroupId, component)
          .pipe(
            map((component) => joineryComponentAction.removeSuccess()),
            catchError((error) =>
              of(joineryComponentAction.removeFailure({ error })),
            ),
          );
      }),
    ),
  );

  pastePicklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joineryComponentAction.pastePicklist),
      switchMap(
        ({ projectId, region, businessGroupId, component, picklist }) => {
          return this.joineryComponentApi
            .pastePicklistToComponent(
              projectId,
              region,
              businessGroupId,
              component.joinery_id,
              component.id,
              picklist,
            )
            .pipe(
              tap(() => {
                this.picklistFacade.setJoineryComponentIds(
                  [component.id],
                  picklist,
                );
              }),
              map(() =>
                joineryComponentAction.pastePicklistSuccess({
                  component: { ...component, picklist_id: picklist.id },
                }),
              ),
              catchError((error) =>
                of(joineryComponentAction.pastePicklistFailure({ error })),
              ),
            );
        },
      ),
    ),
  );

  removePicklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joineryComponentAction.removePicklist),
      switchMap(
        ({ projectId, region, businessGroupId, component, picklistId }) => {
          return this.joineryComponentApi
            .removePicklistFromComponent(
              projectId,
              region,
              businessGroupId,
              component.joinery_id,
              component.id,
              picklistId,
            )
            .pipe(
              map(() => {
                firstValueFrom(
                  this.picklistFacade.getPicklistByPicklistId(
                    component.picklist_id,
                  ),
                ).then((picklist) => {
                  if (picklist) {
                    this.picklistFacade.removeJoineryComponentIds(
                      [component],
                      [picklist],
                    );
                  }
                });

                return joineryComponentAction.removePicklistSuccess({
                  component: { ...component, picklist_id: null },
                });
              }),
              catchError((error) =>
                of(joineryComponentAction.removePicklistFailure({ error })),
              ),
            );
        },
      ),
    ),
  );

  pastePicklistMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joineryComponentAction.pastePicklistMany),
      switchMap(
        ({ projectId, region, businessGroupId, components, picklistId }) => {
          return this.joineryComponentApi
            .pastePicklistToComponents(
              projectId,
              region,
              businessGroupId,
              components.map((c) => c.id),
              picklistId,
            )
            .pipe(
              map(() => {
                firstValueFrom(
                  this.picklistFacade.getPicklistByPicklistId(picklistId),
                ).then((picklist) => {
                  if (picklist) {
                    this.picklistFacade.setJoineryComponentIds(
                      components.map((c) => c.id),
                      picklist,
                    );
                  }
                });
                const pastedComponents = components.map((c) => ({
                  ...c,
                  picklist_id: picklistId,
                }));
                return joineryComponentAction.pastePicklistManySuccess({
                  components: pastedComponents,
                });
              }),
              catchError((error) =>
                of(joineryComponentAction.pastePicklistManyFailure({ error })),
              ),
            );
        },
      ),
    ),
  );

  removePicklistMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(joineryComponentAction.removePicklistMany),
      switchMap(({ projectId, region, businessGroupId, components }) => {
        return this.joineryComponentApi
          .removePicklistFromComponents(
            projectId,
            region,
            businessGroupId,
            components.map((c) => c.id),
          )
          .pipe(
            map(() => {
              firstValueFrom(
                this.picklistFacade.getPicklistsByPicklistIds(
                  components.flatMap((c) => c.picklist_id ?? []),
                ),
              ).then((picklists) => {
                this.picklistFacade.removeJoineryComponentIds(
                  components,
                  picklists,
                );
              });
              const removedComponents = components.map((c) => ({
                ...c,
                picklist_id: null,
              }));
              return joineryComponentAction.removePicklistManySuccess({
                components: removedComponents,
              });
            }),
            catchError((error) =>
              of(joineryComponentAction.removePicklistManyFailure({ error })),
            ),
          );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private joineryComponentApi: JoineryComponentApiService,
    private picklistFacade: PicklistStoreFacade,
  ) {}
}
