import { JoineryComponentWithStandard } from '@interfaces/joinery-component';
import { JoineryPicklistSpecification } from '@interfaces/joinery-picklist-specification';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Picklist } from '@shared/models/response/picklist';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';

import { PicklistFilterParam } from '../picklist.state';

export const fetchAll = createAction(
  '[Picklist Page] Fetch All',
  props<{
    projectId: number;
    region?: ProjectRangeType;
    businessGroupId?: number;
  }>(),
);

export const fetchAllSuccess = createAction(
  '[Picklist API] Fetch All Success',
  props<{ picklists: Picklist[] }>(),
);

export const fetchAllFailure = createAction(
  '[Picklist API] Fetch All Failure',
  props<{ error: unknown }>(),
);

export const updateManySuccess = createAction(
  '[Picklist API] UpdateMany Success',
  props<{
    picklists: Update<Picklist>[];
  }>(),
);

export const removeMany = createAction(
  '[Picklist Page] RemoveMany',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    picklists: Picklist[];
  }>(),
);

export const removeManySuccess = createAction(
  '[Picklist API] Remove Success',
  props<{ ids: number[] }>(),
);

export const removeManyFailure = createAction(
  '[Picklist API] Remove Failure',
  props<{ error: unknown; id: number }>(),
);

export const addProcessingId = createAction(
  '[Picklist Page] AddProcessingId',
  props<{ id: number }>(),
);

export const updateSpec = createAction(
  '[Picklist Page] Update Spec',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    picklist: Picklist;
    picklistSpecification: JoineryPicklistSpecification;
  }>(),
);

export const updateSpecSuccess = createAction(
  '[Picklist API] Update Spec Success',
  props<{ picklist: Picklist }>(),
);

export const updateSpecFailure = createAction(
  '[Picklist API] Update Spec Failure',
  props<{ error: unknown }>(),
);

export const duplicateJoineryPicklist = createAction(
  '[Picklist Page] Duplicate Joinery Picklist',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    picklist: Picklist;
  }>(),
);

export const duplicateJoineryPicklistSuccess = createAction(
  '[Picklist Page] Duplicate Joinery Picklist Success',
  props<{
    picklist: Picklist;
  }>(),
);

export const duplicateJoineryPicklistFailure = createAction(
  '[Picklist Page] Duplicate Joinery Picklist Failure',
  props<{ error: unknown }>(),
);

export const addPicklistToStandardComponent = createAction(
  '[Picklist Page] AddPicklistToStandard',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    standardComponentId: number;
    newPicklistName: string;
    parentPicklist?: Picklist;
  }>(),
);

export const addPicklistToStandardComponentSuccess = createAction(
  '[Picklist API] AddPicklistToStandard Success',
  props<{ picklist: Picklist }>(),
);

export const addPicklistToStandardComponentFailure = createAction(
  '[Picklist API] AddPicklistToStandard Failure',
  props<{ error: unknown }>(),
);

export const addPicklistToComponent = createAction(
  '[Picklist Page] AddPicklistToComponent',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    component: JoineryComponentWithStandard;
    newPicklistName: string;
    parentPicklist?: Picklist;
  }>(),
);

export const addPicklistToComponentSuccess = createAction(
  '[Picklist API] AddPicklistToComponent Success',
  props<{ picklists: Picklist[] }>(),
);

export const addPicklistToComponentFailure = createAction(
  '[Picklist API] AddPicklistToComponent Failure',
  props<{ error: unknown }>(),
);

export const setJoineryComponentIds = createAction(
  '[Picklist Effect] SetJoineryComponentIds',
  props<{ componentIds: number[]; picklist: Picklist }>(),
);

export const removeJoineryComponentIds = createAction(
  '[Picklist Effect] RemoveJoineryComponentIds',
  props<{
    components: JoineryComponentWithStandard[];
    picklists: Picklist[];
  }>(),
);

export const sets = createAction(
  '[Picklist Page] Sets',
  props<{ picklists: Picklist[] }>(),
);

export const setFilter = createAction(
  '[Picklist Page] SetFilter',
  props<{ param: PicklistFilterParam }>(),
);
