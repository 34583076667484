<span
  class="select-new-value-badge"
  *ngFor="let value of values; let i = index"
>
  {{ value.name }}
  <span class="remove" (click)="removeValue(value)">×</span>
</span>
<textarea
  placeholder="{{ values.length ? '' : placeholder }}"
  type="text"
  autocomplete="off"
  (input)="getTypedValue($event)"
  (keydown)="handleInput($event)"
  (paste)="handleInput($event)"
  [formControl]="nameControl"
  [matAutocomplete]="auto"
  (click)="openOptions()"
  #autocompleteTrigger
></textarea>
<mat-autocomplete
  #auto="matAutocomplete"
  [panelWidth]="'unset'"
  (optionSelected)="selected($event)"
>
  <mat-option
    *ngFor="let standardSet of filteredStandardSets$ | async"
    [value]="standardSet"
    matTooltip="{{ standardSetTooltips[standardSet.id!] }}"
    matTooltipClass="standard-set-tool-tip"
    matTooltipPosition="right"
  >
    {{ standardSet.name }}
  </mat-option>
</mat-autocomplete>
