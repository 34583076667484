import { RoomToRoom } from '@interfaces/room-to-room';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { roomToRoomAction } from './actions';
import {
  initialRoomToRoomState,
  roomToRoomAdapter,
} from './room-to-room.state';

export const roomToRoomReducer = createReducer(
  initialRoomToRoomState,
  on(roomToRoomAction.fetchAll, (state) => {
    return roomToRoomAdapter.removeAll({ ...state, isFetching: true });
  }),
  on(roomToRoomAction.fetchAllSuccess, (state, { roomToRooms }) => {
    return roomToRoomAdapter.setAll(roomToRooms, {
      ...state,
      isFetching: false,
    });
  }),
  on(roomToRoomAction.fetchAllFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(roomToRoomAction.updateMany, (state) => {
    return { ...state };
  }),
  on(roomToRoomAction.updateManySuccess, (state, { roomToRooms }) => {
    return roomToRoomAdapter.updateMany(
      roomToRooms.map((value) => ({ id: value.id, changes: { ...value } })),
      {
        ...state,
        isFetching: false,
      },
    );
  }),
  on(roomToRoomAction.updateManyFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(roomToRoomAction.updateSpecs, (state) => {
    return { ...state };
  }),
  on(
    roomToRoomAction.updateSpecsSuccess,
    (state, { roomToRooms, roomToRoomSpecs }) => {
      const updatedRooms: RoomToRoom[] = roomToRooms.map((room) => {
        return {
          ...room,
          joinery_room_to_room_specifications:
            room.joinery_room_to_room_specifications.map((spec) => {
              const updated = roomToRoomSpecs.find(
                (_spec) => spec.id === _spec.id,
              );
              return updated ?? spec;
            }),
        };
      });
      const changes: Update<RoomToRoom>[] = updatedRooms.map((room) => ({
        id: room.id,
        changes: { ...room },
      }));
      return roomToRoomAdapter.updateMany(changes, {
        ...state,
        isFetching: false,
      });
    },
  ),
  on(roomToRoomAction.updateSpecsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);
