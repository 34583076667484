import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import {
  InteriorSet,
  InteriorSetState,
} from '../../domain/types/states/interior-set.state';

@Injectable({
  providedIn: 'root',
})
export class InteriorSetStore extends ComponentStore<InteriorSetState> {
  constructor() {
    super({
      interiorSets: [],
    });
  }

  interiorSets$: Observable<InteriorSet[]> = this.select(
    (state) => state.interiorSets,
  );

  addInteriorSet = this.updater(
    (state: InteriorSetState, value: InteriorSet) => {
      return {
        interiorSets: [...state.interiorSets, value],
      };
    },
  );

  addManyInteriorSet = this.updater(
    (state: InteriorSetState, value: InteriorSet[]) => {
      return {
        ...state,
        interiorSets: [...state.interiorSets, ...value],
      };
    },
  );

  deleteInteriorSet = this.updater(
    (state: InteriorSetState, deleteItemId: number) => {
      const newState = state.interiorSets.filter((interiorSet) => {
        return interiorSet.id !== deleteItemId;
      });
      return { interiorSets: newState };
    },
  );

  deleteManyInteriorSet = this.updater(
    (state: InteriorSetState, deleteInteriorSetIds: number[]) => {
      const newState = state.interiorSets.filter((roomBlock) => {
        return !deleteInteriorSetIds.includes(roomBlock.id);
      });
      return { interiorSets: newState };
    },
  );

  updateState = this.updater(
    (state: InteriorSetState, value: InteriorSetState) => {
      return {
        ...value,
      };
    },
  );
}
