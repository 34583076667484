import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ExteriorSelectNewValueComponent } from '@shared/ui/select/select-new-value/exterior/exterior-select-new-value.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ExteriorSet } from '../../models/response/exterior-set';
import { ProjectService } from '../../service/project.service';

export interface AddExteriorSetResult {
  categoryName: string;
  sets: ExteriorSet[];
}

export interface FilteredExteriorSet {
  category_name: string;
  category_id: number | null;
  sets: { id: number; name: string }[];
}

export interface InputValue {
  name: string;
  id?: number;
}

@Component({
  selector: 'ls-add-exterior-set-dialog',
  templateUrl: './add-exterior-set-dialog.component.html',
  styleUrls: ['./add-exterior-set-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class AddExteriorSetDialogComponent implements OnInit {
  @ViewChild('exteriorSelectNewValue')
  exteriorSelectNewValue: ExteriorSelectNewValueComponent;
  inputData: AddExteriorSetResult = {
    categoryName: '',
    sets: [],
  };

  nameControl = new UntypedFormControl();
  options: string[] = [];
  filteredGroup: Observable<FilteredExteriorSet[]>;
  setNames: InputValue[] = [];
  typedValue: string;

  filteredStandardSets: FilteredExteriorSet[] = (() => {
    const standardSets = this.project.exteriorStandardSets
      .filter((set) => set.exterior_category_name !== '*')
      .map((standardSet) => {
        return {
          category_name: standardSet.exterior_category_name,
          category_id: standardSet.exterior_category_id,
          sets: standardSet.exterior_category_info.map((set) => {
            return {
              id: set.exterior_set_id,
              name: set.exterior_set_name,
            };
          }),
        };
      });

    const inputCategories = this.project.exteriorCategories.map(
      (exteriorCategory) => {
        return {
          category_name: exteriorCategory.name,
          category_id: null,
          sets: [],
        };
      },
    );

    return [...inputCategories, ...standardSets];
  })();

  standardSetToolTip = this.project.exteriorStandardSets.reduce(
    (collection, standardSet) => {
      const categoryId = standardSet.exterior_category_id;

      const maxLength = standardSet.exterior_category_info.reduce(
        (max, info) => {
          return Math.max(max, info.exterior_set_name.length);
        },
        -Infinity,
      );

      const setNames = standardSet.exterior_category_info.reduce(
        (values, info) => {
          const picklistNames = info.picklists
            .map((picklistId) => {
              return (
                this.project.parentPicklists.find((_picklist) => {
                  return _picklist.id == picklistId;
                })?.name || null
              );
            })
            .filter((name) => {
              return !!name;
            })
            .join(' / ');

          if (picklistNames) {
            values.push(
              `${info.exterior_set_name.padEnd(maxLength)}：${picklistNames}`,
            );
          }

          return values;
        },
        [] as string[],
      );

      if (setNames.length > 0) {
        collection[categoryId] = setNames.join('\r\n');
      }

      return collection;
    },
    {} as { [key: number]: string },
  );

  get canSubmit() {
    return (
      !!this.nameControl.value &&
      (!!this.setNames!.length ||
        (!!this.typedValue && this.typedValue.length > 0))
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { groupNameList: string[] },
    private dialogRef: MatDialogRef<AddExteriorSetDialogComponent, any>,
    private project: ProjectService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.nameControl.setValue(this.inputData.categoryName);
    this.filteredGroup = this.nameControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => {
        // enterを押すと改行コードが入力値に含まれてしまうので削除している
        const category_name = value.replace(/\r?\n/g, '').toLowerCase();

        return this.filteredStandardSets.filter((standardSet) =>
          standardSet.category_name.toLowerCase().includes(category_name),
        );
      }),
    );
  }

  autoInput(exteriorSet: FilteredExteriorSet, event: MatOptionSelectionChange) {
    if (event.isUserInput && exteriorSet.sets.length > 0) {
      this.setNames = [...exteriorSet.sets];
      this.changeDetector.markForCheck();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.inputData.categoryName = this.nameControl.value;
    if (this.typedValue?.length > 0) {
      const setName = this.exteriorSelectNewValue.nameControl.value;
      this.setNames.push({
        name: setName,
        id: 0,
      });
    }
    this.setNames.forEach((set: InputValue) => {
      this.inputData.sets.push(
        Object.assign(this.initSet(), { name: set.name, id: set.id }),
      );
    });
    this.dialogRef.close(this.inputData);
  }

  initSet() {
    return {
      id: 0,
      project_id: 0,
      exterior_category_id: 0,
      prefix: '',
      name: '',
      remarks: '',
      memo: '',
      is_approved: false,
      approved_at: '',
      finish_schedule_order: 1,
      surface_picklist_id: null,
      surfacePicklist: null,
      picklists: [],
      picklistInfo: [],
      created_at: '',
      updated_at: '',
    };
  }
}
