import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { ProjectService as ProjectAPI } from '@shared/api/project.service';
import { CandidateProduct } from '@shared/models/response/list-product';
import { PicklistService } from '@shared/service/picklist.service';
import { ProductService } from '@shared/service/product.service';
import { ProjectService } from '@shared/service/project.service';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import { CandidateProductAction } from '../actions';

@Injectable()
export class CandidateProductEffects {
  fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.fetchAll),
      switchMap(({ picklists }) =>
        this.picklistService.getCandidateProductsByPicklists(picklists).pipe(
          map((result) =>
            CandidateProductAction.fetchAllSuccess({
              candidateProducts: result,
            }),
          ),
          catchError((error) =>
            of(CandidateProductAction.fetchAllFailure({ error })),
          ),
        ),
      ),
    ),
  );

  fetchAllSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.fetchAllSuccess),
      concatMap(({ candidateProducts }) =>
        of(CandidateProductAction.getVariationTotal({ candidateProducts })),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.create),
      concatMap(({ candidateProduct }) => {
        this.projectApiService.onBeginUpdate$.next();
        if (candidateProduct.product) {
          return of(CandidateProductAction.createSuccess({ candidateProduct }));
        } else {
          return this.picklistService
            .mixProductToListProduct(candidateProduct)
            .pipe(
              map((candidateProduct) =>
                CandidateProductAction.createSuccess({ candidateProduct }),
              ),
              catchError((error) =>
                of(CandidateProductAction.createFailure({ error })),
              ),
            );
        }
      }),
    ),
  );

  createSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.createSuccess),
      concatMap(({ candidateProduct }) => {
        this.applyListProductChangeToFinishSchedule(candidateProduct, 'create');
        if (this.picklistService.isManualInput(candidateProduct)) {
          return EMPTY;
        } else {
          return of(
            CandidateProductAction.getVariationTotal({
              candidateProducts: [candidateProduct],
            }),
          );
        }
      }),
    ),
  );

  createMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.createMany),
      concatMap(({ candidateProducts }) => {
        this.projectApiService.onBeginUpdate$.next();
        return this.picklistService
          .mixProductsToListProducts(candidateProducts)
          .pipe(
            map((candidateProducts) =>
              CandidateProductAction.createManySuccess({ candidateProducts }),
            ),
            catchError((error) =>
              of(CandidateProductAction.createManyFailure({ error })),
            ),
          );
      }),
    ),
  );

  createManySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.createManySuccess),
      concatMap(({ candidateProducts }) => {
        candidateProducts.forEach((candidateProduct) => {
          this.applyListProductChangeToFinishSchedule(
            candidateProduct,
            'create',
          );
        });
        const hasProductCandidates =
          candidateProducts.filter(
            (candidate) =>
              this.picklistService.isManualInput(candidate) === false,
          ) ?? [];
        if (hasProductCandidates.length === 0) {
          return EMPTY;
        } else {
          return of(
            CandidateProductAction.getVariationTotal({
              candidateProducts: hasProductCandidates,
            }),
          );
        }
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.update),
      concatMap(({ candidateProduct }) => {
        this.projectApiService.onBeginUpdate$.next();
        if (candidateProduct.product_unique_key) {
          return this.picklistService
            .mixProductToListProduct(candidateProduct)
            .pipe(
              map((_candidateProduct) =>
                CandidateProductAction.updateSuccess({
                  candidateProduct: _candidateProduct,
                }),
              ),
              catchError((error) =>
                of(
                  CandidateProductAction.updateFailure({
                    error,
                    id: candidateProduct.id,
                  }),
                ),
              ),
            );
        } else {
          return of(CandidateProductAction.updateSuccess({ candidateProduct }));
        }
      }),
    ),
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.updateSuccess),
      tap(({ candidateProduct }) => {
        this.applyListProductChangeToFinishSchedule(candidateProduct, 'update');
      }),
      map(() => {
        return { type: 'noAction' };
      }),
    ),
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.remove),
      concatMap(({ id }) => {
        this.projectApiService.onBeginUpdate$.next();
        return this.picklistService.deleteListProduct(id).pipe(
          map((id) => CandidateProductAction.removeSuccess({ id })),
          catchError((error) =>
            of(CandidateProductAction.removeFailure({ error, id })),
          ),
        );
      }),
    ),
  );

  getVariationTotal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.getVariationTotal),
      concatMap(({ candidateProducts }) =>
        this.productService.filteredVariationTotal(candidateProducts).pipe(
          map((filteredVariationTotalCount) =>
            CandidateProductAction.getVariationTotalSuccess({
              candidateProducts,
              filteredVariationTotalCount,
            }),
          ),
          catchError((error) =>
            of(CandidateProductAction.getVariationTotalFailure({ error })),
          ),
        ),
      ),
    ),
  );

  getVariationTotalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidateProductAction.getVariationTotalSuccess),
      concatMap(({ candidateProducts, filteredVariationTotalCount }) => {
        const combined: Update<CandidateProduct>[] = this.productService
          .combineVariationTotalAndCandidate(
            candidateProducts,
            filteredVariationTotalCount,
          )
          .map((v) => {
            return { id: v.id, changes: v };
          });
        return of(
          CandidateProductAction.updateManySuccess({
            candidateProducts: combined,
          }),
        );
      }),
    ),
  );

  private applyListProductChangeToFinishSchedule(
    newCandidateProduct: CandidateProduct,
    mode: 'update' | 'create',
  ) {
    // projectServiceのfinishScheduleにいるpicklistに候補製品の変更を反映させる処理
    if (this.projectService.finishSchedule) {
      const picklists = this.projectService.finishSchedule.picklists;
      this.projectService.finishSchedule.picklists = picklists.map(
        (picklist) => {
          if (newCandidateProduct.list_id === picklist.id) {
            switch (mode) {
              case 'create':
                picklist.listProducts.push(newCandidateProduct);
                break;
              case 'update':
                picklist.listProducts = picklist.listProducts.map(
                  (listProduct) => {
                    if (listProduct.id === newCandidateProduct.id) {
                      return newCandidateProduct;
                    }
                    return listProduct;
                  },
                );
                break;
              default:
                picklist.listProducts.push(newCandidateProduct);
                break;
            }
          }
          return picklist;
        },
      );
    }
  }

  constructor(
    private readonly actions$: Actions,
    private picklistService: PicklistService,
    private productService: ProductService,
    private projectApiService: ProjectAPI,
    private projectService: ProjectService,
  ) {}
}
