import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import {
  AddRoomDialogComponent,
  AddRoomResult,
} from './add-room-dialog.component';

@Injectable()
export class AddRoomDialogService extends MatDialog {
  openDialog(
    groupNameList: string[],
    mode?: 'default' | 'onlyRoom',
  ): MatDialogRef<AddRoomDialogComponent, AddRoomResult> {
    const baseConfig: MatDialogConfig = {
      width: '600px',
    };
    const config = Object.assign({}, baseConfig, {
      data: { groupNameList, mode },
    });

    return this.open(AddRoomDialogComponent, config);
  }
}
