import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Project } from '@shared/models/response/project';
import { Observable } from 'rxjs';

import { FinishScheduleState } from '../../domain/types/states/finish-schedule.state';

@Injectable({
  providedIn: 'root',
})
export class FinishScheduleStore extends ComponentStore<FinishScheduleState> {
  constructor() {
    super({
      finishSchedule: undefined,
      isLoading: true,
    });
  }

  finishSchedule$: Observable<Project | undefined> = this.select(
    (state) => state.finishSchedule,
  );

  isLoading$: Observable<boolean> = this.select((state) => state.isLoading);

  updateState = this.updater((state: FinishScheduleState, value) => {
    return {
      ...state,
      value,
    };
  });
}
