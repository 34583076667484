import { CandidateProduct } from '@shared/models/response/list-product';
import { ListProduct } from 'app/v2/general/domain/types/states/list-product.state';
import { Picklist } from 'app/v2/general/domain/types/states/picklist.state';

import { PicklistViewModel } from '../../view-model/picklist-view-model';

export class PicklistDto {
  public static build(
    picklist: Picklist,
    listProductsFromStore: CandidateProduct[],
  ): PicklistViewModel {
    const filteredListProductsFromStore = listProductsFromStore.filter(
      (product) => product.list_id === picklist.id,
    );

    const listProducts = filteredListProductsFromStore.map(
      (candidateProduct) => {
        const listProduct = Object.fromEntries(
          Object.entries(candidateProduct).filter(
            ([key, value]) => key !== 'product',
          ),
        ) as ListProduct;

        return listProduct;
      },
    );

    const picklistViewModel: PicklistViewModel = {
      ...picklist,
      listProducts,
    };

    return picklistViewModel;
  }
}
