import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JoineryComponent } from '@interfaces/joinery-component';
import { JoineryPicklistSpecification } from '@interfaces/joinery-picklist-specification';
import { Picklist } from '@shared/models/response/picklist';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PicklistApiService {
  constructor(private http: HttpClient) {}

  fetchJoineryPicklists(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
  ) {
    return this.http.get<Picklist[]>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/picklists`,
      {
        params: new HttpParams().set(
          'embeds',
          'joinery_picklist_specifications,list_products',
        ),
      },
    );
  }

  // 建具材料一覧からの親材料追加
  // 建具材料一覧からの子材料追加
  addPicklistToStandardComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    standardComponentId: number,
    newPicklist: Picklist,
    parentPicklist?: Picklist,
  ): Observable<Picklist> {
    return this.http.post<Picklist>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/standards/components/${standardComponentId}/picklists`,
      {
        ...newPicklist,
        enable_similar: true,
      },
    );
  }

  removePicklistFromStandardComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    standardComponentId: number,
    picklistId: number,
  ): Observable<void> {
    return this.http.delete<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/standards/components/${standardComponentId}/picklists/${picklistId}`,
    );
  }

  // 構成要素セルからの子材料追加
  addPicklistToComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    component: JoineryComponent,
    newPicklist: Picklist,
  ) {
    return this.http.post<Picklist>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/joineries/${component.joinery_id}/components/${component.id}/picklists`,
      {
        ...newPicklist,
        enable_similar: true,
      },
    );
  }
  // 材料仕様編集
  updatePicklistSpecification(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    picklistId: number,
    picklistSpecification: JoineryPicklistSpecification,
  ) {
    return this.http.put<void>(
      `/api/projects/${projectId}/${region}/business-group/${businessGroupId}/picklists/${picklistId}/specifications/${picklistSpecification.id}`,
      { ...picklistSpecification },
    );
  }
}
