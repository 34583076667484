import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FeceGalleryItemsParams } from '../models/request/face-gallery-items-params';
import { FaceProductsParams } from '../models/request/face-products-params';
import { FaceSeriesParams } from '../models/request/face-series-params';
import { Feature } from '../models/response/feature';
import { GalleryItem } from '../models/response/gallery-item';
import { Product } from '../models/response/product';
import { Series } from '../models/response/series';
import { UseCase } from '../models/response/use-case';

import { RequestParamsConverterService } from './request-params-converter.service';

@Injectable({
  providedIn: 'root',
})
export class FaceService {
  constructor(
    protected http: HttpClient,
    protected converter: RequestParamsConverterService,
  ) {}

  series(params: FaceSeriesParams = {}): Observable<Series[]> {
    return this.http.get<Series[]>(`/api/face/series`, {
      params: this.converter.convert(params),
    });
  }

  products(params: FaceProductsParams = {}): Observable<Product[]> {
    return this.http.get<Product[]>(`/api/face/products`, {
      params: this.converter.convert(params),
    });
  }

  features(params: FeceGalleryItemsParams = {}): Observable<Feature[]> {
    return this.http.get<Feature[]>(`/api/face/features`, {
      params: this.converter.convert(params),
    });
  }

  useCases(params: FeceGalleryItemsParams = {}): Observable<UseCase[]> {
    return this.http.get<UseCase[]>(`/api/face/use-cases`, {
      params: this.converter.convert(params),
    });
  }

  galleryItems(params: FeceGalleryItemsParams = {}): Observable<GalleryItem[]> {
    return this.http.get<UseCase[]>(`/api/face/gallery-items`, {
      params: this.converter.convert(params),
    });
  }
}
