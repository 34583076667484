import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FeatureKey } from '../../enums/feature-key.enum';
import { picklistAdapter, PicklistState } from '../picklist.state';

const getPicklistState = createFeatureSelector<PicklistState>(
  FeatureKey.Picklist,
);

const { selectAll } = picklistAdapter.getSelectors();

export const getIsFetching = createSelector(
  getPicklistState,
  (state) => state.isFetching,
);

export const getError = createSelector(
  getPicklistState,
  (state) => state.error,
);

export const getPicklists = createSelector(getPicklistState, (state) =>
  selectAll(state),
);

export const getFilteredPicklists = createSelector(
  getPicklistState,
  (state) => {
    const allPicklists = selectAll(state);
    return allPicklists.filter((picklist) => {
      if (!state.filter) return true;
      const searchKey = state.filter?.searchKey;
      const used = state.filter?.used;
      const standard = state.filter?.standard;
      let keyAccept = true;
      let usedAccept = true;
      let standardAccept = true;
      if (searchKey) {
        keyAccept = picklist.name.indexOf(searchKey) !== -1;
      }
      if (used) {
        // 未使用材料を排除（子材料がない親材料も非表示）
        usedAccept =
          (picklist.joinery_component_ids?.length ?? 0) > 0 ||
          (!!picklist.is_parent &&
            allPicklists
              .filter((p) => (p.joinery_component_ids?.length ?? 0) > 0)
              .flatMap((p) => p.group_id ?? [])
              .includes(picklist.id));
      }
      if (standard) {
        // 標準材料を排除
        standardAccept = !picklist.is_standard;
      }
      return keyAccept && usedAccept && standardAccept;
    });
  },
);

export const getParentPicklists = createSelector(getPicklistState, (state) =>
  selectAll(state).filter((picklist) => picklist.is_parent),
);

export const getChildPicklists = createSelector(getPicklistState, (state) =>
  selectAll(state).filter((picklist) => !picklist.is_parent),
);
