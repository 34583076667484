import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { ProjectPageService } from '../../../general/project/project-page.service';
import { Picklist } from '../../models/response/picklist';
import { PicklistType } from '../../models/response/sub/picklist-type';

import { AddPicklistDialogComponent } from './add-picklist-dialog.component';

export interface AddPicklistResult {
  picklist: Picklist;
  copyInfo?: {
    needProduct: boolean;
    needPaste: boolean;
  };
}

export interface AddPicklistInput {
  caption: string;
  type: PicklistType;
  projectPage: ProjectPageService;
  copyFrom?: Picklist;
  copyTo?: string;
  approvedRoom?: string;
}

@Injectable()
export class AddPicklistDialogService extends MatDialog {
  openDialog(
    input: AddPicklistInput,
  ): MatDialogRef<AddPicklistDialogComponent, AddPicklistResult> {
    const baseConfig = {
      minWidth: 800,
    };
    const config = Object.assign({}, baseConfig, { data: input });

    return this.open(AddPicklistDialogComponent, config);
  }
}
