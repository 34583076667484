import { createReducer, on } from '@ngrx/store';

import { symbolWithNumberAction } from './actions';
import {
  initialSymbolWithNumberState,
  symbolWithNumberAdapter,
} from './symbol-with-number.state';

export const symbolWithNumberReducer = createReducer(
  initialSymbolWithNumberState,
  on(symbolWithNumberAction.fetchAll, (state) => {
    return symbolWithNumberAdapter.removeAll({ ...state, isFetching: true });
  }),
  on(symbolWithNumberAction.fetchAllSuccess, (state, { symbolWithNumbers }) => {
    return symbolWithNumberAdapter.setAll(symbolWithNumbers, {
      ...state,
      isFetching: false,
    });
  }),
  on(symbolWithNumberAction.fetchAllFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(symbolWithNumberAction.set, (state, { symbolWithNumber }) => {
    return symbolWithNumberAdapter.upsertOne(symbolWithNumber, state);
  }),
  on(symbolWithNumberAction.setsByEffect, (state, { symbolWithNumbers }) => {
    return symbolWithNumberAdapter.upsertMany(symbolWithNumbers, state);
  }),
  on(symbolWithNumberAction.removeByEffect, (state, { joineryId }) => {
    return symbolWithNumberAdapter.removeOne(joineryId, state);
  }),
);
