import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserService } from '../../shared/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuard {
  constructor(private user: UserService) {}

  canActivate() {
    this.user.checkLoginStatus();

    return this.user.isAdmin.pipe(
      tap((isAdmin) => {
        if (!isAdmin) {
          const redirect = encodeURIComponent(location.href);
          location.href = `/user/login?r=${redirect}`;
        }
      }),
    );
  }

  canLoad() {
    // complete()されるObservableじゃないとだめっぽい
    return new Observable<boolean>((observer) => {
      this.canActivate().subscribe((canActivate) => {
        observer.next(canActivate);
        observer.complete();
      });
    });
  }
}
