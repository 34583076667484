import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

type Size = 'large' | 'medium' | 'small' | 'mini';

@Component({
  selector: 'gn-spinner',
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class SpinnerComponent implements OnChanges {
  private changeDetector = inject(ChangeDetectorRef);

  @Input()
  size: Size = 'medium';

  diameter = 30;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.size) {
      this.diameter = this.getDiameter(this.size);
      this.changeDetector.markForCheck();
    }
  }

  private getDiameter(size: Size) {
    switch (size) {
      case 'large':
        return 100;
      case 'medium':
        return 50;
      case 'small':
        return 25;
      case 'mini':
        return 15;
      default:
        return 50;
    }
  }
}
