import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

export const BASE_TITLE = 'truss (トラス) - メーカー横断の建材検索サイト';

export const TITLE_SEPARATOR = '|';

export const setUpTitle = (
  router: Router,
  activatedRoute: ActivatedRoute,
  titleService: Title,
) => {
  return router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map((_) => {
        let route = activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      map((route: ActivatedRoute) => {
        const titles = route.pathFromRoot
          .reverse()
          .map((path) => titleFormData(path.snapshot.data))
          .filter((v) => !!v);
        titles.push(BASE_TITLE);
        return titles.join(` ${TITLE_SEPARATOR} `);
      }),
    )
    .subscribe(titleService.setTitle.bind(titleService));
};

const titleFormData = (data: Data): string => {
  const path: any = data.titlePath || 'title';

  const keys = path.split('.');
  let result = data;

  for (const key of keys) {
    if (result && key in result) {
      result = result[key];
    } else {
      return '';
    }
  }

  return result + '';
};
