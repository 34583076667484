import { Joinery } from '@interfaces/joinery';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { JoineryAction } from './actions';
import { initialJoineryState, JoineryAdapter } from './joinery.state';

export const joineryReducer = createReducer(
  initialJoineryState,
  on(JoineryAction.fetch, (state) => {
    return JoineryAdapter.removeAll({ ...state, isFetching: true });
  }),
  on(JoineryAction.fetchSuccess, (state, { joineryPagination }) => {
    if (joineryPagination && joineryPagination.data) {
      return JoineryAdapter.setAll(joineryPagination.data, {
        ...state,
        isFetching: false,
        links: joineryPagination.links,
        meta: joineryPagination.meta,
      });
    } else {
      return { ...state, isFetching: false };
    }
  }),
  on(JoineryAction.fetchFailure, (state, { error }) => ({
    ...state,
    isFetching: false,
    error,
  })),
  on(JoineryAction.updateSpec, (state) => {
    return { ...state };
  }),
  on(JoineryAction.updateSpecSuccess, (state, { joinery }) => {
    return JoineryAdapter.updateOne(
      {
        id: joinery.id,
        changes: { ...joinery },
      },
      {
        ...state,
        processingIds: [
          ...state.processingIds.filter((_id) => _id !== joinery.id),
        ],
      },
    );
  }),
  on(JoineryAction.updateSpecFailure, (state, { error }) => ({
    ...state,
    error,
    idFetching: false,
  })),
  on(JoineryAction.updateSpecMany, (state) => {
    return { ...state };
  }),
  on(JoineryAction.updateSpecManySuccess, (state, { joineries }) => {
    return JoineryAdapter.updateMany(
      joineries.map((joinery) => {
        return {
          id: joinery.id,
          changes: { ...joinery },
        };
      }),
      state,
    );
  }),
  on(JoineryAction.updateSpecManyFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(JoineryAction.update, (state, { joinery }) => {
    return { ...state, processingIds: [...state.processingIds, joinery.id] };
  }),
  on(JoineryAction.updateSuccess, (state, { joinery }) => {
    return JoineryAdapter.updateOne(
      {
        id: joinery.id,
        changes: { ...joinery },
      },
      {
        ...state,
        processingIds: [
          ...state.processingIds.filter((_id) => _id !== joinery.id),
        ],
      },
    );
  }),
  on(JoineryAction.updateFailure, (state, { error }) => ({
    ...state,
    error,
    idFetching: false,
  })),
  on(JoineryAction.duplicateAndEditRooms, (state, { joinery }) => {
    return { ...state };
  }),
  on(JoineryAction.duplicateAndEditRoomsSuccess, (state, { joinery }) => {
    return JoineryAdapter.addOne(joinery, { ...state });
  }),
  on(JoineryAction.duplicateAndEditRoomsFailure, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(JoineryAction.duplicateMultiWindowAndEditRooms, (state, { joineries }) => {
    return { ...state };
  }),
  on(
    JoineryAction.duplicateMultiWindowAndEditRoomsSuccess,
    (state, { joineries }) => {
      return JoineryAdapter.addMany(joineries, { ...state });
    },
  ),
  on(
    JoineryAction.duplicateMultiWindowAndEditRoomsFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      };
    },
  ),
  on(JoineryAction.remove, (state, { joineryId }) => {
    return {
      ...state,
      processingIds: [...state.processingIds, joineryId],
    };
  }),
  on(JoineryAction.removeSuccess, (state, { joineryId }) => {
    return JoineryAdapter.removeOne(joineryId, {
      ...state,
      processingIds: [...state.processingIds.filter((id) => id !== joineryId)],
    });
  }),
  on(JoineryAction.removeFailure, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(JoineryAction.copySpecManySuccess, (state, { joineries }) => {
    const changes: Update<Joinery>[] = joineries.map((joinery) => {
      const change: Update<Joinery> = {
        id: joinery.id,
        changes: joinery,
      };
      return change;
    });
    return JoineryAdapter.updateMany(changes, state);
  }),
  on(JoineryAction.copySpecManyFailure, (state, { error }) => {
    return { ...state, error };
  }),
  on(JoineryAction.incrementMeta, (state, {}) => {
    const metaData = { ...state.meta };
    return {
      ...state,
      meta: {
        ...state.meta!,
        per_page: metaData.per_page! + 1,
        total: metaData.total! + 1,
      },
    };
  }),
  on(JoineryAction.decrementMeta, (state, {}) => {
    const metaData = { ...state.meta };
    return {
      ...state,
      meta: {
        ...state.meta!,
        per_page: metaData.per_page! - 1,
        total: metaData.total! - 1,
      },
    };
  }),
  on(JoineryAction.addMany, (state, { joineries }) => {
    return { ...state };
  }),
  on(JoineryAction.addManySuccess, (state, { joineries }) => {
    return JoineryAdapter.addMany(joineries, { ...state });
  }),
  on(JoineryAction.addManyFailure, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(JoineryAction.incrementMetaMany, (state, { incrementCount }) => {
    const metaData = { ...state.meta };
    return {
      ...state,
      meta: {
        ...state.meta!,
        per_page: metaData.per_page! + incrementCount,
        total: metaData.total! + incrementCount,
      },
    };
  }),
);
