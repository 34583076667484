import { SymbolWithNumber } from '@interfaces/symbol-with-number';
import { createAction, props } from '@ngrx/store';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';

const pageActionPrefix = '[SymbolWithNumber Page]';
const apiActionPrefix = '[SymbolWithNumber API]';
const effectActionPrefix = '[SymbolWithNumber Effect]';

export const fetchAll = createAction(
  pageActionPrefix + 'Fetch All',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
  }>(),
);

export const fetchAllSuccess = createAction(
  apiActionPrefix + 'Fetch All Success',
  props<{ symbolWithNumbers: SymbolWithNumber[] }>(),
);

export const fetchAllFailure = createAction(
  apiActionPrefix + 'Fetch All Failure',
  props<{ error: unknown }>(),
);

export const set = createAction(
  effectActionPrefix + 'Set',
  props<{ symbolWithNumber: SymbolWithNumber }>(),
);

export const setsByEffect = createAction(
  effectActionPrefix + 'SetsByEffect',
  props<{ symbolWithNumbers: SymbolWithNumber[] }>(),
);

export const removeByEffect = createAction(
  effectActionPrefix + 'RemoveByEffect',
  props<{ joineryId: number }>(),
);
