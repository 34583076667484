import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { CandidateProductStoreModule } from './candidate-product/candidate-product-store.module';
import { JoineryComponentStoreModule } from './joinery-component/joinery-component-store.module';
import { JoineryOptionSettingStoreModule } from './joinery-option-setting/joinery-option-setting-store.module';
import { JoineryPageInfoStoreModule } from './joinery-page-info/joinery-page-info-store.module';
import { JoineryStoreModule } from './joinery/joinery-store.module';
import { PicklistStoreModule } from './picklist/picklist-store.module';
import { RoomToRoomStoreModule } from './room-to-room/room-to-room-store.module';
import { SymbolWithNumberStoreModule } from './symbol-with-number/symbol-with-number-store.module';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      autoPause: true,
      connectInZone: true,
    }),
    EffectsModule.forRoot([]),
    CandidateProductStoreModule,
    JoineryPageInfoStoreModule,
    PicklistStoreModule,
    JoineryStoreModule,
    JoineryComponentStoreModule,
    SymbolWithNumberStoreModule,
    RoomToRoomStoreModule,
    JoineryOptionSettingStoreModule,
  ],
})
export class AppStoreModule {}
