import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of, switchMap } from 'rxjs';

import { Group } from '../../domain/enums/group.enum';
import { User } from '../../domain/types/states/user.state';
import { UserGroup } from '../../domain/types/user-group';

type UserState = {
  user: User | undefined;
  isLoading: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class UserStore extends ComponentStore<UserState> {
  constructor() {
    super({
      user: undefined,
      isLoading: false,
    });
  }

  readonly user$: Observable<User | undefined> = this.select(
    (state) => state.user,
  );
  readonly userId$ = this.select((state) => state.user?.id);
  readonly isLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  );
  readonly userGroups$: Observable<UserGroup[] | undefined> = this.user$.pipe(
    switchMap((user) => {
      return user ? of(user.groups) : of(undefined);
    }),
  );
  readonly isTrussUser$: Observable<boolean> = this.user$.pipe(
    switchMap((user) => {
      if (user && user.groups) {
        const showGroup: Group[] = [Group.Truss];
        return of(user.groups.some((g) => showGroup.includes(g.group)));
      } else {
        return of(false);
      }
    }),
  );

  updateUser = this.updater((state: UserState, user: User) => {
    return {
      ...state,
      user,
    };
  });
}
