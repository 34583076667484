import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import {
  MiscellaneousWorkPicklist,
  MiscellaneousWorkPicklistStore,
} from '../../../../application/store/miscellaneous-work-picklist.store';
import { MiscellaneousWorkPicklistViewModel } from '../../../../application/view-model/miscellaneous-work-picklist-view-model';

@Injectable({
  providedIn: 'root',
})
export class GetMiscellaneousWorkPicklistsViewModelQuery {
  private store = inject(MiscellaneousWorkPicklistStore);

  getViewModelList(): Observable<MiscellaneousWorkPicklistViewModel[]> {
    return this.store.miscellaneousWorkPicklists$.pipe(
      map((picklists) => picklists.map(this.transformToViewModel)),
    );
  }

  private transformToViewModel(
    input: MiscellaneousWorkPicklist,
  ): MiscellaneousWorkPicklistViewModel {
    return {
      ...input,
      listProducts: [],
    };
  }
}
