import { Injectable } from '@angular/core';
import {
  JoineryComponent,
  JoineryComponentWithStandard,
} from '@interfaces/joinery-component';
import { JoineryStandardComponent } from '@interfaces/joinery-standard-component';
import { SymbolWithNumber } from '@interfaces/symbol-with-number';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JoineryApiService } from 'app/services/api/joinery-api.service';
import { JoineryPageApiService } from 'app/services/api/joinery-page-api.service';
import { JoineryService } from 'app/services/joinery.service';
import {
  switchMap,
  combineLatest,
  map,
  catchError,
  of,
  tap,
  from,
  take,
} from 'rxjs';

import { joineryComponentAction } from '../joinery-component/actions';
import { JoineryComponentFacade } from '../joinery-component/joinery-component.facade';
import { JoineryPageInfoStoreFacade } from '../joinery-page-info/joinery-page-info-store.facade';
import { RoomToRoomFacade } from '../room-to-room/room-to-room.facade';
import { SymbolWithNumberFacade } from '../symbol-with-number/symbol-with-number.facade';

import { JoineryAction } from './actions';
import { JoineryFacade } from './joinery.facade';

@Injectable()
export class JoineryEffects {
  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoineryAction.fetch),
      switchMap(({ projectId, region, businessGroupId, page, searchParam }) => {
        const pagination$ = this.joineryPageApi.fetchPage(
          projectId,
          region,
          businessGroupId,
          page,
          searchParam,
        );
        const pageInfo$ = this.joineryPageInfoFacade.joineryPageInfo$.pipe(
          take(1),
        );
        return combineLatest([pagination$, pageInfo$])
          .pipe(
            map(([pagination, pageInfo]) => {
              if (pageInfo) {
                const mergedComponents = this.mergeStandardComponents(
                  pagination.components,
                  pageInfo.standard_components,
                );

                const mergedPagination = {
                  ...pagination,
                  components: mergedComponents,
                };
                return mergedPagination;
              } else {
                return null;
              }
            }),
          )
          .pipe(
            tap((pagination) => {
              if (pagination && pagination.components) {
                this.store.dispatch(
                  joineryComponentAction.setAll({
                    components: pagination.components,
                  }),
                );
              }
            }),
            map((pagination) =>
              JoineryAction.fetchSuccess({
                joineryPagination: pagination?.joineries,
              }),
            ),
            catchError((error) => of(JoineryAction.fetchFailure({ error }))),
          );
      }),
    ),
  );

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JoineryAction.update),
      switchMap(({ projectId, region, businessGroupId, joinery }) => {
        return this.joineryApi
          .updateJoinery(projectId, region, businessGroupId, joinery)
          .pipe(
            map(() => {
              const symbolWithNumber: SymbolWithNumber = {
                id: joinery.id,
                symbol: joinery.symbol,
                number: joinery.number,
                symbol_with_number:
                  (joinery.symbol ?? '') + (joinery.number ?? ''),
                order: joinery.order,
              };
              this.symbolWithNumberFacade.set(symbolWithNumber);
              return JoineryAction.updateSuccess({ joinery });
            }),
            catchError((error) => of(JoineryAction.updateFailure({ error }))),
          );
      }),
    );
  });

  updateSpec$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JoineryAction.updateSpec),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          joinery,
          symbolSpecification,
        }) => {
          return this.joineryApi
            .updateSymbolSpecification(
              projectId,
              region,
              businessGroupId,
              symbolSpecification,
            )
            .pipe(
              map(() => {
                const newSpecs = joinery.symbol_specifications.map((s) => {
                  if (s.id === symbolSpecification.id) {
                    return symbolSpecification;
                  } else {
                    return s;
                  }
                });

                return JoineryAction.updateSpecSuccess({
                  joinery: {
                    ...joinery,
                    symbol_specifications: newSpecs,
                  },
                });
              }),
            );
        },
      ),
    );
  });

  updateSpecMany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JoineryAction.updateSpecMany),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          joineries,
          symbolSpecification,
        }) => {
          return this.joineryService
            .updateSpecMany(
              projectId,
              region,
              businessGroupId,
              joineries,
              symbolSpecification,
            )
            .pipe(
              map((updatedJoineries) => {
                return JoineryAction.updateSpecManySuccess({
                  joineries: updatedJoineries,
                });
              }),
              catchError((error) => {
                return of(JoineryAction.updateSpecManyFailure({ error }));
              }),
            );
        },
      ),
    );
  });

  duplicateAndEditRooms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JoineryAction.duplicateAndEditRooms),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          joinery,
          targetRooms,
          isDeleteCopySource,
        }) => {
          const duplicateResult$ = from(
            this.joineryService.duplicate(
              projectId,
              region,
              businessGroupId,
              joinery,
            ),
          );
          const pageInfo$ = this.joineryPageInfoFacade.joineryPageInfo$.pipe(
            take(1),
          );
          return combineLatest([duplicateResult$, pageInfo$]).pipe(
            map(([result, pageInfo]) => {
              const newJoinery = result.joinery;
              if (isDeleteCopySource) {
                this.joineryFacade.remove(
                  projectId,
                  region,
                  businessGroupId,
                  joinery.id,
                );
              }
              // 構成要素の更新
              if (pageInfo) {
                const mergedComponents = this.mergeStandardComponents(
                  result.components,
                  pageInfo.standard_components,
                );
                if (mergedComponents)
                  this.joineryComponentFacade.setsByEffect(mergedComponents);
              }
              // 使用場所の更新
              const newRooms = targetRooms.map((room) => {
                return { ...room, joinery_id: newJoinery.id };
              });
              this.roomToRoomFacade.updateMany(
                projectId,
                region,
                businessGroupId,
                newRooms,
              );
              // 建具符号一覧の更新
              const symbolWithNumber: SymbolWithNumber = {
                id: newJoinery.id,
                symbol: newJoinery.symbol,
                number: newJoinery.number,
                symbol_with_number:
                  (newJoinery.symbol ?? '') + (newJoinery.number ?? ''),
                order: newJoinery.order,
              };
              this.symbolWithNumberFacade.set(symbolWithNumber);
              this.joineryFacade.incrementMeta();
              return JoineryAction.duplicateAndEditRoomsSuccess({
                joinery: newJoinery,
              });
            }),
            catchError((error) => {
              return of(JoineryAction.duplicateAndEditRoomsFailure({ error }));
            }),
          );
        },
      ),
    );
  });

  duplicateMultiWindowAndEditRooms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JoineryAction.duplicateMultiWindowAndEditRooms),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          joineries,
          components,
          targetRooms,
        }) => {
          const duplicateResult$ = this.joineryApi.addMultiWindowJoinery(
            projectId,
            region,
            businessGroupId,
            joineries,
            components,
          );
          const pageInfo$ = this.joineryPageInfoFacade.joineryPageInfo$.pipe(
            take(1),
          );
          return combineLatest([duplicateResult$, pageInfo$]).pipe(
            map(([result, pageInfo]) => {
              const newJoineries = result.joineries;
              const parentJoinery = newJoineries.find(
                (j) => j.multi_set_window_ids.length > 0,
              );
              // 構成要素の更新
              if (pageInfo) {
                const mergedComponents = this.mergeStandardComponents(
                  result.components,
                  pageInfo.standard_components,
                );
                if (mergedComponents)
                  this.joineryComponentFacade.setsByEffect(mergedComponents);
              }
              // 使用場所の更新
              if (parentJoinery) {
                const newRooms = targetRooms.map((room) => {
                  return { ...room, joinery_id: parentJoinery.id };
                });
                this.roomToRoomFacade.updateMany(
                  projectId,
                  region,
                  businessGroupId,
                  newRooms,
                );
              }

              if (components?.length > 0) {
                this.joineryComponentFacade;
              }
              // 建具符号一覧の更新
              const symbolWithNumbers: SymbolWithNumber[] = newJoineries.map(
                (j) => ({
                  id: j.id,
                  symbol: j.symbol,
                  number: j.number,
                  symbol_with_number: (j.symbol ?? '') + (j.number ?? ''),
                  order: j.order,
                }),
              );
              this.symbolWithNumberFacade.sets(symbolWithNumbers);
              this.joineryFacade.incrementMeta();
              return JoineryAction.duplicateMultiWindowAndEditRoomsSuccess({
                joineries: newJoineries,
              });
            }),
            catchError((error) => {
              return of(JoineryAction.duplicateAndEditRoomsFailure({ error }));
            }),
          );
        },
      ),
    );
  });

  remove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JoineryAction.remove),
      switchMap(({ projectId, region, businessGroupId, joineryId }) => {
        return this.joineryApi
          .remove(projectId, region, businessGroupId, joineryId)
          .pipe(
            map(() => {
              this.symbolWithNumberFacade.removeByEffect(joineryId);
              this.joineryFacade.decrementMeta();
              return JoineryAction.removeSuccess({ joineryId });
            }),
            catchError((error) => {
              return of(JoineryAction.removeFailure({ error }));
            }),
          );
      }),
    );
  });

  copySpecMany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JoineryAction.copySpecMany),
      switchMap(
        ({
          projectId,
          region,
          businessGroupId,
          copySourceJoinery,
          pasteTargetJoineries,
          copySourceConfigId,
        }) => {
          return this.joineryService
            .pasteSpecs(
              projectId,
              region,
              businessGroupId,
              copySourceJoinery,
              copySourceConfigId,
              pasteTargetJoineries,
            )
            .pipe(
              map((joineries) =>
                JoineryAction.copySpecManySuccess({ joineries }),
              ),
              catchError((error) =>
                of(JoineryAction.copySpecManyFailure({ error })),
              ),
            );
        },
      ),
    );
  });

  addMany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JoineryAction.addMany),
      switchMap(({ projectId, region, businessGroupId, joineries }) => {
        const addManyResult$ = this.joineryApi.addManyJoinery(
          projectId,
          region,
          businessGroupId,
          joineries,
        );

        const pageInfo$ = this.joineryPageInfoFacade.joineryPageInfo$.pipe(
          take(1),
        );
        return combineLatest([addManyResult$, pageInfo$]).pipe(
          map(([result, pageInfo]) => {
            const newJoineries = result.joineries;
            const newComponents = result.components;
            const incrementCount = newJoineries.length;

            // 構成要素の更新
            if (pageInfo) {
              const mergedComponents = this.mergeStandardComponents(
                newComponents,
                pageInfo.standard_components,
              );
              if (mergedComponents)
                this.joineryComponentFacade.setsByEffect(mergedComponents);
            }

            // 建具符号一覧の更新
            const symbolWithNumbers: SymbolWithNumber[] = newJoineries.map(
              (j) => ({
                id: j.id,
                symbol: j.symbol,
                number: j.number,
                symbol_with_number: (j.symbol ?? '') + (j.number ?? ''),
                order: j.order,
              }),
            );
            this.symbolWithNumberFacade.sets(symbolWithNumbers);
            //複数追加の場合は追加個数分メタデータ加算の必要あり
            this.joineryFacade.incrementMetaMany(incrementCount);
            return JoineryAction.addManySuccess({ joineries: newJoineries });
          }),
          catchError((error) => {
            return of(JoineryAction.addManyFailure({ error }));
          }),
        );
      }),
    );
  });

  mergeStandardComponents(
    components: JoineryComponent[],
    standardComponents: JoineryStandardComponent[],
  ): JoineryComponentWithStandard[] | undefined {
    const mergedComponents = components?.map((c) => {
      const standardComponent = standardComponents.find(
        (s) => s.id === c.joinery_standard_component_id,
      )!;

      const mergedComponent: JoineryComponentWithStandard = {
        ...c,
        standardComponent,
      };

      return mergedComponent;
    });
    return mergedComponents;
  }

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private joineryService: JoineryService,
    private joineryApi: JoineryApiService,
    private joineryPageApi: JoineryPageApiService,
    private joineryPageInfoFacade: JoineryPageInfoStoreFacade,
    private symbolWithNumberFacade: SymbolWithNumberFacade,
    private roomToRoomFacade: RoomToRoomFacade,
    private joineryComponentFacade: JoineryComponentFacade,
    private joineryFacade: JoineryFacade,
  ) {}
}
