import { createReducer, on } from '@ngrx/store';

import { CandidateProductAction } from '../actions';
import {
  candidateProductAdapter,
  initialCandidateProductState,
} from '../states';

export const candidateProductReducer = createReducer(
  initialCandidateProductState,
  on(CandidateProductAction.fetchAll, (state) => {
    candidateProductAdapter.removeAll({ ...state });
    return { ...state, isFetching: true };
  }),
  on(CandidateProductAction.fetchAllSuccess, (state, { candidateProducts }) =>
    candidateProductAdapter.setAll(candidateProducts, {
      ...state,
      isFetching: false,
    }),
  ),
  on(CandidateProductAction.fetchAllFailure, (state, { error }) => ({
    ...state,
    isFetching: false,
    error,
  })),
  on(CandidateProductAction.fetch, (state, { id }) => ({
    ...state,
    isFetching: true,
    selectedId: id,
  })),
  on(CandidateProductAction.fetchSuccess, (state, { candidateProduct }) =>
    candidateProductAdapter.upsertOne(candidateProduct, {
      ...state,
      isFetching: false,
    }),
  ),
  on(CandidateProductAction.fetchFailure, (state, { error }) => ({
    ...state,
    isFetching: false,
    error,
  })),
  on(CandidateProductAction.create, (state) => ({ ...state, isAdding: true })),
  on(CandidateProductAction.createSuccess, (state, { candidateProduct }) =>
    candidateProductAdapter.addOne(candidateProduct, {
      ...state,
    }),
  ),
  on(CandidateProductAction.createFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(CandidateProductAction.createMany, (state) => ({
    ...state,
    isAdding: true,
  })),
  on(CandidateProductAction.createManySuccess, (state, { candidateProducts }) =>
    candidateProductAdapter.addMany(candidateProducts, {
      ...state,
    }),
  ),
  on(CandidateProductAction.createManyFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(CandidateProductAction.update, (state) => ({
    ...state,
  })),
  on(CandidateProductAction.updateSuccess, (state, { candidateProduct }) => {
    return candidateProductAdapter.updateOne(
      {
        id: candidateProduct.id,
        changes: { ...candidateProduct },
      },
      {
        ...state,
        processingIds: [
          ...state.processingIds.filter((_id) => _id !== candidateProduct.id),
        ],
      },
    );
  }),
  on(
    CandidateProductAction.updateManySuccess,
    (state, { candidateProducts }) => {
      return candidateProductAdapter.updateMany(candidateProducts, state);
    },
  ),
  on(CandidateProductAction.updateFailure, (state, { error, id }) => ({
    ...state,
    error,
    isFetching: false,
    processingIds: [...state.processingIds.filter((_id) => _id !== id)],
  })),
  on(CandidateProductAction.remove, (state, { id }) => ({
    ...state,
    processingIds: [...state.processingIds, id],
  })),
  on(CandidateProductAction.removeSuccess, (state, { id }) =>
    candidateProductAdapter.removeOne(id, {
      ...state,
      processingIds: [...state.processingIds.filter((_id) => _id !== id)],
    }),
  ),
  on(CandidateProductAction.removeFailure, (state, { error, id }) => ({
    ...state,
    error,
    processingIds: [...state.processingIds.filter((_id) => _id !== id)],
  })),
  on(CandidateProductAction.setFilter, (state, { filter }) => ({
    ...state,
    filter,
  })),
  on(CandidateProductAction.removeFilter, (state) => ({
    ...state,
    filter: {},
  })),
  on(CandidateProductAction.addProcessingId, (state, { id }) => ({
    ...state,
    processingIds: [...state.processingIds, id],
  })),
  on(CandidateProductAction.getVariationTotalFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);
