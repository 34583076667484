import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

import { Room } from '../../models/response/room';

@Component({
  selector: 'ls-manage-bim-room-info-dialog',
  templateUrl: './manage-bim-room-info-dialog.component.html',
  styleUrls: ['./manage-bim-room-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ManageBimRoomInfoDialogComponent implements OnInit {
  get rooms() {
    return this.data.rooms;
  }

  selected: Room;

  constructor(
    private dialogRef: MatDialogRef<ManageBimRoomInfoDialogComponent, Room>,
    @Inject(MAT_DIALOG_DATA) readonly data: { rooms: Room[] },
  ) {}

  ngOnInit() {
    this.selected = this.rooms[0]!;
  }

  cancel() {
    this.dialogRef.close();
  }

  okClick() {
    this.dialogRef.close(this.selected);
  }
}
