import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserService } from '../shared/service/user.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private user: UserService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const modifiedReq = req.clone({
      withCredentials: !req.url.startsWith(
        'https://postcode.teraren.com/postcodes',
      ),
    });

    const response = next.handle(modifiedReq);

    return response.pipe(
      switchMap((rtn) => {
        if (rtn.type == HttpEventType.Response) {
          this.user.checkLoginStatus();
        }
        return of(rtn);
      }),
    );
  }
}
