export enum ConstructionClassification {
  ConstructionClassificationFloorGround = 'construction_classification_floor_ground',
  ConstructionClassificationFloorFinish = 'construction_classification_floor_finish',
  ConstructionClassificationBaseboard = 'construction_classification_baseboard',
  ConstructionClassificationSpandrelWallGround = 'construction_classification_spandrel_wall_ground',
  ConstructionClassificationSpandrelWallFinish = 'construction_classification_spandrel_wall_finish',
  ConstructionClassificationWallGround = 'construction_classification_wall_ground',
  ConstructionClassificationWallFinish = 'construction_classification_wall_finish',
  ConstructionClassificationPartingEdge = 'construction_classification_parting_edge',
  ConstructionClassificationCeilingGround = 'construction_classification_ceiling_ground',
  ConstructionClassificationCeilingFinish = 'construction_classification_ceiling_finish',
}

export enum ShowColumn {
  SmokeExhaustingSystem = 'smoke_exhausting_system',
  CorrespondenceGrounds = 'correspondence_grounds',
  RestrictionGrounds = 'restriction_grounds',
  InteriorRestriction = 'interior_restriction',
  SpaceType = 'space_type',
  FallingCeiling = 'falling_ceiling',
  ConstructionClassificationFloorGround = 'construction_classification_floor_ground',
  ConstructionClassificationFloorFinish = 'construction_classification_floor_finish',
  ConstructionClassificationBaseboard = 'construction_classification_baseboard',
  ConstructionClassificationSpandrelWallGround = 'construction_classification_spandrel_wall_ground',
  ConstructionClassificationSpandrelWallFinish = 'construction_classification_spandrel_wall_finish',
  ConstructionClassificationWallGround = 'construction_classification_wall_ground',
  ConstructionClassificationWallFinish = 'construction_classification_wall_finish',
  ConstructionClassificationPartingEdge = 'construction_classification_parting_edge',
  ConstructionClassificationCeilingGround = 'construction_classification_ceiling_ground',
  ConstructionClassificationCeilingFinish = 'construction_classification_ceiling_finish',
}

export const getShowColumnLabel = (showColumn: ShowColumn | string) => {
  switch (showColumn) {
    case ShowColumn.SmokeExhaustingSystem:
      return '排煙';
    case ShowColumn.InteriorRestriction:
      return '内装制限';
    case ShowColumn.RestrictionGrounds:
      return '制限根拠';
    case ShowColumn.CorrespondenceGrounds:
      return '対応根拠';
    case ShowColumn.SpaceType:
      return '室・居室';
    case ShowColumn.FallingCeiling:
      return '下り天井';
    case ShowColumn.ConstructionClassificationFloorGround:
      return '床下地';
    case ShowColumn.ConstructionClassificationFloorFinish:
      return '床仕上';
    case ShowColumn.ConstructionClassificationBaseboard:
      return '巾木';
    case ShowColumn.ConstructionClassificationSpandrelWallGround:
      return '腰壁下地';
    case ShowColumn.ConstructionClassificationSpandrelWallFinish:
      return '腰壁仕上';
    case ShowColumn.ConstructionClassificationWallGround:
      return '壁下地';
    case ShowColumn.ConstructionClassificationWallFinish:
      return '壁仕上';
    case ShowColumn.ConstructionClassificationPartingEdge:
      return '見切縁';
    case ShowColumn.ConstructionClassificationCeilingGround:
      return '天井下地';
    case ShowColumn.ConstructionClassificationCeilingFinish:
      return '天井仕上';
    default:
      return '';
  }
};

export const getShowColumnFromNameInfo = (nameInfo: string) => {
  const keys: string[] = [];
  Object.values(ShowColumn).forEach((key) => {
    if (getShowColumnLabel(key).match(new RegExp(`^${nameInfo}.*$`)))
      keys.push(key);
  });
  return keys;
};

export const SmokeExhaustingDictionary: { [key: string]: string } = {
  自然排煙等で対応: '自然排煙等',
  機械排煙等で対応: '機械排煙等',
  免除: '免除',
  避難出口で対応: '避難出口',
  消火設備で対応: '消火設備',
  区画等で対応: '区画等',
  規制対象外: '規制対象外',
};

interface CorrespondenceGrounds {
  select: string;
  info: {
    grounds: string;
    url: string;
  }[];
}

export const CorrespondenceGrounds: CorrespondenceGrounds[] = [
  {
    select: '自然排煙等',
    info: [
      {
        grounds: '令126条の3 (第8)',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_126_3',
      },
      {
        grounds: '告示1436 第4イ',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
    ],
  },
  {
    select: '機械排煙等',
    info: [
      {
        grounds: '令126条の3 (第8)',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_126_3',
      },
      {
        grounds: '告示1436 第1',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
      {
        grounds: '告示1436 第2',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
    ],
  },
  {
    select: '免除',
    info: [
      {
        grounds: '告示1436 第3',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
      {
        grounds: '令126条の2 1項かっこ書',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_126_2',
      },
      {
        grounds: '令126条の2 1項1号',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_126_2',
      },
      {
        grounds: '令126条の2 1項2号',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_126_2',
      },
      {
        grounds: '令126条の2 1項3号',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_126_2',
      },
      {
        grounds: '令126条の2 1項4号',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_126_2',
      },
      {
        grounds: '階避難安全検証法',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_129',
      },
      {
        grounds: '全館避難安全検証法',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_129_2',
      },
      {
        grounds: '耐火性能検証法',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006487.pdf',
      },
      {
        grounds: '防火区画検証法',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_128_6',
      },
      {
        grounds: '国土交通大臣認可',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_126_2',
      },
    ],
  },
  {
    select: '避難出口',
    info: [
      {
        grounds: '告示1436 第4ロ',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
    ],
  },
  {
    select: '消火設備',
    info: [
      {
        grounds: '告示1436 第4ハ',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
    ],
  },
  {
    select: '区画等',
    info: [
      {
        grounds: '告示1436 第4二(1)',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
      {
        grounds: '告示1436 第4二(2)',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
      {
        grounds: '告示1436 第4二(3)',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
      {
        grounds: '告示1436 第4二(4)',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
      {
        grounds: '告示1436 第4ホ',
        url: 'https://www.mlit.go.jp/notice/noticedata/pdf/201703/00006488.pdf',
      },
    ],
  },
  {
    select: '規制対象外',
    info: [
      {
        grounds: '令126条の2',
        url: 'https://elaws.e-gov.go.jp/document?lawid=325CO0000000338#Mp-At_126_2',
      },
    ],
  },
];
