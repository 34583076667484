<mat-dialog-content>
  <h3>BIM部屋紐付け</h3>
  <div class="contents">
    <div class="left">
      <div class="menu">
        <span
          >未分類のBIM部屋を該当の仕上表部屋にドラッグ＆ドロップで分類してください。</span
        >
      </div>
      <div class="header">
        <div class="floor-name"></div>
        <div class="room-name">
          <button mat-raised-button (click)="addRoom()">
            <fa-icon [icon]="['fas', 'plus-square']" before></fa-icon>
            行を追加する
          </button>
        </div>
        <div class="grouped-rooms">分類済みのBIM部屋</div>
      </div>
      <div class="scroll-area">
        <div
          class="floor-container"
          *ngFor="let floor of finishSchedule.floor_groups"
        >
          <div class="floor-name">{{ floor.name }}</div>
          <div class="rooms-container">
            <div class="room-container" *ngFor="let group of floor.room_groups">
              <div class="room-name">{{ group.name }}</div>
              <div
                class="grouped-rooms"
                cdkDropList
                [cdkDropListData]="group"
                (cdkDropListDropped)="setRoomToGroup($event)"
                [cdkDropListConnectedTo]="dropLists"
              >
                <div
                  *ngFor="let room of getGroupedRooms(group.id)"
                  cdkDrag
                  [cdkDragStartDelay]="getDelayed()"
                  [cdkDragData]="room"
                >
                  <div
                    [ngTemplateOutlet]="groupedRoom"
                    [ngTemplateOutletContext]="{ room: room }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer">
      <div class="menu"></div>
      <div class="header">
        <fa-icon [icon]="['fas', 'arrow-left']" before></fa-icon>
      </div>
      <div class="scroll-area"></div>
    </div>
    <div class="right">
      <div class="menu">
        <div>絞り込み</div>
        <table>
          <tr>
            <th>FL</th>
            <td>
              <mat-select
                [(ngModel)]="flIs"
                (selectionChange)="updateNotGroupedRooms()"
              >
                <mat-option [value]="false">-- FLを選択 --</mat-option>
                <mat-option *ngFor="let fl of fls" [value]="fl">{{
                  fl
                }}</mat-option>
              </mat-select>
            </td>
          </tr>
          <tr>
            <th>部屋名</th>
            <td>
              <input
                type="text"
                [(ngModel)]="nameLike"
                placeholder="部屋名を入力(部分一致)"
                (input)="updateNotGroupedRooms()"
                (compositionend)="updateNotGroupedRooms()"
              />
            </td>
          </tr>
        </table>
      </div>
      <div class="header">
        <div class="not-grouped-room">
          <mat-checkbox
            name="select-all"
            [(ngModel)]="selectAll"
            (change)="onChangeSelectAll()"
          ></mat-checkbox>
          <div>未分類のBIM部屋</div>
        </div>
      </div>
      <div class="scroll-area">
        <div class="no-target" *ngIf="notGroupedRooms.length === 0">
          未分類のBIM部屋はありません
        </div>
        <div
          class="not-grouped-room-container"
          cdkDropList
          [cdkDropListConnectedTo]="dropLists"
          [cdkDropListEnterPredicate]="disableDrop"
          (cdkDropListDropped)="cancelDrag($event)"
        >
          <ng-container *ngFor="let room of notGroupedRooms">
            <div
              class="not-grouped-room"
              *ngIf="targetRooms.indexOf(room) < 0 || room === currentRoom"
              cdkDrag
              [cdkDragData]="room"
            >
              <mat-checkbox
                name="room-{{ room.id }}"
                [(ngModel)]="selectedRooms[room.id.toString()]"
                (change)="selectAll = isSelectedAll"
                >{{ room.level }}&nbsp;{{ room.name }}</mat-checkbox
              >
              <div *cdkDragPreview [matBadge]="badgeCount">
                <div
                  [ngTemplateOutlet]="groupedRoom"
                  [ngTemplateOutletContext]="{ room: room }"
                ></div>
              </div>
              <div
                *cdkDragPlaceholder
                [class.hide]="hidePlaceholder"
                [matBadge]="badgeCount"
              >
                <div
                  [ngTemplateOutlet]="groupedRoom"
                  [ngTemplateOutletContext]="{ room: room }"
                ></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<ng-template #groupedRoom let-room="room">
  <div class="grouped-room">
    <mat-icon (click)="saveRoomInGroup(room, undefined)">cancel</mat-icon>
    {{ room.level }}&nbsp;{{ room.name }}
  </div>
</ng-template>
