import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(protected http: HttpClient) {}

  filePath(name: string) {
    return `/api/images/file/${name}`;
  }
}
