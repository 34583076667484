import {
  Component,
  Input,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
  NgZone,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { fromEvent, Observable } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';

import { ProjectService } from '../../../../service/project.service';

export interface InputValue {
  name: string;
  id?: number;
}

@Component({
  selector: 'ls-interior-select-new-value',
  templateUrl: './interior-select-new-value.component.html',
  styleUrls: ['./interior-select-new-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InteriorSelectNewValueComponent,
      multi: true,
    },
  ],
})
export class InteriorSelectNewValueComponent
  implements ControlValueAccessor, AfterViewInit, OnInit
{
  @ViewChild('autocompleteTrigger', { read: MatAutocompleteTrigger })
  autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('auto') autoComplete: MatAutocomplete;
  @Input() placeholder = '';
  values: InputValue[] = [];

  @Output() isTyped = new EventEmitter<string>();

  private htmlInput: HTMLInputElement;
  private inputValue: InputValue[];

  private scrollValue = 0;

  filteredStandardSets$: Observable<InputValue[]>;
  filteredStandardSets = this.project.interiorSetsByBuildingUse.map(
    (standardSet) => {
      return {
        name: standardSet.room_group_name,
        id: standardSet.room_group_id,
      };
    },
  );

  standardSetTooltips: {
    [key: number]: string;
  } = this.project.interiorSetsByBuildingUse.reduce(
    (collection, standardSet) => {
      const values: string[] = [];

      values.push(
        `${standardSet.building_use} / ${standardSet.room_group_name}`,
      );

      const maxLength = standardSet.room_group_info.reduce((max, info) => {
        return Math.max(max, info.parts_name.length);
      }, -Infinity);

      this.project.interiorCategories.forEach((c) => {
        const name = c.name + (c.type || '');

        const info = standardSet.room_group_info.find((info) => {
          return info.parts_name == name;
        });

        if (info) {
          const picklistNames = info.picklists
            .map((picklistId) => {
              return (
                this.project.parentPicklists.find((_picklist) => {
                  return _picklist.id == picklistId;
                })?.name || null
              );
            })
            .filter((name) => {
              return !!name;
            })
            .join(' / ');

          if (picklistNames) {
            values.push(`${name.padEnd(maxLength)}：${picklistNames}`);
          }
        }
      });

      if (values.length > 0) {
        collection[standardSet.room_group_id] = values.join('\r\n');
      }

      return collection;
    },
    {} as { [key: number]: string },
  );

  nameControl = new UntypedFormControl();

  constructor(
    private elementRef: ElementRef,
    private project: ProjectService,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
  ) {}

  ngOnInit(): void {
    this.filteredStandardSets$ = this.nameControl.valueChanges.pipe(
      startWith(''),
      map((value: string | InputValue) => {
        // enterを押すと改行コードが入力値に含まれてしまうので削除している
        const name =
          typeof value === 'string' ? value.replace(/\r?\n/g, '') : value.name;
        if (name !== '') {
          this.closeOptions();
        }
        return this.filteredStandardSets.filter((standardSet) =>
          standardSet.name?.toLowerCase().includes(name),
        );
      }),
    );
  }

  ngAfterViewInit() {
    this.htmlInput = this.elementRef.nativeElement.querySelector('textarea');
    this.changeDetectorRef.detectChanges();
  }

  get value(): InputValue[] {
    return this.inputValue;
  }

  set value(value: InputValue[]) {
    if (value === this.inputValue) {
      return;
    }
    this.writeValue(value);
  }

  public selected(event: MatAutocompleteSelectedEvent) {
    const standardSet = event.option.value as InputValue;
    this.setValue(standardSet);
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      this.openOptions();
    });
  }

  public handleInput(event: Event | KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 0) {
      this.isTyped.emit(target.value);
    }

    if (
      event.type === 'keydown' &&
      (event as KeyboardEvent).code === 'Enter' &&
      target.value &&
      target.value.trim() !== ''
    ) {
      this.setValue({ name: target.value, id: 0 });
    }

    if (
      event.type === 'keydown' &&
      (event as KeyboardEvent).code === 'Backspace' &&
      this.values.length > 0 &&
      !this.nameControl.value
    ) {
      this.removeValue(this.value[this.value.length - 1]!);
    }

    if (event.type === 'paste') {
      setTimeout(() => {
        target.value.split(/\r\n|\n/).forEach((v) => {
          if (v && v.trim() !== '') {
            this.setValue({ name: v, id: 0 });
          }
        });
      }, 100);
    }
  }

  private setValue(value: InputValue) {
    this.value = this.values.concat(value);
    this.htmlInput.value = '';
    this.htmlInput.focus();
    setTimeout(() => {
      this.nameControl.setValue('', { emitEvent: true });
    });
  }

  removeValue(value: InputValue) {
    const index = this.values.findIndex((v) => {
      return v.name == value.name;
    });
    if (index !== -1) {
      if (index === this.values.length - 1) {
        this.value = this.values.slice(0, -1);
      } else {
        this.value = this.values
          .slice(0, index)
          .concat(this.values.slice(index + 1));
      }
      this.htmlInput.focus();
    }
  }

  writeValue(value: InputValue[]): void {
    this.inputValue = value;
    this.elementRef.nativeElement.value = value;

    this.values = value || [];

    this.onChange(value);
  }

  onChange = (_: InputValue[]) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: InputValue[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  openOptions() {
    this.nameControl.setValue('', { emitEvent: true });
    this.isTyped.emit(this.nameControl.value);
    this.autocompleteTrigger.openPanel();
    this.autocompleteTrigger.updatePosition();
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      if (this.autoComplete?.panel?.nativeElement) {
        this.autoComplete._setScrollTop(this.scrollValue);
        fromEvent(
          this.autoComplete.panel.nativeElement as HTMLElement,
          'scroll',
        )
          .pipe(takeUntil(this.autoComplete.closed))
          .subscribe((event) => {
            this.scrollValue = (event.target as Element).scrollTop;
          });
      }
    });
  }

  closeOptions() {
    this.autocompleteTrigger?.closePanel();
  }

  getTypedValue(event: any) {
    if (event.data || !this.nameControl.value) {
      this.isTyped.emit(event.data ?? '');
    }
  }
}
