import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

export type ButtonSize =
  | 'huge'
  | 'x-large'
  | 'large'
  | 'medium'
  | 'default'
  | 'small'
  | 'x-small';

@Component({
  selector: 'ls-button, a[ls-button], button[ls-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ButtonComponent {
  @Input()
  @HostBinding('attr.theme')
  theme: 'default' | 'primary' | 'accent' | 'warn' | 'pink' = 'default';

  @Input()
  @HostBinding('attr.size')
  size: ButtonSize = 'default';

  @Input()
  @HostBinding('class.bordered')
  border = false;

  @Input()
  @HostBinding('class.block')
  block = false;

  constructor() {}
}
