@if (userIsLoading$ | async) {
  <div class="cover">
    <div class="app-spinner-wrap">
      <mat-spinner color="" [diameter]="30" strokeWidth="1"></mat-spinner>
    </div>

    <div class="bottom">
      <div class="logo">
        <gn-logo></gn-logo>
      </div>
    </div>
  </div>
} @else {
  <router-outlet></router-outlet>
}
