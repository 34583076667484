import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export type ProjectSampleRequestCountState = {
  projectSampleProductCount: number;
};

@Injectable({
  providedIn: 'root',
})
export class ProjectSampleRequestCountStore extends ComponentStore<ProjectSampleRequestCountState> {
  constructor() {
    super({
      projectSampleProductCount: 0,
    });
  }

  projectSampleProductCount$: Observable<number> = this.select((state) => {
    return state.projectSampleProductCount;
  });

  updateProjectSampleProductCount = this.updater(
    (
      state: ProjectSampleRequestCountState,
      projectSampleProductCount: number,
    ) => {
      return {
        ...state,
        projectSampleProductCount,
      };
    },
  );
}
