import {
  CdkDragDrop,
  DragStartDelay,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ImportBimResult } from '@shared/api/project.service';
import { ManageBimRoomDialogComponent } from '@shared/dialog/manage-bim-room-dialog/manage-bim-room-dialog.component';
import { MessageDialogComponent } from '@shared/dialog/message-dialog/message-dialog.component';
import { ListProduct } from '@shared/models/response/list-product';
import { PicklistService } from '@shared/service/picklist.service';
import { CandidateProductFacade } from 'app/store/candidate-product/facades/candidate-product.facade';
import { ReflectPicklistInInteriorFinishScheduleService } from 'app/v2/general/core/reflect-picklist-in-interior-finish-schedule.service';
import {
  checkProhibitedCharacters,
  checkProhibitedSpace,
} from 'app/v2/general/domain/logics/picklist-logic';
import { DuplicateProjectResult } from 'app/v2/general/domain/types/duplicate-project-result';
import { SearchPicklistCategoryDialogComponent } from 'app/v2/general/shared/views/dialogs/search-picklist-category-dialog/search-picklist-category-dialog.component';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  of,
  Subject,
  throwError,
  zip,
} from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';

import { ApiService } from '../../shared/api/api.service';
import { AddExteriorSetDialogService } from '../../shared/dialog/add-exterior-set-dialog/add-exterior-set-dialog.service';
import { AddOutdoorPicklistDialogService } from '../../shared/dialog/add-outdoor-picklist-dialog/add-outdoor-picklist-dialog.service';
import {
  AddPicklistDialogService,
  AddPicklistResult,
} from '../../shared/dialog/add-picklist-dialog/add-picklist-dialog.service';
import { AddRoomDialogService } from '../../shared/dialog/add-room-dialog/add-room-dialog.service';
import { AlertDialogService } from '../../shared/dialog/alert-dialog/alert-dialog.service';
import { ListLinkCheckDialogService } from '../../shared/dialog/list-link-check-dialog/list-link-check-dialog.service';
import { List } from '../../shared/models/list';
import { ExteriorCategory } from '../../shared/models/response/exterior-category';
import {
  ExteriorPicklistInfo,
  ExteriorSet,
} from '../../shared/models/response/exterior-set';
import { FavListCopyStartEvent } from '../../shared/models/response/favlist-event';
import { ReloadFavTriggerEvent } from '../../shared/models/response/favlist-event';
import { FavButtonChangeStartEvent } from '../../shared/models/response/favlist-event';
import { FloorGroup } from '../../shared/models/response/floor-group';
import { InteriorCategory } from '../../shared/models/response/interior-category';
import { InteriorSet } from '../../shared/models/response/interior-set';
import {
  InteriorPicklistInfo,
  InteriorSetParts,
} from '../../shared/models/response/interior-set-parts';
import { Picklist } from '../../shared/models/response/picklist';
import { ProductCache } from '../../shared/models/response/product-cache';
import { Project } from '../../shared/models/response/project';
import { Room, RoomInfoKeys } from '../../shared/models/response/room';
import { RoomBlock } from '../../shared/models/response/room-block';
import { RoomGroup } from '../../shared/models/response/room-group';
import { PicklistType } from '../../shared/models/response/sub/picklist-type';
import { ProjectType } from '../../shared/models/response/sub/project-type';
import { ShowColumn } from '../../shared/models/response/sub/show-column';
import { ProjectService } from '../../shared/service/project.service';
import { UserService } from '../../shared/service/user.service';
import { PicklistStore } from '../../v2/general/application/store/picklist.store';

@Injectable({
  providedIn: 'root',
})
export class ProjectPageService {
  readonly COLUMN_STORAGE_KEY = 'column_storage_key';
  private picklistStore = inject(PicklistStore);
  private reflectPicklistService = inject(
    ReflectPicklistInInteriorFinishScheduleService,
  );
  private dialog = inject(MatDialog);

  get finishSchedule() {
    return this.project.finishSchedule;
  }

  set finishSchedule(value: Project | undefined) {
    this.project.finishSchedule = value;
  }

  get onChangeDecidedProductInfo$() {
    return this.project.onChangeDecidedProductInfo$;
  }

  get onChangePicklist$() {
    return this.project.onChangePicklist$;
  }

  get isSorted(): boolean {
    return this._isSorted;
  }
  set isSorted(sort: boolean) {
    this._isSorted = sort;
  }

  _isDescSorted = false;

  get isDescSorted(): boolean {
    return this._isDescSorted;
  }

  set isDescSorted(sort: boolean) {
    this._isDescSorted = sort;
  }

  get currentPicklist() {
    return this.project.currentPicklist;
  }
  set currentPicklist(value: Picklist | undefined) {
    this.project.currentPicklist = value;
  }

  _currentPicklist?: Picklist;
  _isSorted: boolean;

  onSaveParts$ = new Subject<{
    interiorSet: InteriorSet;
    parts: InteriorSetParts;
    onEnd: Subject<InteriorSetParts>;
  }>();

  onChangeRoom$ = new Subject<void>();
  onChangeExteriorSet$ = new Subject<void>();
  onChangePicklistDetailMode$ = new Subject<void>();

  _favToggleEvent: Subject<
    FavButtonChangeStartEvent | FavListCopyStartEvent | ReloadFavTriggerEvent
  >;

  get favToggleEvent() {
    return this.project.favToggleEvent;
  }

  set favToggleEvent(
    _favToggleEvent: Subject<
      FavButtonChangeStartEvent | FavListCopyStartEvent | ReloadFavTriggerEvent
    >,
  ) {
    this.project.favToggleEvent = _favToggleEvent;
  }

  lastAttachedDragMove?: MouseEvent;
  lastAttachedDragScroll?: {
    x: number;
    y: number;
  };
  scrollContainer?: HTMLElement;

  get isPicklistDetailMode() {
    return this._isPicklistDetailMode;
  }
  set isPicklistDetailMode(value) {
    this.onChangePicklistDetailMode$.next();
    this._isPicklistDetailMode = value;
  }
  _isPicklistDetailMode = false;

  private sideNavOpenedDataSource = new BehaviorSubject<boolean>(true);
  readonly sideNavOpened = this.sideNavOpenedDataSource.asObservable();
  get isSideNavOpened() {
    return this.sideNavOpenedDataSource.value;
  }

  private displayTypeDataSource = new BehaviorSubject<string>('');
  readonly displayType = this.displayTypeDataSource.asObservable();

  get projectId() {
    return this.project.projectId;
  }

  get currentDisplayType() {
    return this.displayTypeDataSource.value;
  }

  get floorGroups() {
    if (!this.finishSchedule) return [];

    return this.finishSchedule!.floor_groups!;
  }

  get interiroSets() {
    if (!this.finishSchedule) return [];

    return this.finishSchedule!.interior_sets!;
  }

  get picklists() {
    return this.project.picklists;
  }

  get interiorCategories() {
    if (!this.finishSchedule) return [];

    return this.finishSchedule!.interior_categories!;
  }

  get exteriorCategories() {
    if (!this.finishSchedule) return [];

    return this.finishSchedule!.exterior_categories!;
  }

  get isiPad(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return (
      (ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1) &&
      'ontouchend' in document
    );
  }

  getDelayed(): DragStartDelay {
    return { touch: 500, mouse: 100 };
  }

  get isShow() {
    const isVisibleItem = this.finishSchedule!.visible_items;

    const isShow: List<boolean> = (() => {
      const _isShow: List<boolean> = {};

      Object.values(ShowColumn).forEach((key) => {
        _isShow[key] = false;
      });

      return _isShow;
    })();

    isShow['construction_classification_status'] =
      isVisibleItem &&
      isVisibleItem!.room_groups['construction_classification_status']
        ? isVisibleItem!.room_groups['construction_classification_status']
        : false;

    if (this.project.isBrowseMode) {
      return isVisibleItem ? isVisibleItem.room_groups : isShow;
    } else {
      const storage = localStorage.getItem(this.COLUMN_STORAGE_KEY);

      if (isVisibleItem && storage) {
        const parse = JSON.parse(storage);

        Object.keys(parse).forEach((key) => {
          if (
            isVisibleItem!.room_groups[key] == true ||
            (this.isConstructionClassificationOrNot(key) &&
              isVisibleItem!.room_groups[
                'construction_classification_status'
              ] == true)
          ) {
            if (parse[key]) isShow[key] = true;
          }
        });
      }
      return isShow;
    }
  }

  set isShow(isShow: List<boolean>) {
    localStorage.setItem(this.COLUMN_STORAGE_KEY, JSON.stringify(isShow));
  }

  get isShowInLocalStorage() {
    const storage = localStorage.getItem(this.COLUMN_STORAGE_KEY);
    if (storage) {
      return JSON.parse(storage);
    }

    return {
      smoke_exhausting_system: false,
      interior_restriction: false,
      restriction_grounds: false,
      correspondence_grounds: false,
      space_type: false,
    };
  }

  get updatedProductInfoAt() {
    return this.project.updatedProductInfoAt;
  }

  set updatedProductInfoAt(updatedAt: Date | undefined) {
    this.project.updatedProductInfoAt = updatedAt;
  }

  constructor(
    private api: ApiService,
    private addRoomDialog: AddRoomDialogService,
    private addExteriorSetDialog: AddExteriorSetDialogService,
    private addOutdoorPicklistDialog: AddOutdoorPicklistDialogService,
    private addPiclistDialog: AddPicklistDialogService,
    private alertDialog: AlertDialogService,
    private listLinkCheckDialogService: ListLinkCheckDialogService,
    private user: UserService,
    private project: ProjectService,
    private router: Router,
    private candidateProductFacade: CandidateProductFacade,
    private picklistService: PicklistService,
  ) {
    this.onSaveParts$
      .pipe(
        //        distinctUntilChanged((p, c) => JSON.stringify(p) === JSON.stringify(c)),
        tap((source) => {
          source.interiorSet.parts[source.parts.interior_category_id] =
            source.parts;
        }),
        concatMap((source) =>
          zip(of(source), this.api.project.saveParts(this.projectId, source)),
        ),
      )
      .subscribe(([source, result]) => {
        source.parts.id = result.parts.id;
        source.parts.interior_set_id = result.parts.interior_set_id;

        source.onEnd.next(source.parts);
        source.onEnd.complete();
      });
  }

  _currentProjectUpdatedAt: string;
  get currentProjectUpdatedAt() {
    return this._currentProjectUpdatedAt;
  }

  set currentProjectUpdatedAt(at: string) {
    this._currentProjectUpdatedAt = at;
  }

  toggleSideNav(opened: boolean, forceStream = false) {
    if (!forceStream && opened === this.sideNavOpenedDataSource.value) {
      return;
    }
    this.sideNavOpenedDataSource.next(opened);
  }

  setDisplayType(type: string) {
    this.displayTypeDataSource.next(type);
  }

  sortFinishScheuleOrder(values: { finish_schedule_order: number }[]): any[] {
    return values.sort((a, b) => {
      return a.finish_schedule_order - b.finish_schedule_order;
    });
  }

  savePicklistOrder(
    event: CdkDragDrop<any>,
    categoryId?: string | number,
    exterior?: boolean,
  ) {
    if (event.currentIndex === event.previousIndex) {
      return;
    }
    const container = event.container.data;
    const name: string = container.name;
    const picklists: Picklist[] = container.picklists;
    const orderKey: string = exterior ? categoryId + '-' + container.id : name;
    const orderPicklistIds = exterior
      ? this.picklistSort(
          picklists,
          orderKey,
          categoryId,
          container.id,
          true,
        ).map((p) => {
          return p.id;
        })
      : this.picklistSort(picklists, orderKey).map((p) => {
          return p.id;
        });
    moveItemInArray(orderPicklistIds, event.previousIndex, event.currentIndex);
    orderPicklistIds.forEach((o: number, i: number) => {
      picklists.forEach((p: Picklist) => {
        if (o === p.id) {
          p.order !== null
            ? (p.order![orderKey] = i)
            : (p.order! = { [orderKey]: i });
        }
      });
    });

    const categoryTarget = {
      key: orderKey,
      picklistIds: orderPicklistIds,
    };
    this.api.project.saveOrder(this.projectId, categoryTarget).subscribe();
  }

  //マルっとusecase
  picklistSort(
    picklists: Picklist[],
    groupName: string,
    categoryId?: string | number,
    setId?: string | number,
    exterior?: boolean,
  ): Picklist[] {
    if (this.isSorted) {
      let sort: Picklist[];
      if (groupName == '外構') {
        if (this.isDescSorted) {
          sort = picklists.slice().sort((a, b) => {
            return b.mark! < a.mark! ? -1 : 1;
          });
        } else {
          sort = picklists.slice().sort((a, b) => (a.mark! < b.mark! ? -1 : 1));
        }
      } else {
        sort = picklists.slice().sort((a, b) => (a.name < b.name ? -1 : 1));
      }

      const groups = (() => {
        if (exterior) {
          return this.exteriorCategories;
        } else if (groupName == '外構') {
          return [];
        }
        return this.project.interiorPicklistGroups!.filter(
          (g) => g.name != '未使用',
        );
      })();

      const orderKey = exterior ? categoryId + '-' + setId : groupName;
      const sortPicklists = {
        key: orderKey!,
        picklistIds: sort.map((s) => s.id),
      };

      if (exterior) {
        groups.forEach((group: any) => {
          group.sets.forEach((set: ExteriorSet) => {
            if (set.name === groupName) {
              sortPicklists.picklistIds.forEach((sid, i) => {
                set.picklists.forEach((p) => {
                  if (sid === p.id) {
                    p.order !== null
                      ? (p.order![orderKey!] = i)
                      : (p.order! = { [orderKey!]: i });
                  }
                });
              });
            }
          });
        });
      } else if (groupName == '外構') {
        picklists.forEach((p: Picklist) => {
          sortPicklists.picklistIds.forEach((sid, i) => {
            if (sid === p.id) {
              p.order !== null
                ? (p.order![orderKey!] = i)
                : (p.order! = { [orderKey!]: i });
            }
          });
        });
      } else {
        groups.forEach((group: any) => {
          if (group.name === orderKey!) {
            sortPicklists.picklistIds.forEach((sid, i) => {
              group.picklists.forEach((p: Picklist) => {
                if (sid === p.id) {
                  p.order !== null
                    ? (p.order![orderKey!] = i)
                    : (p.order! = { [orderKey!]: i });
                }
              });
            });
          }
        });
      }
      this.api.project
        .saveOrder(this.projectId, sortPicklists)
        .subscribe(() => {
          this.isSorted = false;
          this.isDescSorted = false;
        });
      return sort;
    } else {
      picklists = picklists.filter(
        (p, index, self) => self.findIndex((s) => p.id === s.id) === index,
      );
      const name = setId ? categoryId + '-' + setId : groupName;
      return picklists.slice().sort((a, b) => {
        if (groupName === '未使用') {
          return a.id - b.id;
        } else if (a.order === null && b.order === null) {
          return a.id - b.id;
        } else if (
          a.order &&
          b.order &&
          !a.order.hasOwnProperty(name!) &&
          !b.order.hasOwnProperty(name!)
        ) {
          return a.id - b.id;
        } else if (
          a.order &&
          !a.order.hasOwnProperty(name!) &&
          b.order === null
        ) {
          return a.id - b.id;
        } else if (
          a.order === null &&
          b.order &&
          !b.order.hasOwnProperty(name!)
        ) {
          return a.id - b.id;
        } else if (
          a.order === null ||
          (a.order && !a.order!.hasOwnProperty(name!))
        ) {
          return 1;
        } else if (
          b.order === null ||
          (b.order && !b.order!.hasOwnProperty(name!))
        ) {
          return -1;
        } else {
          return a.order![name!] - b.order![name!];
        }
      });
    }
  }

  sortExteriorPicklist(name: string) {
    const allPicklists: Picklist[] = [];
    if (name === '未使用') {
      for (const e of this.project.exteriorPicklistGroups) {
        if (e.name === '未使用') {
          allPicklists.push(...this.picklistSort(e.picklists, '未使用'));
        }
        break;
      }
    }
    this.exteriorCategories.forEach((c) => {
      if (c.name === name) {
        c.sets.forEach((s) => {
          const { id: categoryId } = c as ExteriorCategory & { id: string };
          const { id: setId } = s as ExteriorSet & { id: string };
          allPicklists.push(
            ...this.picklistSort(s.picklists, name, categoryId, setId, true),
          );
        });
      }
    });
    return Array.from(new Set(allPicklists));
  }

  getCategoryFromParts(parts: InteriorSetParts) {
    return this.interiorCategories.find(
      (c) => c.id === parts.interior_category_id,
    )!;
  }

  getFloorFromRoom(room: RoomGroup) {
    return this.floorGroups.find((f) => f.id === room.floor_group_id)!;
  }

  addRoom() {
    const onEnd = new Subject<undefined>();

    this.addRoomDialog
      .openDialog(this.floorGroups.map((f) => f.name))
      .beforeClosed()
      .subscribe((result) => {
        if (!result) return;

        this.project.disableOperation = true;
        this.onChangeRoom$.next();

        let _floorGroup = this.floorGroups.filter(
          (floor) => floor.name === result.floorName,
        )[0];

        const hasFloorGroup = !!_floorGroup;

        if (!hasFloorGroup) {
          _floorGroup = {
            id: 0,
            project_id: 0,
            name: result.floorName,
            finish_schedule_order: this.floorGroups.length + 1,
            room_groups: [],
            created_at: '',
            updated_at: '',
          };
        }
        const floorGroup: FloorGroup = _floorGroup!;

        result.roomGroups.forEach((roomGroup, i) => {
          Object.assign(roomGroup, {
            finish_schedule_order: floorGroup.room_groups.length + 1 + i,
          });
        });

        this.api.project
          .addRoom(this.projectId, {
            floorGroup,
            roomGroups: result.roomGroups,
          })
          .subscribe((results) => {
            floorGroup!.id = results.floorGroup.id;
            floorGroup!.project_id = this.projectId;

            results.roomGroups.forEach((result, i) => {
              result.roomGroup.interiorSet = result.set;
              this.interiroSets.push(result.set);

              if (result.newPicklists.length > 0) {
                result.newPicklists.forEach((picklist) => {
                  this.picklists.push(picklist);
                });
              }

              const picklistFromId = this.picklists.reduce(
                (map, picklist) => ({ ...map, [picklist.id]: picklist }),
                {} as { [key: number]: Picklist },
              );

              for (const parts of Object.values(result.set.parts)) {
                parts.picklists = parts.picklistInfo
                  .sort((a, b) => a.order - b.order)
                  .map((i) => picklistFromId[i.id]);
              }

              floorGroup.room_groups.push(result.roomGroup);

              onEnd.next(undefined);

              if (results.roomGroups.length == i + 1) {
                if (!hasFloorGroup) {
                  this.floorGroups.push(floorGroup);
                }

                this.project.refleshInteriorPicklistGroups();
                this.project.onRefreshPicklistNodes.next();
                this.onChangeRoom$.next();
                this.project.disableOperation = false;
                onEnd.complete();
              }
            });
          });
      });

    return onEnd.asObservable();
  }

  duplicateRoom(roomGroup: RoomGroup) {
    const onEnd = new Subject<undefined>();

    this.project.disableOperation = true;
    this.api.project.duplicateRoomGroup(roomGroup).subscribe((result) => {
      result.roomGroup.interiorSet = result.set;
      result.roomGroup.room_blocks = result.roomBlocks;
      this.interiroSets.push(result.set);

      const picklistFromId = this.picklists.reduce(
        (map, picklist) => ({ ...map, [picklist.id]: picklist }),
        {} as { [key: number]: Picklist },
      );
      for (const parts of Object.values(result.set.parts)) {
        parts.picklists = parts.picklistInfo
          .sort((a, b) => a.order - b.order)
          .map((i) => picklistFromId[i.id]);
      }

      this.floorGroups
        .filter((f) => f.id === result.roomGroup.floor_group_id)
        .forEach((f) => {
          const originalRoomGroup: RoomGroup | undefined = f.room_groups.find(
            (r) => {
              return r.id === roomGroup.id;
            },
          );
          if (originalRoomGroup) {
            f.room_groups.splice(
              f.room_groups.indexOf(originalRoomGroup) + 1,
              0,
              result.roomGroup,
            );
            f.room_groups.forEach((r, i) => (r.finish_schedule_order = i + 1));
          } else {
            f.room_groups.push(result.roomGroup);
          }
        });

      this.project.disableOperation = false;
      this.onChangeRoom$.next();
      onEnd.next(undefined);
      onEnd.complete();
    });
    return onEnd.asObservable();
  }

  duplicateExteriorSet(exteriorSet: ExteriorSet) {
    const onEnd = new Subject<undefined>();

    this.project.disableOperation = true;
    this.api.project.duplicateSet(exteriorSet).subscribe((result) => {
      const picklistFromId = this.picklists.reduce(
        (map, picklist) => ({ ...map, [picklist.id]: picklist }),
        {} as { [key: number]: Picklist },
      );
      result.picklists = result.picklistInfo
        .sort((a, b) => a.order - b.order)
        .map((i) => picklistFromId[i.id]);

      this.exteriorCategories
        .filter((c) => c.id === exteriorSet.exterior_category_id)
        .forEach((c) => {
          c.sets.splice(c.sets.indexOf(exteriorSet) + 1, 0, result);
          c.sets.forEach((s, i) => (s.finish_schedule_order = i + 1));
        });

      this.project.disableOperation = false;
      this.onChangeExteriorSet$.next();
      onEnd.next(undefined);
      onEnd.complete();
    });

    return onEnd.asObservable();
  }

  addExteriorSet() {
    const onEnd = new Subject<undefined>();

    this.addExteriorSetDialog
      .openDialog(this.exteriorCategories.map((f) => f.name))
      .beforeClosed()
      .subscribe((result) => {
        if (!result) return;

        this.project.disableOperation = true;

        let _category = this.exteriorCategories.filter(
          (category) => category.name === result.categoryName,
        )[0];

        const hasCategory = !!_category;

        if (!hasCategory) {
          _category = {
            id: 0,
            project_id: 0,
            name: result.categoryName,
            finish_schedule_order: this.exteriorCategories.length + 1,
            sets: [],
            created_at: '',
            updated_at: '',
          };
        }

        const category: ExteriorCategory = _category!;

        result.sets.forEach((set, i) => {
          Object.assign(set, {
            finish_schedule_order: category.sets.length + 1 + i,
          });
        });

        this.onChangeExteriorSet$.next();

        this.api.project
          .addExteriorSet(this.projectId, {
            category,
            sets: result.sets,
          })
          .subscribe((results) => {
            category.id = results.category.id;
            category.project_id = this.projectId;

            results.sets.forEach((result, i) => {
              if (result.newPicklists.length > 0) {
                result.newPicklists.forEach((picklist) => {
                  this.picklists.push(picklist);
                });
              }

              const picklistFromId = this.picklists.reduce(
                (map, picklist) => ({ ...map, [picklist.id]: picklist }),
                {} as { [key: number]: Picklist },
              );

              result.set.picklists = result.set.picklistInfo
                .sort((a, b) => a.order - b.order)
                .map((i) => picklistFromId[i.id]);

              category.sets.push(result.set);

              if (results.sets.length == i + 1) {
                if (!hasCategory) {
                  this.exteriorCategories.push(category);
                }

                this.project.refleshExteriorPicklistGroups();
                this.project.refleshExteriorBySetsPicklistGroups();
                this.project.onRefreshPicklistNodes.next();
                this.onChangeExteriorSet$.next();
                this.project.disableOperation = false;
                onEnd.complete();
              }
            });
          });
      });

    return onEnd.asObservable();
  }

  addOutdoorPicklist(): Observable<undefined> {
    const onEnd = new Subject<undefined>();

    const picklists: Picklist[] = this.finishSchedule!.picklists.filter(
      (picklist) => picklist.type == PicklistType.Outdoor,
    );

    this.addOutdoorPicklistDialog
      .openDialog(this)
      .beforeClosed()
      .subscribe((result) => {
        if (!result) return;

        this.saveOutdoorPicklist(result.picklist).subscribe((_) => {
          onEnd.next(undefined);
        });
      });
    return onEnd.asObservable();
  }

  //picklist-table-usecase
  saveOutdoorPicklist(
    picklist: Picklist,
    copy_from?: Picklist,
  ): Observable<undefined> {
    const onEnd = new Subject<undefined>();

    this.project
      .savePicklist({
        picklist,
        copy_from,
      })
      .subscribe((result) => {
        onEnd.next(undefined);
        onEnd.complete();
      });

    //いらなくなる
    this.onChangePicklist$.next();

    return onEnd.asObservable();
  }

  saveParts(interiorSet: InteriorSet, parts: InteriorSetParts) {
    const onEnd = new Subject<InteriorSetParts>();
    this.onSaveParts$.next({ interiorSet, parts, onEnd });

    return onEnd.asObservable();
  }

  addInteriorPicklist(target?: {
    room: RoomGroup;
    parts: InteriorSetParts;
    block?: RoomBlock;
  }) {
    const onEnd = new Subject<undefined>();

    let caption = '新規の材料を作成します。';
    if (target) {
      const floorGroup = this.getFloorFromRoom(target.room);
      const interiorCategory = this.getCategoryFromParts(target.parts);
      caption = `新規の材料を作成し、${floorGroup.name} ${target.room.name} ${
        interiorCategory.name
      }${interiorCategory.type || ''}に貼り付けます。`;
    }

    this.addPiclistDialog
      .openDialog({
        caption,
        type: PicklistType.Interior,
        projectPage: this,
        approvedRoom:
          target && target.room.is_approved ? target.room.name : undefined,
      })
      .afterClosed()
      .subscribe((input) => {
        if (!input) return;

        this.saveInteriorPicklist(
          input.picklist,
          target
            ? Object.assign({ order: target.parts.picklists.length }, target)
            : undefined,
        ).subscribe((_) => {
          onEnd.next(undefined);
        });
      });

    return onEnd.asObservable();
  }

  addExteriorPicklist(target?: {
    category: ExteriorCategory;
    set: ExteriorSet;
  }) {
    const onEnd = new Subject<undefined>();

    let caption = '新規の材料を作成します。';
    if (target) {
      caption = `新規の材料を作成し、${target.category.name} ${target.set.name}に貼り付けます。`;
    }

    this.addPiclistDialog
      .openDialog({
        caption,
        type: PicklistType.Exterior,
        projectPage: this,
        approvedRoom:
          target && target.set.is_approved ? target.set.name : undefined,
      })
      .afterClosed()
      .subscribe((input) => {
        if (!input) return;

        this.saveExteriorPicklist(
          input.picklist,
          target
            ? Object.assign({ order: target.set.picklists.length }, target)
            : undefined,
        ).subscribe((_) => {
          onEnd.next(undefined);
        });
      });

    return onEnd.asObservable();
  }

  duplicatePicklist(from: Picklist): Observable<{
    done: boolean;
    picklist?: Picklist;
  }> {
    const picklist = from;
    switch (picklist.type) {
      case PicklistType.Outdoor:
        return this.duplicateOutdoorPicklist(picklist);
      case PicklistType.Interior:
        return this.duplicateInteriorPicklist(picklist);
      case PicklistType.Exterior:
        return this.duplicateExteriorPicklist(picklist);
      default:
        return of({ done: false });
    }
  }

  duplicateOutdoorPicklist(from: Picklist) {
    const onEnd = new Subject<{ done: boolean; picklist?: Picklist }>();

    const caption = `${from.name}を複製して新しい材料を作成します。`;
    const type = from.type;
    const picklists = this.picklists.filter(
      (picklist) => picklist.type == PicklistType.Outdoor,
    );

    this.addOutdoorPicklistDialog
      .openDialog(this, from, true)
      .beforeClosed()
      .subscribe((input) => {
        if (!input) {
          onEnd.next({ done: false });
          return;
        }

        const observer = new Observable<undefined>();
        input.picklist.order = { 外構: picklists.length };
        this.saveOutdoorPicklist(input.picklist, from).subscribe((_) => {
          if (input.copyInfo!.needProduct) {
            this.api.project
              .copyProductInfo(this.projectId, from, input.picklist)
              .subscribe((result) => {
                const listProducts = result.listProducts;
                if (listProducts?.length !== 0) {
                  this.candidateProductFacade.createMany(listProducts);
                }
                onEnd.next({ done: true, picklist: input.picklist });
              });
          } else {
            onEnd.next({ done: true, picklist: input.picklist });
          }
        });
      });
    return onEnd.asObservable();
  }

  duplicateInteriorPicklist(
    from: Picklist,
    target?: { room: RoomGroup; parts: InteriorSetParts },
  ) {
    const onEnd = new Subject<{ done: boolean; picklist?: Picklist }>();

    const caption =
      from.type == PicklistType.Interior
        ? `新しい「${from.name}」のバリエーションを作成します。`
        : `${from.name}を複製して新しい材料を作成します。`;
    const type = from.type;

    let copyTo: string | undefined;
    if (target) {
      const floorGroup = this.getFloorFromRoom(target.room);
      const interiorCategory = this.getCategoryFromParts(target.parts);
      copyTo = `${floorGroup.name} ${target.room.name} ${
        interiorCategory.name
      }${interiorCategory.type || ''}`;
    }

    this.addPiclistDialog
      .openDialog({
        caption,
        type,
        projectPage: this,
        copyFrom: from,
        copyTo,
        approvedRoom:
          target && target.room.is_approved ? target.room.name : undefined,
      })
      .afterClosed()
      .subscribe((input) => {
        if (!input) {
          onEnd.next({ done: false });
          return;
        }

        this._duplicateInteriorPicklist(input, from, target).subscribe(
          (result) => {
            onEnd.next(result);
          },
        );
      });

    return onEnd.asObservable();
  }

  _duplicateInteriorPicklist(
    input: AddPicklistResult,
    from: Picklist,
    target?: { room: RoomGroup; parts: InteriorSetParts },
  ): Observable<{ done: boolean; picklist?: Picklist }> {
    const onEnd = new Subject<{ done: boolean; picklist?: Picklist }>();
    this.saveInteriorPicklist(
      input.picklist,
      target && input.copyInfo!.needPaste
        ? Object.assign({ order: target.parts.picklists.length }, target)
        : undefined,
      false,
      from,
    ).subscribe((_) => {
      if (input.copyInfo!.needProduct) {
        this.api.project
          .copyProductInfo(this.projectId, from, input.picklist)
          .subscribe((result) => {
            const listProducts = result.listProducts;
            if (listProducts?.length !== 0) {
              this.candidateProductFacade.createMany(listProducts);
            }
            onEnd.next({ done: true, picklist: input.picklist });
          });
      } else {
        onEnd.next({ done: true, picklist: input.picklist });
      }
    });
    return onEnd.asObservable();
  }

  duplicateExteriorPicklist(
    from: Picklist,
    target?: { category: ExteriorCategory; set: ExteriorSet },
  ) {
    const onEnd = new Subject<{ done: boolean; picklist?: Picklist }>();

    const caption = `${from.name}を複製して新しい材料を作成します。`;
    const type = from.type;

    let copyTo: string | undefined;
    if (target) {
      copyTo = `${target.category.name} ${target.set.name}`;
    }

    this.addPiclistDialog
      .openDialog({
        caption,
        type,
        projectPage: this,
        copyFrom: from,
        copyTo,
        approvedRoom:
          target && target.set.is_approved ? target.set.name : undefined,
      })
      .afterClosed()
      .subscribe((input) => {
        if (!input) {
          onEnd.next({ done: false });
          return;
        }

        const observer = new Observable<undefined>();
        this.saveExteriorPicklist(
          input.picklist,
          target && input.copyInfo!.needPaste
            ? Object.assign({ order: target.set.picklists.length }, target)
            : undefined,
          false,
          from,
        ).subscribe((_) => {
          if (input.copyInfo!.needProduct) {
            this.api.project
              .copyProductInfo(this.projectId, from, input.picklist)
              .subscribe((result) => {
                const listProducts = result.listProducts;
                if (listProducts.length !== 0) {
                  this.candidateProductFacade.createMany(listProducts);
                }
                onEnd.next({ done: true, picklist: input.picklist });
              });
          } else {
            onEnd.next({ done: true, picklist: input.picklist });
          }
        });
      });

    return onEnd.asObservable();
  }

  savePicklist(picklist: Picklist, isManualEdit = false, copy_from?: Picklist) {
    if (picklist.list_link_id) {
      this.listLinkCheckDialogService
        .openDialog(picklist)
        .afterClosed()
        .subscribe((_) => {
          return this.project.savePicklist({
            picklist,
            isManualEdit,
          });
        });
    }
    return this.project.savePicklist({
      picklist,
      isManualEdit,
      copy_from,
    });
  }

  saveInteriorPicklist(
    picklist: Picklist,
    target?: {
      room: RoomGroup;
      parts: InteriorSetParts;
      order: number;
      block?: RoomBlock;
    },
    isMultiplePaste = false,
    copy_from?: Picklist,
  ) {
    const onEnd = new Subject<Picklist>();

    let picklistInfo: InteriorPicklistInfo | undefined;
    if (target) {
      const prevIndex = target.parts.picklists.indexOf(picklist);
      if (prevIndex > -1) {
        target.parts.picklists.splice(prevIndex, 1);
        const to = target.order;
        target.parts.picklists.splice(to, 0, picklist);
        picklistInfo = target.parts.picklistInfo.filter(
          (i) => i.id === picklist.id,
        )[0];
      } else {
        target.parts.picklists.splice(target.order, 0, picklist);
      }

      if (!picklistInfo || isMultiplePaste) {
        picklistInfo = {
          id: picklist.id,
          order: 0,
          group: [],
          added_user_name: this.user.fullName,
          added_user_id: this.user.userId,
        };
        target.parts.picklistInfo.push(picklistInfo);
      }

      target.parts.picklistInfo.forEach((i) => {
        i.order = target.parts.picklists.map((i) => i.id).indexOf(i.id) + 1;
      });
      target.parts.picklists = target.parts.picklistInfo
        .sort((a, b) => {
          return a.order - b.order;
        })
        .reduce((acc: Picklist[], i) => {
          const sortedPicklist = target.parts.picklists.find((p) => {
            return i.id === p!.id;
          });
          acc.push(sortedPicklist!);
          return acc;
        }, []);
    }

    if (target) {
      target.parts.executing = true;
      if (target.block)
        target.block.parts[target.parts.interior_category_id].executing = true;
    }

    (!target || target.parts.id
      ? this.project.savePicklist({
          picklist,
          copy_from,
          parts: target?.parts ? target.parts : undefined,
        })
      : this.saveParts(target.room.interiorSet, target.parts).pipe(
          concatMap((savedParts) =>
            this.project.savePicklist({
              picklist,
              copy_from,
              parts: savedParts,
            }),
          ),
        )
    ).subscribe((result: any) => {
      if (target) {
        target.parts.executing = false;
        if (target.block)
          target.block.parts[target.parts.interior_category_id].executing =
            false;
      }
      if (!!target && !!picklistInfo) {
        picklistInfo.id = result.picklist.id as number;
        const _picklistInfo = result.parts.picklistInfo.find(
          (p: InteriorPicklistInfo) => p.id == result.picklist.id,
        ) as InteriorPicklistInfo;
        picklistInfo.added_at = _picklistInfo.added_at;
        picklistInfo.added_user_name = _picklistInfo.added_user_name;
        picklistInfo.added_user_id = _picklistInfo.added_user_id;
      }

      onEnd.next(result.picklist);
      onEnd.complete();
    });

    this.onChangePicklist$.next();

    return onEnd.asObservable();
  }

  saveRoomBlock(
    roomGroup: RoomGroup,
    roomBlock: RoomBlock[],
    categoryIds: number[],
  ) {
    const onEnd = new Subject<RoomBlock[]>();

    const setParts = this.interiroSets.find(
      (v) => v.id === roomGroup.id,
    )?.parts;
    if (setParts)
      categoryIds.map((id) => {
        if (setParts[id]) {
          setParts[id].executing = true;
        }
      });

    this.api.project
      .saveRoomBlock(this.projectId, roomGroup.id, roomBlock)
      .subscribe((result) => {
        roomGroup.room_blocks = result;
        if (setParts)
          categoryIds.map((id) => {
            if (setParts[id]) {
              setParts[id].executing = false;
            }
          });
        onEnd.next(result);
        onEnd.complete();
      });

    this.onChangePicklist$.next();

    return onEnd.asObservable();
  }

  setMultiplePicklistsToParts(
    picklists: Picklist[],
    target: { room: RoomGroup; category: InteriorCategory }[],
  ) {
    const onEnd = new Subject<undefined>();
    this.api.project
      .setMultiplePicklistsToParts(this.finishSchedule!.id, picklists, target)
      .subscribe(() => {
        onEnd.next(undefined);
        onEnd.complete();
      });
    return onEnd;
  }

  removeMultiplePicklistsFromParts(
    param: {
      picklists: Picklist[];
      target: InteriorSetParts;
    }[],
  ) {
    const onEnd = new Subject<undefined>();
    this.api.project
      .removeMultiplePicklistsFromParts(this.finishSchedule!.id, param)
      .subscribe(() => {
        onEnd.next(undefined);
        onEnd.complete();
      });
    return onEnd;
  }

  saveExteriorPicklist(
    picklist: Picklist,
    target?: { set: ExteriorSet; order: number },
    isMultiplePaste = false,
    copy_from?: Picklist,
  ) {
    const onEnd = new Subject<undefined>();
    let picklistInfo: ExteriorPicklistInfo | undefined;
    if (target) {
      const prevIndex = target.set.picklists.indexOf(picklist);
      if (prevIndex > -1) {
        target.set.picklists.splice(prevIndex, 1);
        const to = target.order;
        target.set.picklists.splice(to, 0, picklist);
        picklistInfo = target.set.picklistInfo.filter(
          (i) => i.id === picklist.id,
        )[0];
      } else {
        target.set.picklists.splice(target.order, 0, picklist);
      }
      if (!picklistInfo || isMultiplePaste) {
        picklistInfo = { id: picklist.id, order: 0 };
        target.set.picklistInfo.push(picklistInfo);
      }
      target.set.picklistInfo.forEach((i) => {
        i.order = target.set.picklists.map((i) => i.id).indexOf(i.id) + 1;
      });
      target.set.picklists = target.set.picklistInfo
        .sort((a, b) => {
          return a.order - b.order;
        })
        .reduce((acc: Picklist[], i) => {
          const sortedPicklist = target.set.picklists.find((p) => {
            return i.id === p!.id;
          });
          acc.push(sortedPicklist!);
          return acc;
        }, []);
    }

    this.project
      .savePicklist({
        picklist,
        set: target ? target.set : undefined,
        copy_from,
      })
      .subscribe((result: any) => {
        if (!!target && !!picklistInfo) {
          picklistInfo.id = result.picklist.id as number;
          const _picklistInfo = result.set.picklistInfo.find(
            (p: ExteriorPicklistInfo) => p.id == result.picklist.id,
          ) as ExteriorPicklistInfo;
          picklistInfo.added_at = _picklistInfo.added_at;
          picklistInfo.added_user_name = _picklistInfo.added_user_name;
          picklistInfo.added_user_id = _picklistInfo.added_user_id;
        }

        onEnd.next(undefined);
        onEnd.complete();
      });

    this.onChangePicklist$.next();

    return onEnd.asObservable();
  }

  sortRoom(event: CdkDragDrop<FloorGroup>) {
    const from = event.previousContainer.data;
    const to = event.container.data;
    const room = event.item.data as RoomGroup;
    if (event.currentIndex === event.previousIndex && from === to) return;

    const fromIndex = from.room_groups.indexOf(room);

    from.room_groups.splice(fromIndex, 1);
    from.room_groups.forEach((r, i) => (r.finish_schedule_order = i + 1));

    to.room_groups.splice(event.currentIndex, 0, room);
    to.room_groups.forEach((r, i) => {
      r.finish_schedule_order = i + 1;
      r.floor_group_id = to.id;
    });

    const targets = from === to ? [] : [from];
    targets.push(to);

    this.api.project
      .sortRoomAndFloor(this.projectId, targets, room.id, false)
      .subscribe();
    this.onChangeRoom$.next();
  }

  sortFloor(
    floor: FloorGroup,
    from: number,
    to: number,
  ): Observable<undefined> {
    this.floorGroups.splice(from, 1);
    this.floorGroups.splice(to, 0, floor);
    this.floorGroups.forEach((f, i) => {
      f.finish_schedule_order = i + 1;
    });

    return this.api.project.sortRoomAndFloor(
      this.projectId,
      this.floorGroups,
      floor.id,
      true,
    );
  }

  sort(floor: FloorGroup, from: number, to: number) {
    this.floorGroups.splice(from, 1);
    this.floorGroups.splice(to, 0, floor);
    this.floorGroups.forEach((f, i) => {
      f.finish_schedule_order = i + 1;
    });
    return this.api.project.sortRoomAndFloor(
      this.projectId,
      this.floorGroups,
      floor.id,
      true,
    );
  }

  sortExteriorSet(event: CdkDragDrop<ExteriorCategory>) {
    const from = event.previousContainer.data;
    const to = event.container.data;
    const set = event.item.data as ExteriorSet;
    if (event.currentIndex === event.previousIndex && from === to) return;

    from.sets.splice(event.previousIndex, 1);
    from.sets.forEach((r, i) => (r.finish_schedule_order = i + 1));

    to.sets.splice(event.currentIndex, 0, set);
    to.sets.forEach((r, i) => {
      r.finish_schedule_order = i + 1;
      r.exterior_category_id = to.id;
    });

    if (from.sets.length === 0) {
      const target = this.exteriorCategories.indexOf(from);
      this.exteriorCategories.splice(target, 1);
      if (this.exteriorCategories.length > target) {
        this._sortExteriorCategory(
          this.exteriorCategories[target],
          target,
          target,
        );
      }
    }

    const targets = from === to ? [] : [from];
    targets.push(to);

    this.api.project
      .sortExteriorCategoryAndSet(this.projectId, targets, set.id, false)
      .subscribe();

    this.project.refleshExteriorBySetsPicklistGroups();
    this.project.onRefreshPicklistNodes.next();
  }

  sortExteriorCategory(event: CdkDragDrop<undefined>) {
    if (event.currentIndex === event.previousIndex) return;
    this._sortExteriorCategory(
      event.item.data as ExteriorCategory,
      event.previousIndex,
      event.currentIndex,
    );
  }
  _sortExteriorCategory(category: ExteriorCategory, from: number, to: number) {
    this.exteriorCategories.splice(from, 1);
    this.exteriorCategories.splice(to, 0, category);
    this.exteriorCategories.forEach((f, i) => {
      f.finish_schedule_order = i + 1;
    });

    this.api.project
      .sortExteriorCategoryAndSet(
        this.projectId,
        this.exteriorCategories,
        category.id,
        true,
      )
      .subscribe();
  }

  saveRoom(roomGroup: RoomGroup) {
    const rooms = this.finishSchedule!.rooms.filter((r) => {
      return r.room_group_id == roomGroup.id;
    });

    rooms.forEach((r) => {
      RoomInfoKeys.forEach((k) => {
        r[k] = roomGroup[k];
      });
      if (roomGroup.construction_classifications) {
        r.construction_classifications = roomGroup.construction_classifications;
      }
    });
    this.api.project.saveRoom(this.projectId, roomGroup).subscribe();
  }

  saveRoomInfo(roomGroup: RoomGroup) {
    const onEnd$ = new Subject<undefined>();
    const rooms = this.finishSchedule!.rooms.filter((r) => {
      return r.room_group_id == roomGroup.id;
    });

    rooms.forEach((r) => {
      RoomInfoKeys.forEach((k) => {
        r[k] = roomGroup[k];
      });
      if (roomGroup.construction_classifications) {
        r.construction_classifications = roomGroup.construction_classifications;
      }
    });
    this.api.project.saveRoom(this.projectId, roomGroup).subscribe(() => {
      onEnd$.next(undefined);
      onEnd$.complete();
    });
    return onEnd$;
  }

  deleteRoom(roomGroup: RoomGroup) {
    const floor = this.getFloorFromRoom(roomGroup);
    floor.room_groups.splice(floor.room_groups.indexOf(roomGroup), 1);
    if (!roomGroup.interiorSet.name) {
      this.interiroSets.splice(
        this.interiroSets.indexOf(roomGroup.interiorSet),
        1,
      );
      this.onChangePicklist$.next();
    }
    this.finishSchedule!.rooms.filter(
      (r) => r.room_group_id == roomGroup.id,
    ).forEach((r) => (r.room_group_id = undefined));
    this.api.project.deleteRoom(this.projectId, roomGroup).subscribe();
  }

  saveExteriorSet(exteriorSet: ExteriorSet): void {
    this.api.project
      .saveExteriorSet(this.projectId, exteriorSet)
      .subscribe((_) => {
        this.project.refleshExteriorBySetsPicklistGroups();
        this.project.onRefreshPicklistNodes.next();
      });
  }

  saveExteriorSets(exteriorSets: ExteriorSet[]) {
    const onEnd$ = new Subject<undefined>();
    this.api.project
      .saveExteriorSets(this.projectId, exteriorSets)
      .subscribe((_) => {
        onEnd$.next(undefined);
      });
    return onEnd$;
  }

  deleteExteriorSet(exteriorSet: ExteriorSet) {
    const category = this.exteriorCategories.find(
      (c) => c.id === exteriorSet.exterior_category_id,
    )!;
    category.sets.splice(category.sets.indexOf(exteriorSet), 1);
    if (category.sets.length === 0) {
      const target = this.exteriorCategories.indexOf(category);
      this.exteriorCategories.splice(target, 1);
      if (this.exteriorCategories.length > target) {
        this._sortExteriorCategory(
          this.exteriorCategories[target],
          target,
          target,
        );
      }
    }
    this.api.project.deleteExteriorSet(this.projectId, exteriorSet).subscribe();
  }

  saveExteriorCategory(category: ExteriorCategory) {
    this.api.project.saveExteriorCategory(this.projectId, category).subscribe();
  }

  deletePicklist(picklist: Picklist) {
    const onEnd$ = new Subject<undefined>();
    const message: string[] = [];
    let approvedRooms: RoomGroup[] | ExteriorSet[] = [];

    if (picklist.type == PicklistType.Interior) {
      approvedRooms = this.floorGroups.reduce((targetRooms, f) => {
        targetRooms = targetRooms.concat(
          f.room_groups.filter((r) => {
            return (
              r.is_approved &&
              Object.values(r.interiorSet.parts).filter(
                (p) => p.picklists.indexOf(picklist) > -1,
              ).length > 0
            );
          }),
        );

        return targetRooms;
      }, [] as RoomGroup[]);
    } else if (picklist.type == PicklistType.Exterior) {
      approvedRooms = this.exteriorCategories.reduce((targetSets, e) => {
        targetSets = targetSets.concat(
          e.sets.filter((s) => {
            return s.is_approved && s.picklists.indexOf(picklist) > -1;
          }),
        );

        return targetSets;
      }, [] as ExteriorSet[]);
    }

    if (approvedRooms.length > 0) {
      message.push(
        `${picklist.name}は施主承認済みの部屋に貼付けられています。`,
      );
    }

    if (this.picklistService.isFixed(picklist)) {
      message.push(`${picklist.name}は確定済みです。`);
    }

    if (message.length > 0) {
      message.push(`本当に${picklist.name}を削除しますか？`);

      this.alertDialog
        .openDialog(message.join('\n'), '削除')
        .afterClosed()
        .subscribe((r) => {
          if (r) {
            this._deletePicklist(picklist);
            onEnd$.next(undefined);
          }
        });
    } else {
      this._deletePicklist(picklist);
      setTimeout(() => {
        onEnd$.next(undefined);
      }, 1);
    }

    return onEnd$;
  }

  _deletePicklist(picklist: Picklist) {
    this.updateDisplayListLink(picklist, true);

    if (this.project.isParentPicklist(picklist)) {
      this.project.parentPicklists.splice(
        this.project.parentPicklists.indexOf(picklist),
        1,
      );
    } else {
      this.picklists.splice(this.picklists.indexOf(picklist), 1);
    }

    this.interiroSets.forEach((s) => {
      Object.values(s.parts).forEach((parts) => {
        let i: number;
        i = parts.picklists.indexOf(picklist);
        if (i > -1) parts.picklists.splice(i, 1);

        const info = parts.picklistInfo.filter((i) => i.id === picklist.id)[0];
        i = info ? parts.picklistInfo.indexOf(info) : -1;
        if (i > -1) parts.picklistInfo.splice(i, 1);
      });
    });
    this.floorGroups.forEach((f) => {
      f.room_groups.forEach((r) => {
        if (r.room_blocks) {
          r.room_blocks.forEach((b) => {
            Object.values(b.parts).forEach((parts) => {
              let i: number;
              i = parts.picklists.findIndex((v) => v.id == picklist.id);
              if (i > -1) parts.picklists.splice(i, 1);

              const info = parts.picklistInfo.filter(
                (i) => i.id === picklist.id,
              )[0];
              i = info ? parts.picklistInfo.indexOf(info) : -1;
              if (i > -1) parts.picklistInfo.splice(i, 1);
            });
          });
        }
      });
    });
    this.exteriorCategories.forEach((c) => {
      c.sets.forEach((set) => {
        let i: number;
        i = set.picklists.indexOf(picklist);
        if (i > -1) set.picklists.splice(i, 1);

        const info = set.picklistInfo.filter((i) => i.id === picklist.id)[0];
        i = info ? set.picklistInfo.indexOf(info) : -1;
        if (i > -1) set.picklistInfo.splice(i, 1);
      });
    });
    this.api.project.deletePicklist(this.projectId, picklist).subscribe();
    this.onChangePicklist$.next();
  }

  removePicklistFromParts(
    room: RoomGroup,
    parts: InteriorSetParts,
    picklist: Picklist,
  ) {
    const message: string[] = [];
    const source_picklist_id: number[] = [];
    if (room.is_approved) {
      message.push(`貼付け先の${room.name}は施主承認済みです。`);
    }

    if (this.picklistService.isFixed(picklist)) {
      message.push(`${picklist.name}は確定済みです。`);
    }

    if (room.is_approved || this.picklistService.isFixed(picklist)) {
      parts.picklists.forEach((p) => {
        if (p.meta && p.meta.multiple) {
          source_picklist_id.push(p.meta.multiple.source_picklist_id);
        }
      });
    }

    if (message.length > 0 && !source_picklist_id.includes(picklist.id)) {
      message.push(`本当に${picklist.name}の貼付を解除しますか？`);

      this.alertDialog
        .openDialog(message.join('\n'), '貼付解除')
        .afterClosed()
        .subscribe((r) => {
          if (r) {
            this._removePicklistFromParts(parts, picklist, room);
          }
        });
    } else {
      this._removePicklistFromParts(parts, picklist, room);
    }
  }

  _removePicklistFromParts(
    parts: InteriorSetParts,
    picklist: Picklist,
    room?: RoomGroup,
  ) {
    parts.picklists.splice(parts.picklists.indexOf(picklist), 1);
    parts.picklistInfo.splice(
      parts.picklistInfo.indexOf(
        parts.picklistInfo.find((i) => i.id === picklist.id)!,
      ),
      1,
    );

    parts.executing = true;

    if (!!room && room.room_blocks) {
      room.room_blocks.forEach((block) => {
        const blockParts = block.parts[parts.interior_category_id];
        if (blockParts) {
          blockParts.picklists.splice(
            blockParts.picklists.indexOf(picklist),
            1,
          );
          blockParts.picklistInfo.splice(
            blockParts.picklistInfo.indexOf(
              blockParts.picklistInfo.find((i) => i.id === picklist.id)!,
            ),
            1,
          );
        }
      });
    }

    this.api.project
      .removePicklistFromParts(this.projectId, picklist, parts)
      .subscribe((_) => {
        parts.executing = false;
        this.project.onRefreshPicklistNodes.next(undefined);
      });
    this.onChangePicklist$.next();
  }

  removePicklistFromExteriorSet(set: ExteriorSet, picklist: Picklist) {
    const message: string[] = [];

    if (set.is_approved) {
      message.push(`貼付け先の${set.name}は施主承認済みです。`);
    }

    if (message.length > 0) {
      message.push(`本当に${picklist.name}の貼付を解除しますか？`);

      this.alertDialog
        .openDialog(message.join('\n'), '貼付解除')
        .afterClosed()
        .subscribe((r) => {
          if (r) this._removePicklistFromExteriorSet(set, picklist);
        });
    } else {
      this._removePicklistFromExteriorSet(set, picklist);
    }
  }

  _removePicklistFromExteriorSet(set: ExteriorSet, picklist: Picklist) {
    set.picklists.splice(set.picklists.indexOf(picklist), 1);
    set.picklistInfo.splice(
      set.picklistInfo.indexOf(
        set.picklistInfo.find((i) => i.id === picklist.id)!,
      ),
      1,
    );
    this.api.project
      .removePicklistFromExteriorSet(this.projectId, picklist, set)
      .subscribe((_) => {
        this.project.onRefreshPicklistNodes.next(undefined);
      });
    this.onChangePicklist$.next();
  }

  uploadPerspectiveImage(file: File, roomGroup: RoomGroup) {
    const onEnd = new Subject<undefined>();
    this.api.project
      .uploadPerspectiveImage(this.projectId, roomGroup, file)
      .subscribe((result) => {
        if (!roomGroup.perspectiveImages) roomGroup.perspectiveImages = [];
        roomGroup.perspectiveImages.push(result.filePath);
        roomGroup.perspective_image = roomGroup.perspectiveImages.join(',');
        this.saveRoom(roomGroup);
        onEnd.next(undefined);
        onEnd.unsubscribe();
      });

    return onEnd;
  }

  deletePerspectiveImage(roomGroup: RoomGroup, target: string) {
    if (!roomGroup.perspectiveImages) return;
    roomGroup.perspectiveImages = roomGroup.perspectiveImages.filter(
      (img) => img != target,
    );
    roomGroup.perspective_image =
      roomGroup.perspectiveImages.length > 0
        ? roomGroup.perspectiveImages.join(',')
        : undefined;
    this.saveRoom(roomGroup);
  }

  uploadConstructionDocument(file: File, roomGroup: RoomGroup) {
    const onEnd = new Subject<undefined>();
    this.api.project
      .uploadConstructionDocument(this.projectId, roomGroup, file)
      .subscribe((result) => {
        if (!roomGroup.constructionDocuments)
          roomGroup.constructionDocuments = [];
        roomGroup.constructionDocuments.push(result);
        roomGroup.construction_document = JSON.stringify(
          roomGroup.constructionDocuments,
        );
        this.saveRoom(roomGroup);
        onEnd.next(undefined);
        onEnd.unsubscribe();
      });

    return onEnd;
  }

  deleteConstructionDocument(roomGroup: RoomGroup, target: string) {
    if (!roomGroup.constructionDocuments) return;
    roomGroup.constructionDocuments = roomGroup.constructionDocuments.filter(
      (doc) => doc.filePath != target,
    );
    roomGroup.construction_document =
      roomGroup.constructionDocuments.length > 0
        ? JSON.stringify(roomGroup.constructionDocuments)
        : undefined;
    this.saveRoom(roomGroup);
  }

  getProductInfo(product?: ProductCache) {
    return this.project.getProductInfo(product);
  }

  saveListLink(picklist: Picklist, isRemove = false) {
    return this.project.saveListLink(picklist, isRemove);
  }

  updateDisplayListLink(from: Picklist, isRemoved = false): void {
    this.project.updateDisplayListLink(from, isRemoved);
  }

  manageBimRoom() {
    return this.dialog
      .open(ManageBimRoomDialogComponent, { data: this })
      .afterClosed();
  }

  saveRoomsInGroup(rooms: Room[], groupId?: number) {
    rooms.forEach((room) => (room.room_group_id = groupId || null));
    this.api.project.saveRoomsInGroup(rooms).subscribe();
  }

  importBimData(file: File, userId: string) {
    const onEnd$ = new Subject<Project | ImportBimResult | undefined>();
    this.api.project
      .importRevitData(this.projectId, file, userId)
      .pipe(
        catchError((err) => {
          onEnd$.error(err);
          return throwError(err);
        }),
      )
      .subscribe((result) => {
        if (result.error) {
          //バックエンド例外発生時はerrorを返却
          onEnd$.next(result);
          return;
        }

        if (result.canceledGroupingRooms.length > 0) {
          let message = `以下の部屋の情報が一致しないため、仕上表との紐付けを解除しました。\n部屋情報を確認の上、仕上表の「BIM部屋紐付け」から手動で紐付け設定してください。\n`;
          message += '<table><tr><th>仕上表別名</th><th>部屋名</th></tr>';
          result.canceledGroupingRooms.forEach((unmatch) => {
            message += `<tr><td>${
              unmatch.roomGroup.name
            }</td><td>${unmatch.rooms
              .map((r) => r.name)
              .join(' / ')}</td></tr>`;
          });
          message += '</table>';
          this.dialog
            .open(MessageDialogComponent, { data: { message: message } })
            .afterClosed()
            .subscribe((_) => {
              this.project.setProjectId(this.projectId).subscribe((_) => {
                onEnd$.next(this.finishSchedule);
              });
            });
        } else {
          this.project.setProjectId(this.projectId).subscribe((_) => {
            onEnd$.next(this.finishSchedule);
          });
        }
      });

    return onEnd$;
  }

  importTrussBimData(file: File, userId: string) {
    const onEnd$ = new Subject<Project | undefined>();
    this.api.project
      .importTrussBimData(this.projectId, file, userId)
      .pipe(
        catchError((err) => {
          onEnd$.error(err);
          return throwError(err);
        }),
      )
      .subscribe((result) => {
        this.project.setProjectId(this.projectId).subscribe((_) => {
          onEnd$.next(this.finishSchedule);
        });
      });

    return onEnd$;
  }

  importAhDynamo(file: File) {
    const onEnd$ = new Subject<Project | undefined>();
    this.api.project
      .importAhDynamo(this.projectId, file)
      .pipe(
        catchError((err) => {
          onEnd$.error(err);
          return throwError(err);
        }),
      )
      .subscribe((result) => {
        this.project.setProjectId(this.projectId).subscribe((_) => {
          onEnd$.next(this.finishSchedule);
        });
      });

    return onEnd$;
  }

  importAhArex(file: File) {
    const onEnd$ = new Subject<Project | undefined>();
    this.api.project
      .importAhArex(this.projectId, file)
      .pipe(
        catchError((err) => {
          onEnd$.error(err);
          return throwError(err);
        }),
      )
      .subscribe((result) => {
        this.project.setProjectId(this.projectId).subscribe((_) => {
          onEnd$.next(this.finishSchedule);
        });
      });

    return onEnd$;
  }

  hasApprovedRoom(picklistId: number) {
    return this.project.hasApprovedRoom(picklistId);
  }

  isOtherLinkPicklistHasApprovedRoom(picklistId: number) {
    return this.project.isOtherLinkPicklistHasApprovedRoom(picklistId);
  }

  getErrorOfPicklistName(
    target: Picklist,
    name: string | undefined | null = undefined,
  ): string {
    const _name = name ? name : target.name;
    const prohibitedCharacterError = checkProhibitedCharacters(_name);

    if (prohibitedCharacterError) {
      return prohibitedCharacterError;
    }

    const prohibitedSpaceError = checkProhibitedSpace(_name);

    if (prohibitedSpaceError) {
      return prohibitedSpaceError;
    }

    if (
      this.picklists.filter(
        (p) =>
          !p.is_parent &&
          p.name == (name || target.name) &&
          (p.id != target.id || target.id < 1) &&
          p.type == target.type,
      ).length > 0
    ) {
      return '同名の材料が既に存在するため、この名前は使用できません。';
    }
    if (name == '') {
      return '必須入力です';
    }

    return '';
  }

  getErrorOfParentPicklistName(
    target: Picklist,
    name: string | undefined | null = undefined,
  ): string {
    const _name = name ? name : target.name;
    const prohibitedCharacterError = checkProhibitedCharacters(_name);

    if (prohibitedCharacterError) {
      return prohibitedCharacterError;
    }

    const prohibitedSpaceError = checkProhibitedSpace(_name);

    if (prohibitedSpaceError) {
      return prohibitedSpaceError;
    }

    if (
      this.project.parentPicklists.filter(
        (p) =>
          p.name == (name || target.name) &&
          (p.id != target.id || target.id < 1) &&
          p.type == target.type,
      ).length > 0
    ) {
      return '同名の材料が既に存在するため、この名前は使用できません。';
    }
    if (name == '') {
      return '必須入力です';
    }
    return '';
  }

  getErrorOfPicklistMark(
    target: Picklist,
    mark: string | undefined = undefined,
  ): string {
    if (
      this.picklists.filter(
        (p) =>
          p.mark == (mark || target.mark) &&
          (p.id != target.id || target.id < 1) &&
          p.type == target.type,
      ).length > 0
    ) {
      return '同名の材料が既に存在するため、この名前は使用できません。';
    }
    return '';
  }

  // createProjectUsecaseに移動してリファクタ済み
  // duplicateProject(from: Project) {
  //   const onEnd = new Subject<Project | undefined>();

  //   this.duplicateProjectDialog
  //     .openDialog({ from })
  //     .afterClosed()
  //     .subscribe((result) => {
  //       if (!result) return;
  //       onEnd.next(undefined);

  //       this.api.project
  //         .duplicateProject(from, result)
  //         .pipe(
  //           catchError((error) => {
  //             onEnd.error(error);
  //             return EMPTY;
  //           }),
  //         )
  //         .subscribe((project) => {
  //           onEnd.next(project);
  //         });
  //     });

  //   return onEnd;
  // }

  searchCategory(type: PicklistType, picklist?: Picklist) {
    const onEnd$ = new Subject<boolean | undefined>();
    this.project.initPicklistType(type);
    this.dialog
      .open(SearchPicklistCategoryDialogComponent, {
        data: { picklist },
      })
      .beforeClosed()
      .subscribe((result) => {
        if (!result && !this.router.url.match(/.*\/picklist\/[0-9]\/?.*/)) {
          this.project.currentPicklist = undefined;
        }
        onEnd$.next(result);
      });

    return onEnd$;
  }

  exportCsvFromTable(interior: boolean, material: boolean): string | void {
    const projectName = this.finishSchedule!.name;
    const blob = material
      ? this.arrToCSV(this.getDataFromPicklist(interior))
      : this.arrToCSV(this.getDataFromProject(interior));
    const constructionPartName = interior ? 'interior_' : 'exterior_';
    const tableName = material ? 'material_' : 'finish-schedule_';
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute(
      'download',
      constructionPartName + tableName + projectName + '.csv',
    );
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getDataFromProject(interior: boolean): string[][] {
    const tableData: string[][] = [];

    if (interior) {
      const header = ['階', '部屋'];
      const headerType = ['', ''];
      const floorGroups = this.sortFinishScheuleOrder(this.floorGroups);
      this.interiorCategories.forEach((c, i) => {
        if (i === 0) {
          header.push(c.name);
        } else if (c.name === this.interiorCategories[i - 1].name) {
          header.push('');
        } else {
          header.push(c.name);
        }
        headerType.push(c.type !== null ? c.type : '');
      });

      const appendCols: {
        header: string;
        headerType: string;
        key?: keyof RoomGroup;
      }[] = [
        { header: 'SL', headerType: '', key: 'sl' },
        { header: 'FL', headerType: '', key: 'fl' },
        { header: '巾木H', headerType: '', key: 'baseboard_height' },
        { header: '腰壁H', headerType: '', key: 'spandrel_wall_height' },
        { header: 'CH', headerType: '', key: 'ch' },
        { header: '備考', headerType: '', key: 'finish_schedule_remarks' },
        { header: '部屋GUID', headerType: '' },
      ];

      appendCols.forEach((c) => {
        header.push(c.header);
        headerType.push(c.headerType);
      });
      tableData.push(header, headerType);

      floorGroups.forEach((f: FloorGroup) => {
        const floorName = f.name;
        this.sortFinishScheuleOrder(f.room_groups).forEach(
          (r: RoomGroup, i) => {
            const row = [];
            i === 0 ? row.push(floorName, r.name) : row.push('', r.name);
            this.interiorCategories.forEach((k) => {
              const cell: string[] = [];
              if (r.interiorSet.parts[k.id]) {
                r.interiorSet.parts[k.id].picklists.forEach((p: Picklist) => {
                  cell.push(p.name);
                });
              }
              row.push(cell.join('/'));
            });
            appendCols
              .filter((c) => !!c.key)
              .forEach((c) => {
                row.push(r[c.key!]);
              });
            const rooms = this.finishSchedule!.rooms.filter((room) => {
              return room.room_group_id == r.id;
            });
            row.push(rooms.map((r) => r.revit_id).join(','));
            tableData.push(row);
          },
        );
      });
    } else {
      const categories = this.sortFinishScheuleOrder(this.exteriorCategories);
      tableData.push(['分類', '名称', '材料']);
      categories.forEach((c: ExteriorCategory) => {
        const categoryName = c.name;
        this.sortFinishScheuleOrder(c.sets).forEach((s: ExteriorSet, i) => {
          const row = [];
          const replaceName = s.name.replace(/,/g, ' ');
          i === 0
            ? row.push(categoryName, replaceName)
            : row.push('', replaceName);
          const cell: string[] = [];
          s.picklists.forEach((p: Picklist) => {
            cell.push(p.name);
          });
          row.push(cell.join('/'));
          tableData.push(row);
        });
      });
    }
    return tableData;
  }

  getDataFromPicklist(interior: boolean): string[][] {
    const tableData: string[][] = [];

    if (interior) {
      tableData.push(
        [
          '部位',
          '名称',
          '仕様',
          '内装制限',
          '使用場所',
          '製品',
          '',
          '',
          '',
          '',
          '',
          '',
          '同等品可否',
          '材料表備考',
          '材料メモ',
        ],
        [
          '',
          '',
          '',
          '',
          '',
          '製品名',
          '品番',
          'メーカー名',
          '認定番号・告示',
          'シックハウス規制',
          '設計単価',
          '材工/材のみ',
          '',
          '',
          '',
        ],
      );
      this.project
        .interiorPicklistGroups!.map((_g) => {
          const _usedPicklists = this.project.usedInteriorPicklists.find(
            (u) => u.name == _g.name,
          );
          return {
            name: _g.name,
            picklists: _g.picklists.filter((p) => {
              return _usedPicklists
                ? _usedPicklists.picklists.includes(p)
                : false;
            }),
          };
        })
        .filter((g) => g.name != '未使用' && g.picklists.length > 0)!
        .forEach((g) => {
          const typeName = g.name;
          const picklists = this.picklistSort(g.picklists, g.name);
          picklists.forEach((l, i) => {
            const room = this.getInteriorPicklistRoom(l);
            const listProduct =
              this.picklistService.getDecidedListProductByPicklist(l);
            if (listProduct) {
              const productName =
                (listProduct.name ? listProduct.name : '') +
                (this.getProductSpecification(listProduct) &&
                this.getProductSpecification(listProduct) !== '-'
                  ? '/' + this.getProductSpecification(listProduct)
                  : '');
              const row: string[] = [];
              row.push(
                i === 0 ? typeName : '',
                l.name ? l.name : '',
                l.specification ? this.removeNewline(l.specification) : '',
                '',
                room ? room : '',
                productName ? productName : '',
                listProduct.number ? listProduct.number : '',
                listProduct.maker ? listProduct.maker : '',
                listProduct.certification ? listProduct.certification : '',
                listProduct.sickhouse ? listProduct.sickhouse : '',
                listProduct.price
                  ? listProduct.price + ' ' + listProduct.price_unit
                  : '',
                listProduct.lumberjack ? listProduct.lumberjack : '',
                l.enable_similar ? '可' : '不可',
                l.remarks ? this.removeNewline(l.remarks) : '',
                l.memo ? this.removeNewline(l.memo) : '',
              );
              tableData.push(row);
            }
          });
        });
    } else {
      tableData.push(
        [
          '分類',
          'セット',
          '名称',
          '仕様',
          '防耐火構造',
          '使用場所',
          '製品',
          '',
          '',
          '',
          '',
          '',
          '同等品可否',
          '仕上画像',
          '材料表備考',
          '材料メモ',
        ],
        [
          '',
          '',
          '',
          '',
          '',
          '',
          '製品名',
          '品番',
          'メーカー名',
          '認定番号・告示',
          '設計単価',
          '材工/材のみ',
          '',
          '',
          '',
          '',
        ],
      );
      this.exteriorCategories.forEach((c) => {
        const categoryName = c.name;
        c.sets.forEach((s, i) => {
          let row: string[] = [];
          const replaceName = s.name.replace(/,/g, ' ');
          if (s.picklists.length === 0) {
            tableData.push(row.concat(['', replaceName]));
          } else {
            const { id: categoryId } = c as ExteriorCategory & { id: string };
            const { id: setId } = s as ExteriorSet & { id: string };
            const picklists = this.picklistSort(
              s.picklists,
              s.name,
              categoryId,
              setId,
              true,
            );
            picklists.forEach((l, j) => {
              const listProduct =
                this.picklistService.getDecidedListProductByPicklist(l);
              const set = this.getExteriorPicklistSet(l);

              if (listProduct) {
                const productName =
                  (listProduct.name ? listProduct.name : '') +
                  (this.getProductSpecification(listProduct) &&
                  this.getProductSpecification(listProduct) !== '-'
                    ? '/' + this.getProductSpecification(listProduct)
                    : '');
                row.push(
                  i === 0 && j === 0 ? categoryName : '',
                  j === 0 ? replaceName : '',
                  l.name ? l.name : '',
                  l.specification ? this.removeNewline(l.specification) : '',
                  '',
                  set ? set : '',
                  productName ? productName : '',
                  listProduct.number ? listProduct.number : '',
                  listProduct.maker ? listProduct.maker : '',
                  listProduct.certification ? listProduct.certification : '',
                  listProduct.price
                    ? listProduct.price + ' ' + listProduct.price_unit
                    : '',
                  listProduct.lumberjack ? listProduct.lumberjack : '',
                  l.enable_similar ? '可' : '不可',
                  this.isSurface(s, l) ? '○' : '-',
                  l.remarks ? this.removeNewline(l.remarks) : '',
                  l.memo ? this.removeNewline(l.memo) : '',
                );
                tableData.push(row);
                row = [];
              }
            });
          }
        });
      });
    }
    return tableData;
  }

  getInteriorPicklistRoom(l: Picklist): string {
    const nameOfSetId = this.interiroSets.reduce(
      (list, s) => {
        const usingCategories = this.interiorCategories.filter((c) => {
          if (!s.parts[c.id]) {
            return false;
          }
          return (
            s.parts[c.id].picklists.filter((p) => p.id === l.id).length > 0
          );
        });
        if (usingCategories.length === 0) {
          return list;
        }
        list[s.id] = usingCategories
          .map((c) => c.name + (c.type || ''))
          .join(',');
        return list;
      },
      {} as { [setId: number]: string },
    );

    return this.floorGroups
      .reduce((list, f) => {
        list.push(
          ...f.room_groups
            .filter((r) => !!nameOfSetId[r.interior_set_id])
            .map((r) => {
              return f.name + ' ' + r.name;
            }),
        );
        return list;
      }, [] as string[])
      .join('/');
  }

  getExteriorPicklistSet(l: Picklist): string {
    return this.exteriorCategories
      .reduce((list, c) => {
        return [
          ...list,
          ...c.sets
            .filter((s) => !!s.picklists.find((p) => p.id === l.id))
            .map((s) => c.name + ' ' + s.name),
        ];
      }, [])
      .join('/')
      .replace(/,/g, ' ');
  }

  getProductSpecification(listProduct: ListProduct) {
    return listProduct.specification
      ? listProduct.specification.replace(/\r?\n/g, '/')
      : '';
  }

  removeNewline(l: string) {
    return l ? l.replace(/\r?\n/g, '/') : '';
  }

  isSurface(set: ExteriorSet, picklist: Picklist) {
    if (set.surface_picklist_id === picklist.id) {
      return true;
    }
    if (!set.surface_picklist_id && set.picklists[0]!.id === picklist.id) {
      return true;
    }
    if (
      !set.picklists.find((p) => p.id === set.surface_picklist_id) &&
      set.picklists[0]!.id === picklist.id
    ) {
      return true;
    }
    return false;
  }

  arrToCSV(arr: string[][]): Blob {
    console.log(arr);
    let csvString = '';
    arr.forEach((a) => {
      const row = a.join('\t');
      csvString += row + '\r\n';
    });
    csvString = '\ufeff' + csvString;

    const array = [];
    for (let i = 0; i < csvString.length; i++) {
      array.push(csvString.charCodeAt(i));
    }

    console.log(array);
    const csvData = new Uint16Array(array);

    return new Blob([csvData], {
      type: 'text/csv;charset=utf-16;',
    });
  }

  hasProductInfo(decidedListProduct: ListProduct) {
    return (
      !!decidedListProduct.name ||
      !!decidedListProduct.specification ||
      !!decidedListProduct.number ||
      !!decidedListProduct.maker ||
      !!decidedListProduct.certification ||
      !!decidedListProduct.sickhouse ||
      !!decidedListProduct.thickness ||
      !!decidedListProduct.price ||
      !!decidedListProduct.lumberjack
    );
  }

  isConstructionClassificationOrNot(key: string) {
    return new RegExp('construction_classification_.*$').test(key);
  }

  checkChildPicklistIsUnused(category: string, picklist: Picklist): boolean {
    const categorized = this.project.usedInteriorPicklists.find(
      (u) => u.name == category,
    );
    if (categorized) {
      return !categorized.picklists.find((_c) => _c.id == picklist.id);
    }
    return true;
  }

  createOtherProjectType(
    from: Project,
    isUpdateTemplate = false,
    type = ProjectType.Template,
  ): Observable<Project | undefined | string> {
    const onEnd = new Subject<Project | undefined>();
    const info: DuplicateProjectResult = (() => {
      switch (type) {
        case ProjectType.StandardSet:
          return {
            interior: {
              finishSchedule: true,
              roomApproved: false,
              picklist: true,
              picklistProduct: true,
              picklistDecided: false,
            },
            exterior: {
              finishSchedule: true,
              setApproved: false,
              picklist: true,
              picklistProduct: true,
              picklistDecided: false,
            },
            outdoor: {
              picklist: true,
              picklistProduct: true,
              picklistDecided: false,
            },
            revit: true,
          };
        default:
          return {
            interior: {
              finishSchedule: true,
              roomApproved: true,
              picklist: true,
              picklistProduct: true,
              picklistDecided: true,
            },
            exterior: {
              finishSchedule: true,
              setApproved: true,
              picklist: true,
              picklistProduct: true,
              picklistDecided: true,
            },
            outdoor: {
              picklist: true,
              picklistProduct: true,
              picklistDecided: true,
            },
            revit: true,
          };
      }
    })();

    this.api.project
      .createOtherProjectType(from, info, isUpdateTemplate, type)
      .pipe(
        catchError((error) => {
          onEnd.error(error);
          return EMPTY;
        }),
      )
      .subscribe((project) => {
        onEnd.next(project);
      });

    return onEnd;
  }

  getBasePicklist(picklist: Picklist) {
    return Object.assign({}, picklist!, {
      id: 0,
      list_link_id: null,
      revit_id: null,
    });
  }

  updateBasicInfo(picklist: Picklist): Observable<Picklist> {
    const onEnd = new Subject<Picklist>();

    if (picklist!.list_link_id) {
      setTimeout(() => {
        this.listLinkCheckDialogService
          .openDialog(picklist!)
          .afterClosed()
          .subscribe((_) => {
            this.updateBasicInfoPicklist(picklist).subscribe((result) => {
              this.reflectPicklistService.reflectPicklistViewModel(result);
              onEnd.next(result);
            });
            this.listLinkCheckDialogService.closeAll();
          });
      }, 100);
    } else {
      this.updateBasicInfoPicklist(picklist).subscribe((result) => {
        this.reflectPicklistService.reflectPicklistViewModel(result);
        onEnd.next(result);
      });
    }

    return onEnd.asObservable();
  }

  updateBasicInfoPicklist(picklist: Picklist): Observable<Picklist> {
    const onEnd = new Subject<Picklist>();

    //2023.07.28 マテリアルボード・材料詳細に反映させるため
    this.picklistStore.updateOne(picklist);
    //

    this.saveInteriorPicklist(picklist!).subscribe((result) => {
      onEnd.next(result);
    });

    return onEnd.asObservable();
  }

  getOtherLinkPicklist(picklist: Picklist): Picklist | undefined {
    return this.project.getOtherLinkPicklist(picklist);
  }

  getSentenceOnApproved(
    picklist: Picklist | undefined,
    allowedSentence = '編集',
  ): string | null {
    return this.project.getSentenceOnApproved(picklist, allowedSentence);
  }

  importBiData(file: File) {
    const onEnd$ = new Subject<Project | undefined>();
    this.api.project
      .importBiData(this.projectId, file)
      .pipe(
        catchError((err) => {
          onEnd$.error(err);
          return throwError(err);
        }),
      )
      .subscribe((result) => {
        this.project.setProjectId(this.projectId).subscribe((_) => {
          onEnd$.next(this.finishSchedule);
        });
      });

    return onEnd$;
  }

  public getReletaionPicklists(picklist: Picklist) {
    return this.project.getReletaionPicklists(picklist);
  }

  public isVisibleY(id: string, scrollContainer?: HTMLElement): boolean {
    const el = document.getElementById(id);
    scrollContainer = scrollContainer ?? this.scrollContainer;
    if (!el || !scrollContainer) return false;
    const extra = 60;
    const top = scrollContainer.getBoundingClientRect().top;
    const rect = el.getBoundingClientRect();
    if (rect.top + rect.height < top - extra) return false;
    if (rect.top > document.documentElement.clientHeight + extra) return false;
    return true;
  }
}
