import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  FavoriteList,
  FavoriteListLog,
} from '../models/response/favorite-list';
import { Invite } from '../models/response/invite';
import { Pagination } from '../models/response/pagination';
import { Product } from '../models/response/product';
import { PicklistProductStatus } from '../models/response/sub/picklist-product-status';
import { User } from '../models/response/user';

export interface FavProductInfoUpdateParams {
  product_unique_key: string;
  status: PicklistProductStatus;
  changed_status_user_id?: number;
  tags: {
    [tag: string]: boolean;
  };
  with_variation: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FavoriteListService {
  constructor(protected http: HttpClient) {}

  update(list: FavoriteList): Observable<FavoriteList> {
    return this.http.put<FavoriteList>(`/api/favorite-lists/${list.id}`, list);
  }

  members(list: FavoriteList): Observable<User[]> {
    return this.http.get<User[]>(`/api/favorite-lists/${list.id}/members`);
  }

  invites(list: FavoriteList): Observable<Invite[]> {
    return this.http.get<Invite[]>(`/api/favorite-lists/${list.id}/invites`);
  }

  sendInvite(list: FavoriteList, mail: string): Observable<Invite[]> {
    return this.http.post<Invite[]>(
      `/api/favorite-lists/${list.id}/invites/send`,
      { mail },
    );
  }

  tags(favListId: string): Observable<string[]> {
    return this.http.get<string[]>(`/api/favorite-lists/${favListId}/tags`);
  }

  logs(
    list: FavoriteList,
    page?: number,
  ): Observable<Pagination<FavoriteListLog>> {
    page = page || 1;
    return this.http.get<Pagination<FavoriteListLog>>(
      `/api/favorite-lists/${list.id}/logs?page=${page}`,
    );
  }

  updateProductInfo(
    favListId: number,
    params: FavProductInfoUpdateParams,
  ): Observable<Product> {
    return this.http.post<Product>(
      `/api/favorite-lists/${favListId}/product`,
      params,
    );
  }

  copyFrom(toId: number, fromId: number): Observable<any> {
    return this.http.get<any>(
      `/api/favorite-lists/${toId}/copy-from/${fromId}`,
    );
  }

  countListStatus(
    listId: number,
    status: PicklistProductStatus,
  ): Observable<number> {
    const params = new HttpParams().set('status', status);
    return this.http.get<number>(
      `/api/favorite-lists/${listId}/countListStatus`,
      { params: params },
    );
  }
}
