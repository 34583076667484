import {
  Component,
  Input,
  ElementRef,
  AfterViewInit,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';

export interface InputValue {
  name: string;
  id?: number;
}

@Component({
  selector: 'ls-exterior-select-new-value',
  templateUrl: './exterior-select-new-value.component.html',
  styleUrls: ['./exterior-select-new-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ExteriorSelectNewValueComponent,
      multi: true,
    },
  ],
})
export class ExteriorSelectNewValueComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input() placeholder = '';
  values: InputValue[] = [];
  nameControl = new UntypedFormControl();

  @Output() isTyped = new EventEmitter<string>();

  private htmlInput: HTMLInputElement;
  private inputValue: InputValue[];

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.htmlInput = this.elementRef.nativeElement.querySelector('textarea');
  }

  get value(): InputValue[] {
    return this.inputValue;
  }

  set value(value: InputValue[]) {
    if (value === this.inputValue) {
      return;
    }
    this.writeValue(value);
  }

  public autoInput(standardSet: InputValue) {
    this.setValue(standardSet);
  }

  public handleInput(event: Event | KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 0) {
      this.isTyped.emit(target.value);
    }

    if (
      event.type === 'keydown' &&
      (event as KeyboardEvent).keyCode === 13 &&
      target.value.trim() !== ''
    ) {
      this.setValue({ id: 0, name: target.value });
    }

    if (event.type === 'paste') {
      setTimeout(() => {
        target.value.split(/\r\n|\n/).forEach((v) => {
          if (v.trim() !== '') {
            this.setValue({ id: 0, name: v });
          }
        });
      }, 100);
    }
  }

  setValue(value: InputValue) {
    this.value = this.values.concat(value);
    this.htmlInput.value = '';
    this.htmlInput.focus();
  }

  removeValue(value: InputValue) {
    const index = this.values.findIndex((v) => {
      return v.name == value.name;
    });
    if (index !== -1) {
      if (index === this.values.length - 1) {
        this.value = this.values.slice(0, -1);
      } else {
        this.value = this.values
          .slice(0, index)
          .concat(this.values.slice(index + 1));
      }
      this.htmlInput.focus();
    }
  }

  writeValue(value: InputValue[]): void {
    this.inputValue = value;
    this.elementRef.nativeElement.value = value;

    this.values = value || [];

    this.onChange(value);
  }

  onChange = (_: InputValue[]) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: InputValue[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  getTypedValue(event: any) {
    this.isTyped.emit(event.data!);
  }
}
