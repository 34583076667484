import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKey } from 'app/store/enums/feature-key.enum';

import {
  symbolWithNumberAdapter,
  SymbolWithNumberState,
} from '../symbol-with-number.state';

const getSymbolWithNumberState = createFeatureSelector<SymbolWithNumberState>(
  FeatureKey.SymbolWithNumber,
);

const { selectAll } = symbolWithNumberAdapter.getSelectors();

export const getIsFetching = createSelector(
  getSymbolWithNumberState,
  (state) => state.isFetching,
);

export const getError = createSelector(
  getSymbolWithNumberState,
  (state) => state.error,
);

export const getSymbolWithNumbers = createSelector(
  getSymbolWithNumberState,
  (state) => selectAll(state),
);

export const getUniqueSymbols = createSelector(
  getSymbolWithNumberState,
  (state) =>
    selectAll(state)
      .map((symbolWithNumber) => symbolWithNumber.symbol)
      .filter((v, i, self) => self.indexOf(v) === i),
);
