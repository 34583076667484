export enum Group {
  None = 'none',
  Daiwa = 'daiwa',
  AH = 'AH',
  Guest = 'guest',
  Test = 'test',
  UbChk = 'ubchk', //非公開シリーズ確認用、仕上表不可
  Truss = 'truss',
  Demo = 'demo', //一般有料ユーザー、Bim操作不可、マニュアル => demo
  Fujita = 'fujita', //DH子会社、Bim操作可、マニュアル => demo
  DaiwaLease = 'daiwa lease', //DH子会社、Bim操作可、マニュアル => demo
  DiceNext = 'dicenext', //DH子会社、Bim操作可、マニュアル => demo
  Cigr = 'cigr', //Bim操作可、マニュアル => demo
  GeneralContractor1 = 'general contractor 1', //Bim操作不可、AH仕様フォーマット、マニュアル => demo
  BootOne = 'bootone', //Bim操作可、マニュアル => demo
  BI = 'BI',
  Taisei = 'taisei',
  BootOneAlpha = 'bootone_alpha',
  Nishimatsu = 'nishimatsu',
  AhBootOne = 'AH-bootone',
  MitsuiSumitomo = 'mitsuisumitomo',
  MatsudaHirata = 'matsudahirata',
  DemoBootOne = 'demo-bootone', //一般有料ユーザー、Bim操作可、マニュアル => demo
  Meikou = 'meikou',
  Goyou = 'goyou',
  MatsumotoCorporation = 'matsumoto corporation',
  Designarc = 'designarc',
  Yabudoken = 'yabudoken',
  Saikikensetsu = 'saikikensetsu',
  NipponSteelEngineering = 'nippon steel engineering', // 日鉄エンジニアリング株式会社
  TakanoKensetsu = 'takanokensetsu', // タカノ建設株式会社
  Jstudio = 'j-studio', // Jスタジオ
  Ouyougijutsu = 'ouyougijutsu', // 応用技術
}

export const getGroups = () =>
  Object.values(Group).filter((g) => g != Group.None);

export const getGroupLabel = (group: Group | string) => {
  switch (group) {
    case Group.Daiwa:
      return '大和ハウス';
    case Group.AH:
      return '安藤ハザマ';
    case Group.Guest:
      return 'ゲスト';
    case Group.Test:
      return 'テスト';
    case Group.UbChk:
      return '非公開カテゴリー確認用'; //もともとUB確認用であったが、非公開カテゴリーをメーカーに確認してもらう用のグループにするため、ラベル名を変更
    case Group.Truss:
      return 'トラス';
    case Group.Demo:
      return 'デモ利用';
    case Group.Fujita:
      return 'フジタ';
    case Group.DaiwaLease:
      return '大和リース';
    case Group.DiceNext:
      return 'DiceNext';
    case Group.Cigr:
      return 'コスモスイニシア';
    case Group.GeneralContractor1:
      return 'ゼネコン１';
    case Group.BootOne:
      return 'BootOne';
    case Group.BI:
      return 'BI';
    case Group.Taisei:
      return '大成建設';
    case Group.BootOneAlpha:
      return 'BootOne Alpha';
    case Group.Nishimatsu:
      return '西松建設';
    case Group.AhBootOne:
      return 'AH-bootone';
    case Group.MitsuiSumitomo:
      return '三井住友建設株式会社';
    case Group.MatsudaHirata:
      return '松田平田設計';
    case Group.DemoBootOne:
      return 'デモ利用BootOneあり';
    case Group.Meikou:
      return '名工建設';
    case Group.Goyou:
      return '五洋建設';
    case Group.MatsumotoCorporation:
      return 'まつもとコーポレーション';
    case Group.Designarc:
      return '株式会社デザインアーク';
    case Group.Yabudoken:
      return '株式会社屋部土建';
    case Group.Saikikensetsu:
      return '株式会社佐伯建設 ';
    case Group.NipponSteelEngineering:
      return '日鉄エンジニアリング株式会社';
    case Group.TakanoKensetsu:
      return 'タカノ建設株式会社';
    case Group.Jstudio:
      return 'J STUDIO';
    case Group.Ouyougijutsu:
      return '応用技術株式会社';
    default:
      return '';
  }
};

export interface GroupSetting {
  room: {
    smoke_exhausting_system: boolean;
    correspondence_grounds: boolean;
    interior_restriction: boolean;
    restriction_grounds: boolean;
    space_type: boolean;
    [key: string]: boolean;
  };
}

export const getGroupSetting = (group: Group | string): GroupSetting => {
  switch (group) {
    case Group.Daiwa:
      return {
        room: {
          smoke_exhausting_system: true,
          correspondence_grounds: false,
          interior_restriction: true,
          restriction_grounds: true,
          space_type: false,
        },
      };
    case Group.GeneralContractor1:
    case Group.AH:
      return {
        room: {
          smoke_exhausting_system: true,
          correspondence_grounds: false,
          interior_restriction: true,
          restriction_grounds: false,
          space_type: true,
        },
      };
    default:
      return {
        room: {
          smoke_exhausting_system: true,
          correspondence_grounds: true,
          interior_restriction: true,
          restriction_grounds: true,
          space_type: false,
        },
      };
  }
};
