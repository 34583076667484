<ls-dialog-base
  [config]="{
    title,
    subtitle,
    actionLabel: '保存',
    cancelLabel: 'キャンセル'
  }"
  [canAction]="canSubmit"
  (cancel)="cancel()"
  (action)="onSave()"
>
  <ng-container *ngIf="!onlyRoom; else onlyroom">
    <table>
      <tr>
        <th>階<span class="required">*</span></th>
        <td>
          <input
            name="name"
            type="text"
            placeholder="「1階」「客室階」のように階名を入力"
            [formControl]="nameControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let groupName of filteredGroup$ | async"
              [value]="groupName"
            >
              {{ groupName }}
            </mat-option>
          </mat-autocomplete>
        </td>
      </tr>
      <tr>
        <th class="vertical-top">
          <div class="vertical-center">
            <span>部屋名<span class="required">*</span></span>
          </div>
        </th>
        <td>
          <ls-interior-select-new-value
            #interiorSelectNewValue
            [(ngModel)]="roomNames"
            (isTyped)="typedValue = $event"
            placeholder="「エントランス」など部屋名を入力"
          ></ls-interior-select-new-value>
        </td>
      </tr>
      <tr class="hint">
        <th></th>
        <td>
          <span
            >入力後にエンターキーを押すことで、複数の部屋を続けて入力できます。<br />
            また、改行区切りの部屋名をコピー&ペーストすることでも入力ができます。</span
          >
        </td>
      </tr>
    </table>
  </ng-container>

  <ng-template #onlyroom>
    <ls-interior-select-new-value
      #interiorSelectNewValue
      [(ngModel)]="roomNames"
      (isTyped)="typedValue = $event"
      placeholder="「エントランス」など部屋名を入力"
    ></ls-interior-select-new-value>
    <p class="hint">
      入力後にエンターキーを押すことで、複数の部屋を続けて入力できます。<br />
      また、改行区切りの部屋名をコピー&ペーストすることでも入力ができます。
    </p>
  </ng-template>
</ls-dialog-base>
