import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKey } from 'app/store/enums/feature-key.enum';

import { roomToRoomAdapter, RoomToRoomState } from '../room-to-room.state';

const getRoomToRoomState = createFeatureSelector<RoomToRoomState>(
  FeatureKey.RoomToRoom,
);

const { selectAll } = roomToRoomAdapter.getSelectors();

export const getIsFetching = createSelector(
  getRoomToRoomState,
  (state) => state.isFetching,
);

export const getError = createSelector(
  getRoomToRoomState,
  (state) => state.error,
);

export const getRoomToRooms = createSelector(getRoomToRoomState, (state) =>
  selectAll(state),
);
