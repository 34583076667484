import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  throwError,
} from 'rxjs';

import { Group } from '../../domain/enums/group.enum';
import { TenantStore } from '../_store/tenant.store';
import { UserTenantStore } from '../_store/user-tenant.store';
import { Tenant, TenantRole, UserTenant } from '../_type/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantQuery {
  private tenantStore = inject(TenantStore);
  private userTenantStore = inject(UserTenantStore);
  private router = inject(Router);

  getTenant(): Observable<Tenant | undefined> {
    return this.tenantStore.get();
  }

  getTenantId(): Observable<number | undefined> {
    return this.tenantStore.get().pipe(map((tenant) => tenant?.id));
  }

  getTenantRoles(): Observable<TenantRole[] | undefined> {
    return this.tenantStore.get().pipe(map((tenant) => tenant?.roles));
  }

  isDHTenant(): Observable<boolean> {
    return this.tenantStore
      .get()
      .pipe(
        map(
          (tenant) =>
            tenant?.name === Group.Daiwa || tenant?.name === Group.Truss,
        ),
      );
  }

  isLoading(): Observable<boolean> {
    return this.tenantStore.get().pipe(map((tenant) => !tenant));
  }

  getUserTenants(): Observable<UserTenant[] | undefined> {
    return this.userTenantStore.get();
  }

  getCurrentUserTenant(): Observable<UserTenant> {
    return combineLatest([this.getTenantId(), this.getUserTenants()]).pipe(
      filter(
        ([currentTenantId, userTenants]) => !!currentTenantId && !!userTenants,
      ),
      map(([currentTenantId, userTenants]: [number, UserTenant[]]) => {
        const targetUserTenant = userTenants?.find(
          (userTenant) => userTenant.id === currentTenantId,
        );
        if (targetUserTenant) return targetUserTenant;
        else return throwError(() => new Error('UserTenant not found'));
      }),
      catchError((error) => {
        console.error(error);
        alert('企業/チームの取得に失敗しました');
        this.router.navigateByUrl('/');
        return of(error);
      }),
    );
  }

  isAdmin(): Observable<boolean> {
    return this.getCurrentUserTenant().pipe(
      map((userTenant: UserTenant) => userTenant.isAdmin),
    );
  }

  isEmployee(): Observable<boolean> {
    return this.getCurrentUserTenant().pipe(
      map((userTenant) => userTenant?.role.employmentType === 'employee'),
    );
  }

  isAllowedGroupEmployee(groups: string[]): Observable<boolean> {
    return this.getCurrentUserTenant().pipe(
      map((userTenant) => {
        if (!userTenant) return false;

        const isEmployee = userTenant?.role.employmentType === 'employee';
        const isAllowedGroup = groups.includes(userTenant.name);

        return isEmployee && isAllowedGroup;
      }),
    );
  }
}
