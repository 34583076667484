import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from '../api/api.service';
import { Maker } from '../models/response/maker';
import { SearchPicklistCategories } from '../models/response/search-picklist-categories';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchCategories?: SearchPicklistCategories = undefined;
  makers?: Maker[] = [];
  onLoadSeachCategories$ = new Subject<void>();
  onLoadSeachMakers$ = new Subject<void>();

  private unsubscriber = new Subject<void>();

  // TODO: constructorから外す
  constructor(private api: ApiService) {
    this.api.category.searchCategories().subscribe((categories) => {
      this.searchCategories = categories;
      this.onLoadSeachCategories$.next();
      this.onLoadSeachCategories$.complete();
    });

    this.api.maker
      .indexWithOutPagenation()
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((makers) => {
        this.makers = makers;
        this.onLoadSeachMakers$.next();
        this.onLoadSeachMakers$.complete();
      });
  }
}
