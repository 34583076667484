import { Injectable } from '@angular/core';
import { JoineryComponentWithStandard } from '@interfaces/joinery-component';
import { JoineryPicklistSpecification } from '@interfaces/joinery-picklist-specification';
import { select, Store } from '@ngrx/store';
import { Picklist } from '@shared/models/response/picklist';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { map, Observable } from 'rxjs';

import { PicklistAction } from './actions';
import { PicklistFilterParam } from './picklist.state';
import { PicklistSelector } from './selectors';

@Injectable({
  providedIn: 'root',
})
export class PicklistStoreFacade {
  isFetching$ = this.store.pipe(select(PicklistSelector.getIsFetching));
  error$ = this.store.pipe(select(PicklistSelector.getError));
  filteredPicklists$ = this.store.pipe(
    select(PicklistSelector.getFilteredPicklists),
  );
  picklists$ = this.store.pipe(select(PicklistSelector.getPicklists));
  parentPicklists$ = this.store.pipe(
    select(PicklistSelector.getParentPicklists),
  );
  childPicklists$ = this.store.pipe(select(PicklistSelector.getChildPicklists));

  constructor(private readonly store: Store) {}

  fetchAll(
    projectId: number,
    region?: ProjectRangeType,
    businessGroupId?: number,
  ) {
    this.store.dispatch(
      PicklistAction.fetchAll({ projectId, region, businessGroupId }),
    );
  }

  updateSpec(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    picklist: Picklist,
    picklistSpecification: JoineryPicklistSpecification,
  ): void {
    this.store.dispatch(
      PicklistAction.updateSpec({
        projectId,
        region,
        businessGroupId,
        picklist,
        picklistSpecification,
      }),
    );
  }

  addPicklistToStandardComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    standardComponentId: number,
    newPicklistName: string,
    parentPicklist?: Picklist,
  ): void {
    this.store.dispatch(
      PicklistAction.addPicklistToStandardComponent({
        projectId,
        region,
        businessGroupId,
        standardComponentId,
        newPicklistName,
        parentPicklist,
      }),
    );
  }

  addPicklistToComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    component: JoineryComponentWithStandard,
    newPicklistName: string,
    parentPicklist?: Picklist,
  ): void {
    this.store.dispatch(
      PicklistAction.addPicklistToComponent({
        projectId,
        region,
        businessGroupId,
        component,
        newPicklistName,
        parentPicklist,
      }),
    );
  }

  duplicateJoineryPicklist(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    picklist: Picklist,
  ): void {
    this.store.dispatch(
      PicklistAction.duplicateJoineryPicklist({
        projectId,
        region,
        businessGroupId,
        picklist,
      }),
    );
  }

  removePicklists(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    picklists: Picklist[],
  ) {
    this.store.dispatch(
      PicklistAction.removeMany({
        projectId,
        region,
        businessGroupId,
        picklists,
      }),
    );
  }

  setJoineryComponentIds(componentIds: number[], picklist: Picklist) {
    this.store.dispatch(
      PicklistAction.setJoineryComponentIds({
        componentIds,
        picklist,
      }),
    );
  }

  removeJoineryComponentIds(
    components: JoineryComponentWithStandard[],
    picklists: Picklist[],
  ) {
    this.store.dispatch(
      PicklistAction.removeJoineryComponentIds({ components, picklists }),
    );
  }

  sets(picklists: Picklist[]) {
    this.store.dispatch(PicklistAction.sets({ picklists }));
  }

  setFilter(param: PicklistFilterParam) {
    this.store.dispatch(PicklistAction.setFilter({ param }));
  }

  getPicklistByPicklistId(
    picklistId: number | null,
  ): Observable<Picklist | undefined> {
    return this.picklists$.pipe(
      map((picklists) =>
        picklists.find((picklist) => picklist.id === picklistId),
      ),
    );
  }

  getPicklistsByPicklistIds(picklistIds: number[]): Observable<Picklist[]> {
    return this.picklists$.pipe(
      map((picklists) =>
        picklists.filter((picklist) => picklistIds.includes(picklist.id)),
      ),
    );
  }
}
