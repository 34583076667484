import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountPerOptions } from '@general/category/search-form/count-per-options';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

import { List } from '../models/list';
import { SeriesSearchParams } from '../models/request/series-search-params';
import { Count } from '../models/response/count';
import { Feature } from '../models/response/feature';
import { Maker } from '../models/response/maker';
import { Pagination } from '../models/response/pagination';
import { Product } from '../models/response/product';
import { SearchParamsOptionSet } from '../models/response/search-params-option-set';
import { Series } from '../models/response/series';
import { UseCase } from '../models/response/use-case';
import { setFavoriteState } from '../service/favorite-state.service';

import { RequestParamsConverterService } from './request-params-converter.service';

@Injectable({
  providedIn: 'root',
})
export class SeriesService {
  constructor(
    protected http: HttpClient,
    protected converter: RequestParamsConverterService,
  ) {}

  index(page = 1): Observable<Pagination<Series>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<Series>>('/api/series', { params });
  }

  show(id: string, tenantName?: string): Observable<Series> {
    const params = new HttpParams().set('tenant', tenantName ?? '');
    return this.http.get<Series>(`/api/series/${id}`, { params });
  }

  search(params: SeriesSearchParams = {}): Observable<Pagination<Series>> {
    return this.http.get<Pagination<Series>>(`/api/series/search`, {
      params: this.converter.convert(params),
    });
  }

  searchTotal(params: SeriesSearchParams = {}): Observable<Count> {
    return this.http.get<Count>(`/api/series/search/total`, {
      params: this.converter.convert(params),
    });
  }

  products(id: string, page = 1): Observable<Pagination<Product>> {
    const params = { page: page + '' };
    return this.http
      .get<Pagination<Product>>(`/api/series/${id}/products`, { params })
      .pipe(tap((pagination) => setFavoriteState(...pagination.data)));
  }

  features(id: string): Observable<Feature[]> {
    return this.http.get<Feature[]>(`/api/series/${id}/features`);
  }

  featuresTotal(id: string): Observable<Count> {
    return this.http.get<Count>(`/api/series/${id}/features/total`);
  }

  useCases(id: string): Observable<UseCase[]> {
    return this.http.get<UseCase[]>(`/api/series/${id}/use-cases`);
  }

  useCasesTotal(id: string): Observable<Count> {
    return this.http.get<Count>(`/api/series/${id}/use-cases/total`);
  }

  maker(id: string): Observable<Maker> {
    return this.http.get<Maker>(`/api/series/${id}/maker`);
  }

  searchParamsMap(
    id: string,
    categories: string[],
  ): Observable<Map<string, SearchParamsOptionSet>> {
    return this.http
      .get<List<SearchParamsOptionSet>>(`/api/series/${id}/search-params`, {
        params: this.converter.convert({ categories }),
      })
      .pipe(
        map((response) => {
          const paramsMap = new Map<string, SearchParamsOptionSet>();
          Object.keys(response).forEach((categoryId) => {
            return (
              !!response[categoryId] &&
              paramsMap.set(categoryId, response[categoryId])
            );
          });
          return paramsMap;
        }),
      );
  }

  newest(): Observable<Series[]> {
    return this.http.get<Series[]>('/api/series/newest');
  }

  getCountPerOptions(seriesSearchParams: SeriesSearchParams) {
    return this.http.get<{ [key: string]: CountPerOptions }>(
      `/api/series/search/count-per-option`,
      {
        params: this.converter.convert(seriesSearchParams),
      },
    );
  }
}
