<mat-dialog-content class="g-line-break-text">{{
  this.data.message
}}</mat-dialog-content>

<mat-dialog-actions>
  <a
    *ngIf="!data.isSingleButton"
    ls-button
    (click)="cancel()"
    [border]="true"
    >{{ this.data.cancelLabel }}</a
  >
  <a
    ls-button
    (click)="okClick()"
    [theme]="this.data.okTheme || 'warn'"
    [border]="true"
    >{{ this.data.okLabel }}</a
  >
</mat-dialog-actions>
