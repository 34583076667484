import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Picklist } from '@shared/models/response/picklist';

import { PicklistAction } from './actions';
import { initialPicklistState, picklistAdapter } from './picklist.state';

export const picklistReducer = createReducer(
  initialPicklistState,
  on(PicklistAction.fetchAll, (state) => {
    return picklistAdapter.removeAll({ ...state, isFetching: true });
  }),
  on(PicklistAction.fetchAllSuccess, (state, { picklists }) =>
    picklistAdapter.setAll(picklists, {
      ...state,
      isFetching: false,
    }),
  ),
  on(PicklistAction.fetchAllFailure, (state, { error }) => ({
    ...state,
    isFetching: false,
    error,
  })),
  on(PicklistAction.updateManySuccess, (state, { picklists }) => {
    return picklistAdapter.updateMany(picklists, state);
  }),
  on(PicklistAction.removeMany, (state, { picklists }) => ({
    ...state,
    processingIds: [
      ...state.processingIds,
      ...picklists.map((picklist) => picklist.id),
    ],
  })),
  on(PicklistAction.removeManySuccess, (state, { ids }) =>
    picklistAdapter.removeMany(ids, {
      ...state,
      processingIds: [
        ...state.processingIds.filter((_id) => !ids.includes(_id)),
      ],
    }),
  ),
  on(PicklistAction.addProcessingId, (state, { id }) => ({
    ...state,
    processingIds: [...state.processingIds, id],
  })),
  on(PicklistAction.updateSpec, (state) => ({
    ...state,
  })),
  on(PicklistAction.updateSpecSuccess, (state, { picklist }) => {
    return picklistAdapter.updateOne(
      {
        id: picklist.id,
        changes: { ...picklist },
      },
      {
        ...state,
        processingIds: [
          ...state.processingIds.filter((_id) => _id !== picklist.id),
        ],
      },
    );
  }),
  on(PicklistAction.updateSpecFailure, (state, { error }) => ({
    ...state,
    error,
    isFetching: false,
  })),
  on(PicklistAction.addPicklistToStandardComponent, (state) => ({ ...state })),
  on(
    PicklistAction.addPicklistToStandardComponentSuccess,
    (state, { picklist }) => {
      return picklistAdapter.addOne(picklist, state);
    },
  ),
  on(
    PicklistAction.addPicklistToStandardComponentFailure,
    (state, { error }) => ({
      ...state,
      error,
    }),
  ),
  on(PicklistAction.addPicklistToComponent, (state) => ({ ...state })),
  on(PicklistAction.addPicklistToComponentSuccess, (state, { picklists }) => {
    return picklistAdapter.addMany(picklists, state);
  }),
  on(PicklistAction.addPicklistToComponentFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(PicklistAction.duplicateJoineryPicklist, (state) => ({ ...state })),
  on(PicklistAction.duplicateJoineryPicklistSuccess, (state, { picklist }) => {
    return picklistAdapter.addOne(picklist, state);
  }),
  on(PicklistAction.duplicateJoineryPicklistFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    PicklistAction.setJoineryComponentIds,
    (state, { componentIds, picklist }) => {
      const prevIds = picklist.joinery_component_ids ?? [];
      const change: Update<Picklist> = {
        id: picklist.id,
        changes: {
          joinery_component_ids: [...prevIds, ...componentIds],
        },
      };
      return picklistAdapter.updateOne(change, state);
    },
  ),
  on(
    PicklistAction.removeJoineryComponentIds,
    (state, { components, picklists }) => {
      const changes: Update<Picklist>[] = picklists.map((p) => {
        const targetComponentIds = components
          .filter((c) => c.picklist_id === p.id)
          .map((c) => c.id);
        return {
          id: p.id,
          changes: {
            joinery_component_ids:
              p.joinery_component_ids?.filter(
                (id) => !targetComponentIds.includes(id),
              ) ?? [],
          },
        } as Update<Picklist>;
      });
      return picklistAdapter.updateMany(changes, state);
    },
  ),
  on(PicklistAction.sets, (state, { picklists }) => {
    return picklistAdapter.upsertMany(picklists, state);
  }),
  on(PicklistAction.setFilter, (state, { param }) => ({
    ...state,
    filter: { ...state.filter, ...param },
  })),
);
