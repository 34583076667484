export enum PicklistType {
  Interior = 'interior',
  Exterior = 'exterior',
  Outdoor = 'outdoor',
  Joinery = 'joinery',
  Facility = 'facility',
  GeneralWork = 'general Work',
  MiscellaneousWork = 'miscellaneous-work',
  Other = 'other',
  DoorFrame = 'door-frame',
  WindowFrame = 'window-frame',
  Shutter = 'shutter',
}

export const getPicklistTypes = () => Object.values(PicklistType);

export const getPicklistTypeLabel = (type: PicklistType | string) => {
  switch (type) {
    case PicklistType.Interior:
      return '内部';
    case PicklistType.Exterior:
      return '外部';
    case PicklistType.Outdoor:
      return '外構';
    case PicklistType.Joinery:
      return '建具';
    case PicklistType.Facility:
      return '設備';
    case PicklistType.DoorFrame:
      return 'ドア';
    case PicklistType.WindowFrame:
      return '窓';
    case PicklistType.Shutter:
      return 'シャッター';
    case PicklistType.GeneralWork:
      return '備品';
    case PicklistType.MiscellaneousWork:
      return '雑工事';
    case PicklistType.Other:
      return 'その他';
    default:
      return '';
  }
};

export const getPicklistTypeAdditionalLabel = (type: PicklistType | string) => {
  switch (type) {
    case PicklistType.Interior:
      return '内部仕上表関連';
    case PicklistType.Exterior:
      return '外部仕上表関連';
    case PicklistType.Outdoor:
      return '外構表関連';
    case PicklistType.Joinery:
      return '建具';
    case PicklistType.Facility:
      return '設備';
    case PicklistType.GeneralWork:
      return '備品';
    case PicklistType.MiscellaneousWork:
      return '雑工事';
    case PicklistType.Other:
      return 'その他';
    default:
      return '';
  }
};
