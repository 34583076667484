<mat-dialog-content>
  <div *ngIf="isCopy" class="caption">
    {{ inputData.picklist.name }}を複製して新しい材料を作成します。
  </div>
  <table>
    <tr>
      <th>記号</th>
      <td>
        <input
          name="mark"
          type="text"
          [(ngModel)]="inputData.picklist.mark"
          (ngModelChange)="
            checkMark(inputData.picklist.mark!, inputData.picklist)
          "
        />
        <div class="error" *ngIf="markErrorMessage">{{ markErrorMessage }}</div>
      </td>
    </tr>
    @if (finishSchedule!.keynotes!.data!.length > 0 && (isDHTenant$ | async)) {
      <tr>
        <th>キーノート</th>
        <td>
          <ls-keynote
            [picklist]="inputData.picklist"
            (onChange)="changeKeynote($event)"
          ></ls-keynote>
        </td>
      </tr>
    }
    <tr>
      <th>名称</th>
      <td>
        <input
          name="name"
          [placeholder]="
            finishSchedule!.keynotes!.data!.length > 0
              ? '名称はキーノートにより自動入力'
              : '「タイル(A)」のように一般名を入力'
          "
          [(ngModel)]="inputData.picklist.name"
          (ngModelChange)="
            checkName(inputData.picklist.name, inputData.picklist)
          "
        />
        <div class="error" *ngIf="nameErrorMessage">{{ nameErrorMessage }}</div>
      </td>
    </tr>
    <tr>
      <th>仕様</th>
      <td>
        <input
          name="specification"
          [placeholder]="
            finishSchedule!.keynotes!.data!.length > 0
              ? '仕様はキーノートにより自動入力'
              : '形状や機能など「公共工事標準仕様書」の仕様を入力'
          "
          [(ngModel)]="inputData.picklist.specification"
        />
      </td>
    </tr>
    <tr *ngIf="finishSchedule!.surfacePatterns!.length > 0">
      <th>サーフェスパターン</th>
      <ls-surface-pattern
        [id]="inputData.picklist.surface_pattern_id ?? null"
        [position]="'above'"
        (onChange)="inputData.picklist.surface_pattern_id = $event"
      >
      </ls-surface-pattern>
    </tr>
    <tr *ngIf="isCopy">
      <th>複製方法</th>
      <td>
        <mat-checkbox
          class="copy-mode"
          name="copy-mode-with-product"
          [(ngModel)]="inputData.copyInfo!.needProduct"
          >採用・候補製品も複製する</mat-checkbox
        >
      </td>
    </tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="left">
    <ls-button [border]="true" (click)="cancel()"> キャンセル </ls-button>
  </div>
  <div class="right">
    <ls-button
      theme="primary"
      [border]="true"
      [class.disabled]="!canSubmit"
      (click)="onSave()"
    >
      {{ inputPicklist && !isCopy ? '編集' : '作成' }}
    </ls-button>
  </div>
</mat-dialog-actions>
