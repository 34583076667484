import { ServerType } from './environment.model';

export const environment = {
  production: false,
  storagePath: 'https://static.dev.truss.me/',
  //storagePath: '/storage/',
  gaTrackingId: 'UA-58775646-9',
  serverType: ServerType.Dev,
  clarity: "9uqag6gdwb",
  gtmId: 'GTM-NWP44XDR',
  stripeKey: 'pk_test_51OAQe2CVqa4Ss53E2qikomBbFotl60OKaR0y9vCptO5uqNTTJV1qDK4a0osWe0BCHePgTldRrtvyVfwwReoYgMYM00Fh4WQ0YG',
  stripeTableId: 'prctbl_1Pfay8CVqa4Ss53EQQOhmDE2',
  launchDarklyKey: "665ca7738a40331043c3a425",
};
