import { Injectable } from '@angular/core';

export interface RequestParams {
  [key: string]: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class RequestParamsConverterService {
  convert(params: { [key: string]: any }): RequestParams {
    return this.objectPathInfo(params).reduce((result, meta) => {
      const pathString =
        meta.path[0] +
        meta.path
          .slice(1)
          .map((v) => `[${v}]`)
          .join('');
      result[pathString] = this.convertValueForParams(meta.value);
      return result;
    }, {} as RequestParams);
  }

  private convertValueForParams(value: any): string {
    if (typeof value === 'undefined' || value === null) {
      return '';
    }

    if (typeof value === 'boolean') {
      return value ? '1' : '0';
    }

    return value.toString ? value.toString() : '';
  }

  private objectPathInfo(object: {
    [key: string]: any;
  }): { path: string[]; value: any }[] {
    return Object.keys(object).reduce(
      (result: { path: string[]; value: any }[], key) => {
        const value = object[key];
        const type = this.typeOf(value);

        if (type !== 'Object' && type !== 'Array') {
          result.push({ path: [key], value });
          return result;
        }

        const objectValue =
          type === 'Array'
            ? value.reduce((c: { [key: string]: any }, v: any, i: number) => {
                c[i + ''] = v;
                return c;
              }, {})
            : value;

        const info = this.objectPathInfo(objectValue).map((meta) => {
          meta.path.unshift(key);
          return meta;
        });
        result.push(...info);
        return result;
      },
      [],
    );
  }

  private typeOf(value: any): string {
    return Object.prototype.toString.call(value).slice(8, -1);
  }
}
