import { Injectable, inject } from '@angular/core';
import { InteriorSetParts } from '@shared/models/response/interior-set-parts';

import { FloorGroup } from '../../../shared/models/response/floor-group';
import { InteriorSet } from '../../../shared/models/response/interior-set';
import { Picklist } from '../../../shared/models/response/picklist';
import { ProjectService } from '../../../shared/service/project.service';
import { InteriorSetPartsViewModel } from '../application/view-model/interior-set-parts-view-model';
import { PicklistViewModel } from '../application/view-model/picklist-view-model';
import { PicklistType } from '../domain/enums/picklist-type.enum';
import { ThumbnailType } from '../domain/enums/thumbnail-type.enum';
import {
  isChildPicklist,
  isParentPicklist,
} from '../domain/logics/picklist-logic';
import { Picklist as PicklistStateType } from '../domain/types/states/picklist.state';

/**
 * projectServiceのfinishScheduleへのpicklist反映用クラス
 */
@Injectable({
  providedIn: 'root',
})
export class ReflectPicklistInInteriorFinishScheduleService {
  private projectService = inject(ProjectService);

  constructor() {}

  public reflectPicklistType(updatedPicklist: PicklistStateType): void {
    const prevPicklist =
      this.findPicklistFromInteriorFinishSchedule(updatedPicklist);

    if (!!prevPicklist) {
      const newPicklist = this.reflectPicklistTypeInInteriorFinishSchedule(
        prevPicklist,
        updatedPicklist,
      );

      this.updatePicklist(newPicklist);
    }
  }

  public addPicklistViewModel(
    updatedPicklist: PicklistViewModel,
    parts?: InteriorSetPartsViewModel,
  ): void {
    const newPicklist = this.createNewPicklist(updatedPicklist);

    if (isParentPicklist(newPicklist)) {
      const finishScheduleParentPicklists =
        this.projectService.finishSchedule!.parentPicklists;

      const targetPicklist = finishScheduleParentPicklists?.find(
        (p) => p.id === newPicklist.id,
      );

      if (!targetPicklist) {
        finishScheduleParentPicklists!.push(newPicklist);
      }
    } else {
      const finishScheduleChildPicklists =
        this.projectService.finishSchedule!.picklists;

      const targetPicklist = finishScheduleChildPicklists?.find(
        (p) => p.id === newPicklist.id,
      );

      if (!targetPicklist) {
        finishScheduleChildPicklists!.push(newPicklist);
      }
    }

    if (isChildPicklist(newPicklist) && !!parts) {
      this.addPartsToFinishSchedule(parts);
    }
  }

  private addPartsToFinishSchedule(parts: InteriorSetPartsViewModel) {
    parts = { ...parts };
    let finishScheduleParts = this.getInteriorSetParts().find(
      (_parts) => parts!.id == _parts.id,
    );

    if (!!finishScheduleParts) {
      finishScheduleParts.picklistInfo = parts.picklistInfo;
      finishScheduleParts.picklists = parts.picklists;
    } else {
      let interiorSet = this.getInteriorSets().find(
        (set) => set.id === parts!.interior_set_id,
      );

      if (!!interiorSet) {
        interiorSet.parts = {
          ...interiorSet.parts,
          ...{ [parts!.interior_category_id]: parts },
        };
      }
    }
  }

  public reflectPicklistViewModel(updatedPicklist: PicklistViewModel): void {
    const _updatedPicklist = updatedPicklist as Picklist;

    const prevPicklist =
      this.findPicklistFromInteriorFinishSchedule(_updatedPicklist);

    if (!!prevPicklist) {
      const newPicklist = this.reflectPicklistTypeInInteriorFinishSchedule(
        prevPicklist,
        updatedPicklist,
      );

      this.updatePicklist(newPicklist);
    }
  }

  /**
   * picklistのprojectServiceのfinishScheduleへの反映
   * @param updatedPicklist
   */
  private updatePicklist(newPicklist: Picklist): void {
    if (this.getInteriorSets().length > 0) {
      this.updateInteriorSetPartsPicklistInInteriorFinshSchedule(newPicklist);
    }

    if (this.getFloorGroups().length > 0) {
      this.updateRoomBlockPartsPicklistInInteriorFinshSchedule(newPicklist);
    }
  }

  /**
   * picklistのprojectServiceのfinishSchedule.interiorSetPartsへの反映
   * @param updatedPicklist
   * @returns
   */
  private updateInteriorSetPartsPicklistInInteriorFinshSchedule(
    newPicklist: Picklist,
  ): void {
    const prevPicklists = this.getInteriorSetParts().flatMap(
      (parts) => parts.picklists,
    );

    this.reflectNewPicklist(prevPicklists, newPicklist);
  }

  /**
   * picklistのprojectServiceのfinishSchedule.roomBlockPartsへの反映
   * @param updatedPicklist
   * @returns
   */
  private updateRoomBlockPartsPicklistInInteriorFinshSchedule(
    newPicklist: Picklist,
  ): void {
    const prevPicklists = this.getFloorGroups()
      .flatMap((floorGroup) => floorGroup.room_groups)
      .flatMap((roomGroup) => roomGroup.room_blocks)
      .flatMap((roomBlock) => (roomBlock ? Object.values(roomBlock.parts) : []))
      .flatMap((parts) => parts.picklists);

    this.reflectNewPicklist(prevPicklists, newPicklist);
  }

  private findPicklistFromInteriorFinishSchedule(
    updatedPicklist: PicklistStateType,
  ): Picklist {
    return this.getPrevPicklists().find(
      (picklist) => picklist.id == updatedPicklist.id,
    )!!;
  }

  /**
   * picklistのprojectServiceのfinishSchedule内picklistsへの反映処理
   * @param picklist
   * @param updatedPicklist
   * @returns
   */
  private reflectPicklistTypeInInteriorFinishSchedule(
    prevPicklist: Picklist,
    updatedPicklist: PicklistStateType,
  ): Picklist {
    Object.keys(prevPicklist).forEach((key: string) => {
      const prevPicklistKey = key as keyof Picklist;
      const _updatedPicklist: Picklist = updatedPicklist as Picklist;
      if (prevPicklistKey in _updatedPicklist) {
        Object.assign(prevPicklist, {
          [prevPicklistKey]: _updatedPicklist[prevPicklistKey],
        });
      }
    });

    const newPicklist = prevPicklist;

    return newPicklist;
  }

  private createNewPicklist(updatedPicklist: PicklistStateType) {
    const picklistValue = {
      id: 0,
      revit_id: '',
      project_id: 0,
      type: PicklistType.Interior,
      group_id: null,
      name: '',
      specification: '',
      assign_type: 'input popular name',
      enable_similar: 1,
      similar_memo: '',
      enable_multiple_paste: false,
      remarks: '',
      memo: '',
      product_use_image: ThumbnailType.Product,
      order: {},
      list_link_id: undefined,
      created_at: '',
      updated_at: '',
      listProducts: [],
      is_standard: false,
      is_parent: false,
      is_structural_material: false,
      structural_code: null,
    } as Picklist;

    Object.keys(picklistValue).forEach((key: string) => {
      const prevPicklistKey = key as keyof Picklist;
      const _updatedPicklist: Picklist = updatedPicklist as Picklist;
      if (prevPicklistKey in _updatedPicklist) {
        Object.assign(picklistValue, {
          [prevPicklistKey]: _updatedPicklist[prevPicklistKey],
        });
      }
    });

    return picklistValue;
  }

  private reflectNewPicklist(prevPicklists: Picklist[], newPicklist: Picklist) {
    prevPicklists
      .filter((p) => p.id && p.id == newPicklist.id)
      .forEach((prevPicklist) => {
        Object.keys(prevPicklist).forEach((key: string) => {
          const prevPicklistKey = key as keyof Picklist;
          if (prevPicklistKey in newPicklist) {
            Object.assign(prevPicklist, {
              [prevPicklistKey]: newPicklist[prevPicklistKey],
            });
          }
        });
      });
  }

  private getFloorGroups(): FloorGroup[] {
    return this.projectService.floorGroups || [];
  }

  private getInteriorSets(): InteriorSet[] {
    return this.projectService.interiroSets || [];
  }

  private getPrevPicklists(): Picklist[] {
    return this.projectService.allPicklists || [];
  }

  private getInteriorSetParts(): InteriorSetParts[] {
    return this.getInteriorSets().flatMap((interiorSet) =>
      Object.values(interiorSet.parts).filter((parts) => !!parts),
    );
  }
}
