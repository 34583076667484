import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export type SampleRequestPermission = {
  canRequest: boolean | undefined;
  canSuggest: boolean | undefined;
};
type SampleRequestPermissionState = {
  sampleRequestPermissionState: SampleRequestPermission;
};

@Injectable({
  providedIn: `root`,
})
export class SampleRequestPermissionStore extends ComponentStore<SampleRequestPermissionState> {
  constructor() {
    super({
      sampleRequestPermissionState: {
        canRequest: undefined,
        canSuggest: undefined,
      },
    });
  }

  readonly sampleRequestPermission$: Observable<SampleRequestPermission> =
    this.select((state) => state.sampleRequestPermissionState);

  readonly canRequestSampleRequest$: Observable<boolean | undefined> =
    this.select((state) => state.sampleRequestPermissionState.canRequest);

  readonly canSuggestSampleRequest$: Observable<boolean | undefined> =
    this.select((state) => state.sampleRequestPermissionState.canSuggest);
}
