import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { TenantQuery } from 'app/v2/general/features/_query/tenant.query';

import { Picklist } from '../../models/response/picklist';
import {
  getPicklistTypeLabel,
  PicklistType,
} from '../../models/response/sub/picklist-type';
import { ThumbnailType } from '../../models/response/sub/thumbnail-type';
import { UserService } from '../../service/user.service';
import { AlertDialogService } from '../alert-dialog/alert-dialog.service';

import {
  AddPicklistInput,
  AddPicklistResult,
} from './add-picklist-dialog.service';

@Component({
  selector: 'ls-add-picklist-dialog',
  templateUrl: './add-picklist-dialog.component.html',
  styleUrls: ['./add-picklist-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class AddPicklistDialogComponent {
  inputData: AddPicklistResult = {
    picklist: {
      id: 0,
      revit_id: '',
      project_id: 0,
      type: PicklistType.Interior,
      group_id: null,
      name: '',
      specification: '',
      assign_type: 'input popular name',
      enable_similar: 1,
      similar_memo: '',
      enable_multiple_paste: false,
      remarks: '',
      memo: '',
      product_use_image: ThumbnailType.Product,
      order: {},
      list_link_id: undefined,
      created_at: '',
      updated_at: '',
      listProducts: [],
      is_standard: false,
      is_parent: false,
      is_structural_material: false,
      structural_code: null,
    },
  };

  get projectPage() {
    return this.data.projectPage;
  }

  get errorOfName() {
    return this.projectPage.getErrorOfPicklistName(this.inputData.picklist);
  }

  get canSubmit() {
    return !!this.inputData.picklist.name && !this.errorOfName;
  }

  get copyFrom() {
    return this.data.copyFrom;
  }

  get copyTo() {
    return this.data.copyTo;
  }

  get approvedRoom() {
    return this.data.approvedRoom;
  }

  createNewPicklistName(copyFrom: Picklist) {
    const _copy = Object.assign({}, copyFrom);
    let num =
      [...this.projectPage.getReletaionPicklists(_copy).concat(), ...[_copy]]
        .filter((picklist) => {
          return picklist.name.match(/^.*\(([A-Z]+)\)$/);
        })
        .map((picklist) => {
          const str = picklist.name.match(/^.*\(([A-Z]+)\)$/);

          if (!str || !str[1]) {
            return 0;
          }

          return Array.from(str[1])
            .reverse()
            .reduce((sum, _str, i) => {
              return sum + (_str.charCodeAt(0) - 65 + 1) * 26 ** i;
            }, 0);
        })
        .reduce((max, charCode) => {
          return Math.max(max, charCode);
        }, 0) + 1;

    let temp,
      letter = '';

    while (num > 0) {
      temp = (num - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      num = (num - temp - 1) / 26;
    }

    return `${_copy.name.replace(/\(([A-Z]+)\)$/, '')}(${letter})`;
  }

  @ViewChild('tooltip', { static: true })
  tooltip: MatTooltip;

  isDHTenant$ = this.tenantQuery.isDHTenant();

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: AddPicklistInput,
    private dialogRef: MatDialogRef<AddPicklistDialogComponent, any>,
    private changeDetector: ChangeDetectorRef,
    private alertDialog: AlertDialogService,
    readonly user: UserService,
    private tenantQuery: TenantQuery,
  ) {
    if (this.copyFrom) {
      this.createNewPicklistName(this.copyFrom);
      this.inputData.picklist = Object.assign({}, this.copyFrom);
      this.inputData.picklist.id = 0;
      this.inputData.picklist.name = this.createNewPicklistName(this.copyFrom);
      this.inputData.picklist.revit_id = null;
      this.inputData.picklist.list_link_id = null;
      this.inputData.picklist.is_parent = false;
      this.inputData.picklist.is_standard = false;
      this.inputData.picklist.order = this.copyFrom.is_standard
        ? null
        : this.copyFrom.order;

      this.inputData.copyInfo = {
        needProduct: false,
        needPaste: true,
      };

      if (!this.copyFrom.group_id) {
        this.inputData.picklist.group_id = this.copyFrom.id;
      }
    }

    this.inputData.picklist.type = data.type;
    this.inputData.picklist.project_id = this.projectPage.finishSchedule!.id;
  }

  cancel() {
    this.dialogRef.close();
  }

  onSave() {
    if (
      this.approvedRoom &&
      (!this.inputData.copyInfo || this.inputData.copyInfo.needPaste)
    ) {
      this.alertDialog
        .openDialog(
          `${this.approvedRoom}は承認済みです。\n本当に${this.inputData.picklist.name}を貼り付けますか？`,
          '貼付',
        )
        .afterClosed()
        .subscribe((r) => {
          if (r) this.checkLink();
        });
    } else {
      this.checkLink();
    }
  }

  checkLink() {
    const currentType: PicklistType = this.inputData.picklist.type;
    const targetType: PicklistType =
      currentType === PicklistType.Interior
        ? PicklistType.Exterior
        : PicklistType.Interior;
    const otherPicklists: Picklist[] = this.data.projectPage!.picklists.filter(
      (picklist) =>
        picklist.type == targetType &&
        picklist.name == this.inputData.picklist.name,
    );

    if (otherPicklists.length > 0 && !this.copyFrom) {
      const targetTypeLable = getPicklistTypeLabel(targetType);
      this.alertDialog
        .openDialog(
          `${targetTypeLable}に同名のリストが存在します。本リストとリンクしますか？`,
          `${targetTypeLable}リストとリンクする`,
          'リンクせず新規作成する',
          'primary',
        )
        .afterClosed()
        .subscribe((r) => {
          if (r) {
            this.inputData.picklist = Object.assign(
              this.inputData.picklist,
              otherPicklists[0]!,
            );
            this.inputData.picklist.group_id = null;
            this.inputData.picklist.list_link_id = this.inputData.picklist.id;
            this.inputData.picklist.id = 0;
            this.inputData.picklist.type = currentType;
          }
          this.save();
        });
    } else {
      this.save();
    }
  }

  save() {
    if (this.inputData.copyInfo && !this.inputData.copyInfo.needProduct) {
      this.inputData.picklist = Object.assign(
        this.inputData.picklist,
        //truss-684
        // this.projectPage.getProductInfo(),
      );
    }
    this.dialogRef.close(this.inputData);
  }
}
