<span
  class="select-new-value-badge"
  *ngFor="let value of values; let i = index"
>
  {{ value.name }}
  <span class="remove" (click)="removeValue(value)">×</span>
</span>
<textarea
  placeholder="{{ values.length ? '' : placeholder }}"
  type="text"
  autocomplete="off"
  (input)="getTypedValue($event)"
  (keydown)="handleInput($event)"
  (paste)="handleInput($event)"
  [formControl]="nameControl"
></textarea>
