import { List } from '../list';

import { HasDate } from './sub/has-date';

export interface Room extends HasDate, RoomInfo, ConstructionClassifications {
  id: number;
  project_id: number;
  revit_id: string;
  level: string;
  name: string;
  room_group_id?: number | null;
}

export interface RoomInfo {
  smoke_exhausting_system: string | null;
  correspondence_grounds: string | null;
  interior_restriction: string | null;
  restriction_grounds: string | null;
  space_type: string | null;
  falling_ceiling: string | null;
  sl: string | null;
  fl: string | null;
  baseboard_height: string | null;
  spandrel_wall_height: string | null;
  peripheral_height: string | null;
  ch: string | null;
}

export interface ConstructionClassifications {
  construction_classifications?: List<string | null>;
}

export const RoomInfoKeys: (keyof RoomInfo)[] = [
  'interior_restriction',
  'smoke_exhausting_system',
  'correspondence_grounds',
  'restriction_grounds',
  'fl',
  'sl',
  'baseboard_height',
  'spandrel_wall_height',
  'ch',
];

export const ConstructionClassificationKeys = [
  'construction_classification_floor_ground',
  'construction_classification_floor_finish',
  'construction_classification_baseboard',
  'construction_classification_spandrel_wall_ground',
  'construction_classification_spandrel_wall_finish',
  'construction_classification_wall_ground',
  'construction_classification_wall_finish',
  'construction_classification_parting_edge',
  'construction_classification_ceiling_ground',
  'construction_classification_ceiling_finish',
];
