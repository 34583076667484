import { SymbolWithNumber } from '@interfaces/symbol-with-number';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface SymbolWithNumberState extends EntityState<SymbolWithNumber> {
  error?: unknown;
  isFetching: boolean;
}

export const symbolWithNumberAdapter = createEntityAdapter<SymbolWithNumber>();

export const initialSymbolWithNumberState: SymbolWithNumberState =
  symbolWithNumberAdapter.getInitialState({
    isFetching: false,
  });
