import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKey } from 'app/store/enums/feature-key.enum';

import {
  JoineryComponentAdapter,
  JoineryComponentState,
} from '../joinery-component.state';

const getJoineryComponentState = createFeatureSelector<JoineryComponentState>(
  FeatureKey.JoineryComponent,
);

const { selectAll } = JoineryComponentAdapter.getSelectors();

export const getIsFetching = createSelector(
  getJoineryComponentState,
  (state) => state.isFetching,
);

export const getError = createSelector(
  getJoineryComponentState,
  (state) => state.error,
);

export const getComponents = createSelector(getJoineryComponentState, (state) =>
  selectAll(state),
);
