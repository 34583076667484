<div class="container" [class.container--unset]="config.disableContainer">
  <h1 class="title" *ngIf="config.title as title" matDialogTitle>
    {{ title }}
  </h1>
  <p class="subtitle" *ngIf="config.subtitle as subtitle">{{ subtitle }}</p>

  <mat-dialog-content>
    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <ls-button
      [border]="true"
      *ngIf="config.cancelLabel as label"
      mat-button
      (click)="cancel.emit()"
    >
      {{ label }}
    </ls-button>
    <ls-button
      theme="primary"
      [border]="true"
      *ngIf="config.actionLabel as label"
      mat-button
      [class.disabled]="!canAction"
      (click)="action.emit()"
    >
      {{ label }}
    </ls-button>
  </mat-dialog-actions>
</div>
