import { Joinery } from '@interfaces/joinery';
import { JoineryComponent } from '@interfaces/joinery-component';
import { RoomToRoom } from '@interfaces/room-to-room';
import { SymbolSpecification } from '@interfaces/symbol-specification';
import { createAction, props } from '@ngrx/store';
import { Pagination } from '@shared/models/response/pagination';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { JoinerySearchParam } from 'app/services/api/joinery-page-api.service';

const apiPrefix = '[Joinery API]';
const pagePrefix = '[Joinery Page]';
const effectPrefix = '[Joinery Effect]';

export const fetch = createAction(
  pagePrefix + 'Fetch',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    page?: number;
    searchParam?: JoinerySearchParam;
  }>(),
);

export const fetchSuccess = createAction(
  apiPrefix + 'Fetch Success',
  props<{ joineryPagination: Pagination<Joinery> | null | undefined }>(),
);

export const fetchFailure = createAction(
  apiPrefix + 'Fetch Failure',
  props<{ error: unknown }>(),
);

export const updateSpec = createAction(
  pagePrefix + 'Update Spec',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    joinery: Joinery;
    symbolSpecification: SymbolSpecification;
  }>(),
);

export const updateSpecSuccess = createAction(
  apiPrefix + 'Update Spec Success',
  props<{ joinery: Joinery }>(),
);

export const updateSpecFailure = createAction(
  apiPrefix + 'Update Spec Failure',
  props<{ error: unknown }>(),
);

export const updateSpecMany = createAction(
  pagePrefix + 'Update Spec Many',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    joineries: Joinery[];
    symbolSpecification: SymbolSpecification;
  }>(),
);

export const updateSpecManySuccess = createAction(
  apiPrefix + 'Update Spec Many Success',
  props<{ joineries: Joinery[] }>(),
);

export const updateSpecManyFailure = createAction(
  apiPrefix + 'Update Spec Many Failure',
  props<{ error: unknown }>(),
);

export const incrementMeta = createAction(pagePrefix + 'Increment Meta');
export const decrementMeta = createAction(pagePrefix + 'Decrement Meta');
export const incrementMetaMany = createAction(
  pagePrefix + 'Increment Meta Many',
  props<{ incrementCount: number }>(),
);

export const copySpecMany = createAction(
  pagePrefix + 'CopySpecMany Success',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    copySourceJoinery: Joinery;
    copySourceConfigId: number;
    pasteTargetJoineries: Joinery[];
  }>(),
);

export const copySpecManySuccess = createAction(
  apiPrefix + 'CopySpecMany Success',
  props<{
    joineries: Joinery[];
  }>(),
);

export const copySpecManyFailure = createAction(
  apiPrefix + 'CopySpecMany Failure',
  props<{ error: unknown }>(),
);

export const update = createAction(
  pagePrefix + 'Update',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    joinery: Joinery;
  }>(),
);

export const updateSuccess = createAction(
  apiPrefix + 'Update Success',
  props<{ joinery: Joinery }>(),
);

export const updateFailure = createAction(
  apiPrefix + 'Update Failure',
  props<{ error: unknown }>(),
);

export const duplicateAndEditRooms = createAction(
  pagePrefix + 'DuplicateAndEditRooms',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    joinery: Joinery;
    targetRooms: RoomToRoom[];
    isDeleteCopySource: boolean;
  }>(),
);

export const duplicateAndEditRoomsSuccess = createAction(
  apiPrefix + 'DuplicateAndEditRooms Success',
  props<{ joinery: Joinery }>(),
);

export const duplicateAndEditRoomsFailure = createAction(
  apiPrefix + 'DuplicateAndEditRooms Failure',
  props<{ error: unknown }>(),
);

export const duplicateMultiWindowAndEditRooms = createAction(
  pagePrefix + 'DuplicateMultiWindowAndEditRooms',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    joineries: Joinery[];
    components: JoineryComponent[];
    targetRooms: RoomToRoom[];
  }>(),
);

export const duplicateMultiWindowAndEditRoomsSuccess = createAction(
  apiPrefix + 'DuplicateMultiWindowAndEditRooms Success',
  props<{ joineries: Joinery[] }>(),
);

export const duplicateMultiWindowAndEditRoomsFailure = createAction(
  apiPrefix + 'DuplicateMultiWindowAndEditRooms Failure',
  props<{ error: unknown }>(),
);

export const remove = createAction(
  pagePrefix + 'Remove',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    joineryId: number;
  }>(),
);

export const removeSuccess = createAction(
  apiPrefix + 'Remove Success',
  props<{ joineryId: number }>(),
);

export const removeFailure = createAction(
  apiPrefix + 'Remove Failure',
  props<{ error: unknown }>(),
);

export const addMany = createAction(
  pagePrefix + 'AddMany',
  props<{
    projectId: number;
    region: ProjectRangeType;
    businessGroupId: number;
    joineries: Omit<Joinery, 'id'>[];
  }>(),
);

export const addManySuccess = createAction(
  apiPrefix + 'AddMany Success',
  props<{ joineries: Joinery[] }>(),
);

export const addManyFailure = createAction(
  apiPrefix + 'AddMany Failure',
  props<{ error: unknown }>(),
);
