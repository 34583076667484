export enum FeatureKey {
  CandidateProduct = 'candidate-product',
  Picklist = 'picklist',
  Joinery = 'joinery',
  JoineryComponent = 'joinery-component',
  JoineryPageInfo = 'joinery-page-info',
  JoineryPagination = 'joinery-pagination',
  JoineryOptionSetting = 'joinery-option-setting',
  StandardJoineryComponent = 'standard-joinery-component',
  PicklistSpecification = 'picklist-specification',
  SymbolSpecification = 'symbol-specification',
  SymbolWithNumber = 'symbol-with-number',
  RoomToRoom = 'room-to-room',
}
