import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CategoryColumnSetting } from '../models/response/category-column-setting';
import { SearchParamsOption } from '../models/response/search-params';

import { RequestParamsConverterService } from './request-params-converter.service';

export interface CategoryColumnSettingIndexParams {
  categories?: string[];
  words?: string[];
  with?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class CategoryColumnSettingService {
  constructor(
    protected http: HttpClient,
    protected converter: RequestParamsConverterService,
  ) {}

  index(
    params: CategoryColumnSettingIndexParams = {},
  ): Observable<CategoryColumnSetting[]> {
    return this.http.get<CategoryColumnSetting[]>(
      '/api/category-column-settings',
      { params: this.converter.convert(params) },
    );
  }

  show(id: string) {
    return this.http.get<CategoryColumnSetting>(
      `/api/category-column-settings/${id}`,
    );
  }

  allOptions(id: string) {
    return this.http.get<SearchParamsOption[]>(
      `/api/category-column-settings/${id}/all-options`,
    );
  }
}
