import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

/**
 * @link https://github.com/FortAwesome/angular-fontawesome
 */
export const setUpIcons = (library: FaIconLibrary) => {
  library.addIconPacks(fas, far);
  library.addIcons(faGoogle);
};
