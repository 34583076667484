import { Injectable, inject } from '@angular/core';
import { FloorGroup } from 'app/v2/general/domain/types/states/floor-group.state';
import { GetMiscellaneousWorkRoomGroupViewModelQuery } from 'app/v2/general/features/project/miscellaneous-work/_query/get-miscellaneous-work-room-group-view-model.query';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';

import { FloorGroupStore } from '../../../store/floor-group.store';
import {
  FloorGroupViewModel,
  MiscellaneousWorkFloorGroupViewModel,
} from '../../../view-model/floor-group-view-model';
import { MiscellaneousWorkRoomGroupViewModel } from '../../../view-model/miscellaneous-work-room-group-view-model';
import { FloorGroupDto } from '../../dto/floor-group.dto';
import { GetRoomGroupViewModelQuery } from '../room-group/get-room-group-view-model-query';

/**
 * FloorGroupViewModel生成クラス
 */
@Injectable({
  providedIn: 'root',
})
export class GetFloorGroupViewModelQuery {
  private floorGroupStore = inject(FloorGroupStore);
  private getRoomGroupViewModelQuery = inject(GetRoomGroupViewModelQuery);
  private getMiscellaneousWorkRoomGroupQuery = inject(
    GetMiscellaneousWorkRoomGroupViewModelQuery,
  );
  private floorGroupFromStore$ = this.floorGroupStore.floorGroups$;
  private roomGroupViewModel$ =
    this.getRoomGroupViewModelQuery.getViewModelList();

  /**
   * floorGroupStore, roomGroupStoreから取得した値でFloorGroupViewModelの配列をObservableを生成する
   */
  public getViewModelList(): Observable<FloorGroupViewModel[]> {
    const floorGroups$ = combineLatest([
      this.floorGroupFromStore$,
      this.roomGroupViewModel$,
    ]).pipe(
      map(([floorGroupFromStore, roomGroupViewModel]) => {
        return FloorGroupDto.build(floorGroupFromStore, roomGroupViewModel);
      }),
    );

    return floorGroups$;
  }

  /**
   * FloorGroupとMiscellaneousWorkRoomGroupViewModelから
   * 雑工事用のFloorGroupViewModelのObservableを生成する。
   */
  getMiscellaneousWorkList(): Observable<
    MiscellaneousWorkFloorGroupViewModel[]
  > {
    const floorGroups$: Observable<FloorGroup[]> =
      this.floorGroupStore.floorGroups$;
    const roomGroups$: Observable<MiscellaneousWorkRoomGroupViewModel[]> =
      this.getMiscellaneousWorkRoomGroupQuery.getViewModelList();

    const miscellaneousWorks$: Observable<
      MiscellaneousWorkFloorGroupViewModel[]
    > = combineLatest([floorGroups$, roomGroups$]).pipe(
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
      ),
      map(([floorGroups, roomGroups]) => {
        return FloorGroupDto.transformMiscellaneousWorks(
          floorGroups,
          roomGroups,
        );
      }),
    );

    return miscellaneousWorks$;
  }
}
