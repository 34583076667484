import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Feature } from '../models/response/feature';
import { Pagination } from '../models/response/pagination';
import { Product } from '../models/response/product';
import { Series } from '../models/response/series';
import { setFavoriteState } from '../service/favorite-state.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor(protected http: HttpClient) {}

  index(page = 1): Observable<Pagination<Feature>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<Feature>>('/api/features', { params });
  }

  show(id: string): Observable<Feature> {
    return this.http.get<Feature>(`/api/features/${id}`);
  }

  series(id: string): Observable<Pagination<Series>> {
    return this.http.get<Pagination<Series>>(`/api/features/${id}/series`);
  }

  products(id: string): Observable<Pagination<Product>> {
    return this.http
      .get<Pagination<Product>>(`/api/features/${id}/products`)
      .pipe(tap((pagination) => setFavoriteState(...pagination.data)));
  }
}
