import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Category } from '../models/response/category';
import { CategoryColumnSetting } from '../models/response/category-column-setting';
import { Count } from '../models/response/count';
import { Feature } from '../models/response/feature';
import { GalleryItem } from '../models/response/gallery-item';
import { Maker } from '../models/response/maker';
import { Pagination } from '../models/response/pagination';
import { SearchParams } from '../models/response/search-params';
import {
  SearchPicklistCategories,
  ProjectConditions,
} from '../models/response/search-picklist-categories';
import { UseCase } from '../models/response/use-case';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(protected http: HttpClient) {}

  index(): Observable<Category[]> {
    return this.http.get<Category[]>('/api/categories');
  }

  chartables(): Observable<Category[]> {
    return this.http.get<Category[]>('/api/categories/chartables');
  }

  show(id: string): Observable<Category> {
    return this.http.get<Category>(`/api/categories/${id}`);
  }

  searchParams(id: string): Observable<SearchParams[]> {
    return this.http.get<SearchParams[]>(`/api/categories/${id}/search-params`);
  }

  columnSettings(id: string): Observable<CategoryColumnSetting[]> {
    return this.http.get<CategoryColumnSetting[]>(
      `/api/categories/${id}/column-settings`,
    );
  }

  makers(id: string, page = 1): Observable<Pagination<Maker>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<Maker>>(`/api/categories/${id}/makers`, {
      params,
    });
  }

  features(id: string, page = 1): Observable<Pagination<Feature>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<Feature>>(
      `/api/categories/${id}/features`,
      { params },
    );
  }

  featuresTotal(id: string): Observable<Count> {
    return this.http.get<Count>(`/api/categories/${id}/features/total`);
  }

  useCases(id: string, page = 1): Observable<Pagination<UseCase>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<UseCase>>(
      `/api/categories/${id}/use-cases`,
      { params },
    );
  }

  useCasesTotal(id: string): Observable<Count> {
    return this.http.get<Count>(`/api/categories/${id}/use-cases/total`);
  }

  galleryItems(id: string, page = 1): Observable<Pagination<GalleryItem>> {
    const params = { page: page + '' };
    return this.http.get<Pagination<GalleryItem>>(
      `/api/categories/${id}/gallery-items`,
      { params },
    );
  }

  searchCategories(): Observable<SearchPicklistCategories> {
    return this.http.get<SearchPicklistCategories>(
      `/api/categories/search-categories`,
    );
  }
  projectConditions(projectId: number): Observable<ProjectConditions> {
    return this.http.get<ProjectConditions>(
      `/api/categories/project-conditions/${projectId}`,
    );
  }
}
