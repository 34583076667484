import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Tenant } from '../_type/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantStore {
  private readonly tenantSubject$ = new BehaviorSubject<Tenant | undefined>(
    undefined,
  );

  get(): Observable<Tenant | undefined> {
    return this.tenantSubject$.asObservable();
  }

  set(tenant: Tenant | undefined): void {
    this.tenantSubject$.next(tenant);
  }

  remove(): void {
    this.tenantSubject$.next(undefined);
  }
}
