import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { Picklist } from '../../models/response/picklist';

import { ListLinkCheckDialogComponent } from './list-link-check-dialog.component';

@Injectable()
export class ListLinkCheckDialogService extends MatDialog {
  openDialog(
    picklist: Picklist,
  ): MatDialogRef<ListLinkCheckDialogComponent, boolean> {
    return this.open(ListLinkCheckDialogComponent, {
      data: { picklist },
      disableClose: true,
    });
  }
}
