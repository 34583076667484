import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

@Injectable({
  providedIn: 'root',
})
export class MatPaginatorIntlJaModule extends MatPaginatorIntl {
  itemsPerPageLabel = '1ページの表示数';
  nextPageLabel = '次のページ';
  previousPageLabel = '前のページ';
  lastPageLabel = '最後のページ';
  firstPageLabel = '最初のページ';

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / 合計 ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} ～ ${endIndex} / 合計 ${length}`;
  };
}
